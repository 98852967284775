import { MedicineService } from 'services/MedicineService/MedicineService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { medicineManagementEvent } from 'stores/MedicineManagement/MedicineManagementEvents';

import { getMedicineManagementUseCase } from './getMedicineManagementUseCase';

const execute = async ({ name }: { name: string }) => {
  try {
    medicineManagementEvent({ isLoadingForm: true });
    await MedicineService.createMedicine(name);
    alertEvent({
      open: true,
      text: 'Medicamento criado com sucesso.',
      type: 'success',
    });
  } catch (err) {
    alertEvent({
      open: true,
      text: 'Ocorreu um erro ao criar medicamento, tente novamente mais tarde.',
      type: 'error',
    });
  } finally {
    medicineManagementEvent({ isOpenForm: false, isLoadingForm: false, isOpenModalSubmit: false });
    getMedicineManagementUseCase.execute();
  }
};

export const createMedicineUseCase = { execute };

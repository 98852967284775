import { theme } from 'styles/theme';

import { SxProps } from '@mui/material';

export const containerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
};

export const drawerMenuItemStyle: SxProps = {
  width: '100%',
  px: 2,
  justifyContent: 'space-between',
  alignItems: 'center',
  direction: 'row',
  height: 42,
};

export const mainTitle: SxProps = {
  fontSize: '32px',
  fontWeight: 'bold',
};
export const subtitle1: SxProps = {
  fontSize: '20px',
  fontWeight: 'bold',
};
export const subtitle2: SxProps = {
  fontSize: '20px',
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
};
export const title: SxProps = {
  fontSize: '16px',
  fontWeight: 'bold',
};
export const titleEquipment: SxProps = {
  fontSize: '18px',
  fontWeight: 'bold',
};
export const info1: SxProps = {
  fontSize: '14px',
};
export const info2: SxProps = {
  fontSize: '14px',
  fontWeight: 'bold',
};
export const txt16normal: SxProps = {
  fontSize: '16px',
};
export const btnFirst: SxProps = {
  backgroundColor: theme.palette.secondary.main,
  padding: '10px 15px 10px 15px',
  borderRadius: '5px 0px 0px 5px',
  borderRight: `1px solid ${theme.palette.secondary.main}`,
  color: 'white',
  size: 'small',
};
export const btnLast: SxProps = {
  backgroundColor: '#3B98E5',
  padding: '10px 15px 10px 15px',
  borderRadius: '0px 5px 5px 0px',
  color: 'white',
  size: 'small',
};
export const btnCenter: SxProps = {
  backgroundColor: '#3B98E5',
  padding: '10px 15px 10px 15px',
  borderRadius: 0,
  borderRight: `1px solid ${theme.palette.secondary.main}`,
  color: 'white',
  size: 'small',
};
export const cardEquipmentStyles: SxProps = {
  border: '1px solid #ddd',
  marginTop: '20px',
  padding: '12px',
};
export const cardEquipmentDoneStyles: SxProps = {
  border: '1px solid #ddd',
  marginTop: '20px',
  padding: '12px',
  bgcolor: '#E4E4E4',
};
export const tagButtonDoneStyles: SxProps = {
  borderRadius: 12,
  backgroundColor: '#5b5b5b',
  width: 108,
  height: 28,
  color: '#fff',
  fontSize: 11,
  fontWeight: 'bold',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
};
export const tagButtonStyles: SxProps = {
  borderRadius: 12,
  backgroundColor: theme.palette.secondary.main,
  width: 108,
  height: 28,
  color: '#fff',
  fontSize: 11,
  fontWeight: 'bold',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
};
export const tagEquipmentReports: SxProps = {
  borderRadius: 1,
  backgroundColor: '#1D8F5E',
  width: 108,
  height: 38,
  color: '#fff',
  fontSize: 12,
  fontWeight: 'bold',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
};
export const sectionEquipment: SxProps = {
  justifyContent: 'space-between',
  gap: 1,
  mt: 2,
  pb: 2,
  width: '100%',
};

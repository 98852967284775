import { useStore } from 'effector-react';

import { Snackbar, Typography } from '@mui/material';

import Alert from 'components/ui/alert';

import { alertEvent } from 'stores/Alert/AlertEvents';
import alertStore from 'stores/Alert/AlertStore';

function SnackBarComponent() {
  const { open, text, type } = useStore(alertStore);
  const handleCloseAlert = () => alertEvent({ open: false });
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={6000}
      onClose={handleCloseAlert}
    >
      <Alert onClose={handleCloseAlert} severity={type} sx={{ width: '100%' }}>
        <Typography fontSize={16} color="inherit">
          {text}
        </Typography>
      </Alert>
    </Snackbar>
  );
}

export default SnackBarComponent;

interface IParams {
  data: any;
  questionCategoryList: string[];
  cbReset: (v: boolean) => void;
  cbSetValue: (input: string, value: string) => void;
}

const execute = async (params: IParams) => {
  const { data, cbReset, cbSetValue } = params;
  const isMRPAManual = data?.name?.toLowerCase().includes('mrpa manual');
  if (!isMRPAManual) return;
  cbReset(true);
  const sectionNegotiation = data?.productQuestions?.filter(
    question => question?.question?.questionCategory === params?.questionCategoryList[2],
  );
  const quantityInput = sectionNegotiation?.find(obj =>
    obj.question.description?.toLowerCase().includes('quantidade de laudos'),
  );
  if (quantityInput) {
    const input = `${quantityInput.question.id}__${quantityInput.question.questionType}__${quantityInput.question.questionCategory}`;
    cbSetValue(input, '1');
  }
  cbReset(false);
};

export const setDefaultValuesUseCase = { execute };

import { useRef, useState } from 'react';

import { DeleteOutline } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

const allowedExtensions = ['jpg', 'png', 'svg', 'webp'];

function getBase64Size(base64String) {
  const padding = (base64String.length % 4 === 0 ? 0 : 4 - (base64String.length % 4)) * 2;
  return (base64String.length + padding) * 0.75 - padding;
}

function ImagePicker({ file, setFile }) {
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);

  const handleSetFile = obj => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(obj);
    fileReader.onload = e => {
      const result = e?.target?.result;
      const fileSize = getBase64Size(result); // Calculate the size of the base64 image
      if (fileSize > 300 * 1024) {
        setError('A imagem deve ter no máximo 300 KB.');
      } else {
        setError('');
        setFile({
          contentFile: result,
          name: obj.name,
        });
      }
    };
  };

  const handleDragOver = e => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = e => {
    e.preventDefault();
    setDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    handleFileSelect(droppedFile);
  };

  const openFileExplorer = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = selectedFile => {
    const fileExtension = selectedFile?.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      setError('Somente imagens JPG, PNG, SVG e WEBP são permitidas.');
      return;
    }

    setError('');
    handleSetFile(selectedFile);
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        border: !file?.contentFile ? `2px ${dragging ? 'solid' : 'dashed'} #48a7ff` : '',
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 12,
        flexDirection: 'column',
        backgroundImage: `url('${file?.contentFile}')`,
        backgroundPosition: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'unset',
        backgroundSize: 'cover',
        position: 'relative',
      }}
    >
      {error && (
        <Typography color="red" fontSize={14} sx={{ mb: 1 }}>
          {error}
        </Typography>
      )}
      {!file?.contentFile ? (
        <>
          <img src="/assets/icon-upload.png" alt="Upload img" />
          <Typography color="#8896be" fontSize={16}>
            Arraste e solte seu arquivo aqui
          </Typography>
          <Typography color="#8896be" fontWeight={700} sx={{ mt: 1 }} fontSize={14}>
            OU
          </Typography>
          <Button
            onClick={openFileExplorer}
            size="medium"
            className="button-bg"
            variant="contained"
            sx={{ mt: 1 }}
          >
            Escolher arquivo
          </Button>
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: 'none' }}
            onChange={e => handleFileSelect(e.target.files[0])}
          />
        </>
      ) : (
        <Button
          variant="contained"
          component="span"
          color="error"
          onClick={() => setFile(null)}
          style={{ position: 'absolute', right: -10, bottom: -15 }}
        >
          <DeleteOutline color="inherit" />
        </Button>
      )}
    </div>
  );
}

export default ImagePicker;

import { IMedicinesResponse } from 'domains/medicines';

export interface MedicineManagementState {
  isLoading: boolean;
  isLoadingForm: boolean;
  isLoadingModalStatus: boolean;
  list: IMedicinesResponse | null;
  pageSize: number;
  pageNow: number;
  changeStatus: null | {
    id: string;
    isActive: boolean;
  };
  isOpenForm: boolean;
  isOpenModalStatus: boolean;
  isOpenModalSubmit: boolean;
}

export const medicineManagementInitialState: MedicineManagementState = {
  isLoading: false,
  isLoadingForm: false,
  isLoadingModalStatus: false,
  list: null,
  pageSize: 10,
  pageNow: 1,
  changeStatus: null,
  isOpenForm: false,
  isOpenModalStatus: false,
  isOpenModalSubmit: false,
};

import api from 'clients/api/Api';

import {
  IParamsChangeStatusProduct,
  IParamsCreateAutomationProduct,
  IParamsCreateProduct,
  IParamsGetAutomationListResponse,
  IParamsUpdateProduct,
  IProductByIdResponse,
  IProductPagedResponse,
  IProductResponse,
} from 'domains/product';

const getAllProducts = async () => {
  const { data } = await api.get<IProductResponse>('cardio/Product/All');
  return data;
};

const getProductById = async (id: string) => {
  const { data } = await api.get<IProductByIdResponse>(`cardio/Product/${id}`);
  return data;
};

const getProducts = async (page = 1, size = 10) => {
  const { data } = await api.get<IProductPagedResponse>('cardio/Product/AllPaged', {
    params: { page, size },
  });
  return data;
};

const createProduct = async (params: IParamsCreateProduct) => {
  const { data } = await api.post('cardio/Product', params);
  return data;
};

const editProduct = async (id: string, params: IParamsUpdateProduct) => {
  const { data } = await api.put(`cardio/Product/${id}`, params);
  return data;
};
const changeStatus = async (id: string, params: IParamsChangeStatusProduct) => {
  const { data } = await api.put(`cardio/Product/ChangeStatus/${id}`, params);
  return data;
};

const saveAutomationProduct = async (
  productVersionId: string,
  params: IParamsCreateAutomationProduct,
) => {
  const { data } = await api.post(`cardio/Product/${productVersionId}/Automation`, params);
  return data;
};

const getAutomationProduct = async (productVersionId: string) => {
  const { data } = await api.get<IParamsGetAutomationListResponse>(
    `cardio/Product/${productVersionId}/Automations`,
  );
  return data;
};

const deleteAutomationProduct = async (automationId: string) => {
  const { data } = await api.delete(`cardio/Product/${automationId}/Automation`);
  return data;
};

const getRequestDynamicForm = async (id: string) => {
  const { data } = await api.get<IProductByIdResponse>(`cardio/Product/${id}`);
  return data;
};

export const ProductsService = {
  getProductById,
  getProducts,
  getAllProducts,
  createProduct,
  editProduct,
  getAutomationProduct,
  saveAutomationProduct,
  deleteAutomationProduct,
  getRequestDynamicForm,
  changeStatus,
};

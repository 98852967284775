/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */

import { useEffect, useState } from 'react';

import { useStore } from 'effector-react';

import { ArrowForwardOutlined, CloseOutlined, NumbersOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material';

import TextInput from 'components/form/textInput';
import { ButtonBack } from 'components/ui/buttonBack/ButtonBack';
import ConfirmModal from 'components/ui/confirmModal/ConfirmModal';

import { changeSerialNumberUseCase } from 'useCases/partnersManagement/changeSerialNumberUseCase';

import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

interface ISerialList {
  id: string;
  serialNumber: string;
}

function DrawerSerialNumber() {
  const { isOpenDrawerSerialNumber, detailsDrawerSerialNumber, isLoadingDrawerSerialNumber } =
    useStore(partnersManagementStore);

  const equipmentQuantity = Number(detailsDrawerSerialNumber?.equipmentQty || 1);

  const [openModal, setOpenModal] = useState(false);

  const [serialList, setSerialList] = useState<ISerialList[]>([]);

  const onClose = () => {
    partnersManagementEvent({ isOpenDrawerSerialNumber: false, isOpenDrawerDetails: true });
  };

  const handleButtonDisabled = () => {
    return serialList?.every(it => !it?.serialNumber);
  };

  useEffect(() => {
    if (detailsDrawerSerialNumber?.equipmentItems?.length) {
      const newArr = detailsDrawerSerialNumber.equipmentItems.map(item => ({
        id: item.id,
        serialNumber: item?.serialNumber || 'N/A',
      }));
      setSerialList(newArr);
    } else {
      const data = new Array(equipmentQuantity).fill('');
      const newArr = data.map(() => ({
        id: `appId:${(Math.random() * 999)?.toString()}`,
        serialNumber: '',
      }));
      setSerialList(newArr);
    }
  }, [equipmentQuantity, isOpenDrawerSerialNumber]);

  return (
    <>
      <Drawer anchor="right" open={isOpenDrawerSerialNumber} onClose={onClose}>
        <Stack
          p={4}
          direction="column"
          flex={1}
          sx={{
            width: '598px',
            backgroundColor: 'white',
            paddingBottom: 5,
            borderRadius: '15px 0 0 15px',
          }}
        >
          <Stack mt={2} pb={1} direction="row" justifyContent="space-between" alignItems="center">
            <ButtonBack text="VOLTAR" onBack={onClose} />
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Stack>
          <Typography fontSize={22} fontWeight={700} pb={4}>
            Alterar números de série
          </Typography>
          <Typography fontSize={14} fontWeight={700}>
            NÚMERO DE SÉRIE
          </Typography>
          <Divider />
          <Box mt={2}>
            {serialList?.map((item, index) => (
              <TextInput
                label=""
                key={item.id}
                sx={{ mt: 1 }}
                startAdornment={<NumbersOutlined />}
                name={`serial-input-${index}`}
                value={serialList[index].serialNumber}
                onChange={e =>
                  setSerialList(oldState =>
                    oldState?.map(obj =>
                      obj.id === item.id ? { ...obj, serialNumber: e.target.value } : obj,
                    ),
                  )
                }
              />
            ))}
          </Box>
          <Button
            variant="contained"
            sx={{ width: 200, mt: 6 }}
            disabled={handleButtonDisabled()}
            endIcon={<ArrowForwardOutlined color="inherit" />}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            CONFIRMAR
          </Button>
        </Stack>
      </Drawer>
      <ConfirmModal
        open={openModal}
        setOpen={v => setOpenModal(v)}
        title="Atenção"
        text="Deseja alterar os números de série desse equipamento ?"
        onConfirm={async () => {
          await changeSerialNumberUseCase.execute({ serialList });
          setOpenModal(false);
        }}
        isLoading={isLoadingDrawerSerialNumber}
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
    </>
  );
}

export default DrawerSerialNumber;

import { ExportService } from 'services/ExportService/ExportService';

import { Common } from 'utils';

import { exportsManagementEvent } from 'stores/ExportsManagement/ExportsManagementEvents';
import exportManagementStore from 'stores/ExportsManagement/ExportsManagementStore';

const execute = async () => {
  try {
    exportsManagementEvent({ isLoading: true });
    const { pageSize, pageNow, filters } = exportManagementStore.getState();
    let obj = {
      Page: pageNow,
      Size: pageSize,
      ExportType: filters?.exportType,
    };
    if (filters) {
      obj = { ...obj, ...filters };
    }
    const result = await ExportService.getAll(obj);
    await Common.sleep(1000);
    exportsManagementEvent({ dataSource: result });
  } catch (err) {
    console.log(err);
  } finally {
    exportsManagementEvent({ isLoading: false });
  }
};

export const getExportManagementUseCase = { execute };

import { SxProps } from '@mui/material';

export const containerStyles: SxProps = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  marginTop: '20vh',
};

export const contentStyles: SxProps = {
  width: 400,
  height: 430,
  bgcolor: '#fff',
  borderRadius: 4,
  padding: 5,
  position: 'relative',
};

export const modalTitleStyles: SxProps = {
  textAlign: 'center',
  fontSize: '24px',
  fontWeight: 700,
};

export const modalTextStyles: SxProps = { textAlign: 'center', fontSize: 16, mt: 1 };

export const closeButtonStyles: SxProps = { position: 'absolute', right: 8, top: 8 };

import { ICompaigns } from 'domains/compaigns';
import {
  IParamsGetAutomationListResponse,
  IParamsGetAutomationProduct,
  IProductPagedResponse,
} from 'domains/product';
import { IQuestion } from 'domains/question';

export interface ProductManagementState {
  isLoading: boolean;
  isOpenForm: boolean;
  isOpenModalChangeStatusSubmit: boolean;
  rowId: string;
  isOpenFormEdit: boolean;
  imageDeleted: boolean;
  isOpenEmailAutomation: boolean;
  isOpenSpreadsheetAutomation: boolean;
  isOpenAutomationListDrawer: boolean;
  isOpenModalSubmit: boolean;
  isOpenModalEditSubmit: boolean;
  isLoadingForm: boolean;
  list: IProductPagedResponse | null;
  listIdentificationQuestions: IQuestion[] | null;
  listClientQuestions: IQuestion[] | null;
  listNegotiationQuestions: IQuestion[] | null;
  listShippingQuestions: IQuestion[] | null;
  listCompaign: ICompaigns[] | null;

  listIdentificationEditQuestions: IQuestion[] | null;
  listClientEditQuestions: IQuestion[] | null;
  listNegotiationEditQuestions: IQuestion[] | null;
  listShippingEditQuestions: IQuestion[] | null;
  listEditCompaign: ICompaigns[] | null;

  listIdentificationAutomationQuestions: IParamsGetAutomationProduct[] | null;
  listClientAutomationQuestions: IParamsGetAutomationProduct[] | null;
  listNegotiationAutomationQuestions: IParamsGetAutomationProduct[] | null;
  listShippingAutomationQuestions: IParamsGetAutomationProduct[] | null;

  isOpenModalDeleteAutomationSubmit: boolean | null;
  dataAutomationList: IParamsGetAutomationListResponse | null;
  dataProduct: any;
  pageNow: number;
  pageSize: number;
}

export const productManagementInitialState: ProductManagementState = {
  isLoading: false,
  isOpenForm: false,
  isOpenModalChangeStatusSubmit: false,
  imageDeleted: false,
  rowId: '',
  isOpenFormEdit: false,
  isOpenEmailAutomation: false,
  isOpenSpreadsheetAutomation: false,
  isOpenAutomationListDrawer: false,
  isOpenModalSubmit: false,
  isOpenModalEditSubmit: false,
  isOpenModalDeleteAutomationSubmit: false,
  isLoadingForm: false,
  list: null,
  listIdentificationQuestions: null,
  listClientQuestions: null,
  listNegotiationQuestions: null,
  listShippingQuestions: null,
  listCompaign: null,
  listIdentificationEditQuestions: null,
  listClientEditQuestions: null,
  listNegotiationEditQuestions: null,
  listShippingEditQuestions: null,
  listEditCompaign: null,
  listIdentificationAutomationQuestions: null,
  listClientAutomationQuestions: null,
  listNegotiationAutomationQuestions: null,
  listShippingAutomationQuestions: null,
  pageNow: 1,
  pageSize: 10,
  dataAutomationList: null,
  dataProduct: null,
};

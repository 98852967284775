import { theme } from 'styles/theme';

import { ArrowBackOutlined } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

interface IButtonBackProps {
  text: string;
  onBack: () => void;
}

export function ButtonBack({ text, onBack }: IButtonBackProps) {
  return (
    <Button
      onClick={onBack}
      variant="outlined"
      color="primary"
      size="small"
      sx={{
        borderColor: theme.palette.secondary.main,
        borderWidth: 2,
        '&:hover': {
          borderColor: theme.palette.secondary.main,
          borderWidth: '2px',
        },
      }}
      startIcon={<ArrowBackOutlined sx={{ fontSize: 22, color: theme.palette.secondary.main }} />}
    >
      <Typography fontSize={13} fontWeight={700} color={theme.palette.secondary.main}>
        {text}
      </Typography>
    </Button>
  );
}

import api from 'clients/api/Api';

import {
  ICreateSolicitation,
  IGetSolicitation,
  ISolicitationResponse,
} from 'domains/solicitation/Solicitation.domain';

const getAll = async (params: IGetSolicitation) => {
  const { data } = await api.get<ISolicitationResponse>('cardio/Solicitation/All', {
    params,
  });
  return data;
};

const create = async (params: ICreateSolicitation) => {
  const { data } = await api.post('cardio/Solicitation', params);
  return data;
};

const approve = async (id: string, userSector: string) => {
  const { data } = await api.put(`cardio/Solicitation/Approve/${id}`, {
    userSector,
  });
  return data;
};
const reprove = async (id: string, userSector: string) => {
  const { data } = await api.put(`cardio/Solicitation/Reprove/${id}`, {
    userSector,
  });
  return data;
};

export const SolicitationService = { create, getAll, approve, reprove };

import { SxProps } from '@mui/material';

export const cardContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  pt: 2,
  px: 2,
  pb: 1,
  minHeight: '280px',
  bgcolor: 'white',
  width: '100%',
  border: '1px solid #fff',
  boxShadow: 'rgba(50, 50, 93, 0.25) 0px 0px 5px 0px',
  borderRadius: 1,
};
export const cardImg: SxProps = {
  width: 90,
  height: 90,
};
export const cardTitle: SxProps = {
  fontSize: '16px',
  fontWeight: 'bold',
  mt: 3,
};
export const cardTxt: SxProps = {
  fontSize: '14px',
  mt: 2,
  color: '#716e6e',
  fontWeight: 'medium',
  textAlign: 'left',
};
export const cardLink: SxProps = {
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  alignContent: 'flex-end',
  alignItems: 'flex-end',
  fontSize: '14px',
  fontWeight: 'bold',
  textAlign: 'right',
  color: 'lightblue',
};

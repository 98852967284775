import { theme } from 'styles/theme';

import { SxProps } from '@mui/material';

export const txtColor: SxProps = {
  color: '#FFF',
  fontWeight: 'bold',
  fontSize: '12px',
};
export const boxIcon: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 50,
  bgcolor: '#FFF',
  width: '24px',
  height: '24px',
};
export const boxTxt: SxProps = {
  display: 'flex',
  alignItems: 'center',
  pl: '2px',
};
export const pendingBoxColor: SxProps = {
  bgcolor: '#d3912f',
  borderRadius: 5,
  pl: '5px',
  pr: 2,
  py: '6px',
};
export const activeBoxColor: SxProps = {
  bgcolor: '#3b98e5',
  borderRadius: 5,
  pl: '5px',
  pr: 2,
  py: '6px',
};
export const approvedBoxColor: SxProps = {
  bgcolor: '#1d8f5e',
  borderRadius: 5,
  pl: '5px',
  pr: 2,
  py: '6px',
};
export const recusedBoxColor: SxProps = {
  bgcolor: '#e7002a',
  borderRadius: 5,
  pl: '5px',
  pr: 2,
  py: '6px',
};
export const sendBoxColor: SxProps = {
  bgcolor: theme.palette.secondary.main,
  borderRadius: 5,
  pl: '5px',
  pr: 2,
  py: '6px',
};
export const backMatrixBoxColor: SxProps = {
  bgcolor: '#F49758',
  borderRadius: 5,
  pl: '5px',
  pr: 2,
  py: '6px',
};
export const closedBoxColor: SxProps = {
  bgcolor: '#646464',
  borderRadius: 5,
  pl: '5px',
  pr: 2,
  py: '6px',
};
export const deletedBoxColor: SxProps = {
  bgcolor: '#db4d4d',
  borderRadius: 5,
  pl: '5px',
  pr: 2,
  py: '6px',
};

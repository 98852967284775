import { createStore } from 'effector';

import { newRequestEvent } from './NewRequestEvents';
import { newRequestInitialState, NewRequestState } from './NewRequestState';

const newRequestStore = createStore<NewRequestState>(newRequestInitialState).on(
  newRequestEvent,
  (state, payload) => ({
    ...state,
    ...payload,
  }),
);

export default newRequestStore;

import Keycloak from 'keycloak-js';

import {
  getKeycloakClientId,
  getKeycloakRealm,
  getKeycloakUrl,
} from 'services/HostService/HostService';

const keycloakInitOptions = {
  onLoad: 'login-required',
  url: getKeycloakUrl(),
  realm: getKeycloakRealm(),
  clientId: getKeycloakClientId(),
  checkLoginIframe: false,
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  responseMode: 'query',
  pkceMethod: 'S256',
};

const keycloak = new Keycloak(keycloakInitOptions);

export { keycloak, keycloakInitOptions };

import { createStore } from 'effector';

import { requestManagementDetailEvent } from './RequestManagementDetailEvents';
import {
  RequestManagementDetailState,
  requestManagementDetailInitialState,
} from './RequestManagementDetailState';

const requestManagementDetailStore = createStore<RequestManagementDetailState>(
  requestManagementDetailInitialState,
).on(requestManagementDetailEvent, (state, payload) => ({
  ...state,
  ...payload,
}));

export default requestManagementDetailStore;

import { MedicineService } from 'services/MedicineService/MedicineService';

import { Common } from 'utils';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { medicineManagementEvent } from 'stores/MedicineManagement/MedicineManagementEvents';
import medicineManagementStore from 'stores/MedicineManagement/MedicineManagementStore';

import { getMedicineManagementUseCase } from './getMedicineManagementUseCase';

const execute = async () => {
  try {
    medicineManagementEvent({ isLoadingModalStatus: true });
    await Common.sleep(2000);
    const { changeStatus } = medicineManagementStore.getState();
    await MedicineService.changeStatusMedicine(changeStatus.id, changeStatus.isActive);
    alertEvent({ open: true, text: 'Status atualizado com sucesso.', type: 'success' });
  } catch (err) {
    alertEvent({
      open: true,
      text: 'Ocorreu um erro ao atualizar status, tente mais tarde.',
      type: 'error',
    });
  } finally {
    medicineManagementEvent({ isOpenModalStatus: false, isLoadingModalStatus: false });
    getMedicineManagementUseCase.execute();
  }
};

export const changeStatusMedicineUseCase = { execute };

import { createStore } from 'effector';

import { productManagementEvent } from './ProductManagementEvents';
import { ProductManagementState, productManagementInitialState } from './ProductManagementState';

const productManagementStore = createStore<ProductManagementState>(
  productManagementInitialState,
).on(productManagementEvent, (state, payload) => ({
  ...state,
  ...payload,
}));

export default productManagementStore;

import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContent = styled('div')(() => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export default function Page401() {
  return (
    <Container style={{ backgroundColor: '#fff', borderRadius: 28 }}>
      <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="caption" paragraph>
          Erro: 401
        </Typography>
        <Typography variant="h3" paragraph>
          Acesso restrito
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          Lamentamos mas você não tem permissão para acessar essa página. Por favor, faça login no
          sistema para obter acesso.
        </Typography>
        <Box
          component="img"
          src="/assets/illustration_401.png"
          sx={{ height: 260, mx: 'auto', my: 4 }}
        />
        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Voltar
        </Button>
      </StyledContent>
    </Container>
  );
}

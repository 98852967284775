import { createStore } from 'effector';

import { formDynamicFormEvent } from './FormDynamicFormEvents';
import { FormDynamicFormState, formDynamicFormInitialState } from './FormDynamicFormState';

const formDynamicFormStore = createStore<FormDynamicFormState>(formDynamicFormInitialState).on(
  formDynamicFormEvent,
  (state, payload) => ({
    ...state,
    ...payload,
  }),
);

export default formDynamicFormStore;

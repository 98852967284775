/* eslint-disable react/require-default-props */
import { Controller } from 'react-hook-form';

import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

export default function SelectInput(props) {
  const { control, name, options, defaultValue, errorMessage, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        return (
          <FormControl fullWidth>
            <InputLabel htmlFor={`input-select-${name}`}>{rest?.label}</InputLabel>
            <Select
              displayEmpty
              fullWidth
              variant="outlined"
              value={value}
              onChange={v => {
                onChange(v.target.value);
              }}
              startAdornment={!!value && <InputAdornment position="start"> </InputAdornment>}
              placeholder="Selecione"
              inputProps={{
                name,
                id: `input-select-${name}`,
              }}
              sx={{
                fontSize: 16,
                height: 42,
              }}
              {...rest}
            >
              {options.map(item => (
                <MenuItem key={item.value} value={item.value} style={{ fontSize: 16 }}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            {!!errorMessage && (
              <FormHelperText
                sx={{
                  fontSize: '0.75rem',
                  color: 'red',
                  ml: 1.5,
                }}
              >
                {errorMessage}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}

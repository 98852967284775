import api from 'clients/api/Api';

import { ICompaignsPagedResponse, ICompaignsResponse } from 'domains/compaigns';

interface IParams {
  requestType: 'SRA' | 'MRPA' | 'AOP' | 'DynamicForm';
}

const getAllCompaigns = async () => {
  const { data } = await api.get<ICompaignsResponse>('cardio/Campaign/All');
  return data;
};

const getCompaigns = async (page: number, size: number, status = true) => {
  const { data } = await api.get<ICompaignsPagedResponse>('cardio/Campaign/AllPaged', {
    params: { page, size, status },
  });
  return data;
};

const getCompaignsByRequestType = async (params: IParams) => {
  const { data } = await api.get<ICompaignsResponse>('cardio/Campaign/ActivesByType', {
    params: {
      RequestType: params?.requestType,
    },
  });
  return data;
};

const createCompaign = async (name: string) => {
  const { data } = await api.post('cardio/Campaign', {
    name,
  });
  return data;
};

export const CompaignsService = {
  getCompaigns,
  getCompaignsByRequestType,
  createCompaign,
  getAllCompaigns,
};

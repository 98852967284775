/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import CompaignsManagementPage from 'pages/CompaignManagementPage/CompaignManagementPage';
import DynamicFormPage from 'pages/FormDynamic/DynamicForm/DynamicFormPage';
import DynamicFormPageEdit from 'pages/FormDynamic/DynamicFormEdit/DynamicFormPageEdit';
import LoginPage from 'pages/LoginPage/LoginPage';
import MedicineManagementPage from 'pages/MedicineManagementPage/MedicineManagementPage';
import MyRequestPage from 'pages/MyRequestPage/MyRequestPage';
import NewRequestPage from 'pages/NewRequestPage/NewRequestPage';
import Page401 from 'pages/Page401/Page401';
import Page404 from 'pages/Page404/Page404';
import PartnersManagementPage from 'pages/PartnersManagementPage/PartnersManagementPage';
import ProductManagementPage from 'pages/ProductManagementPage/ProductManagementPage';
import RequestManagementPage from 'pages/RequestManagementPage/RequestManagementPage';

import { Layout } from 'components/ui/layout';
import ProtectedRoute from 'components/ui/ProtectedRoute/ProtectedRoute';

import { Common } from 'utils';

import { authEvent } from 'stores/AuthStore/AuthEvents';
import ExportManagementPage from 'pages/ExportManagementPage/ExportManagementPage';

export default function Routes(): JSX.Element {
  const [session] = useState(Common.getSessionValue('logCookie'));

  const loadData = () => {
    try {
      if (!session || !session.includes('name')) {
        Common.setSessionValue('logCookie', null);
        Common.clearCacheData();
        return;
      }
      const parseData = JSON.parse(session);
      if (parseData) authEvent(parseData);
    } catch (err) {
      console.log(err);
    }
  };

  useLayoutEffect(() => {
    loadData();
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={LoginPage} />
        <Route path="/401" component={Page401} />
        <Route path="/404" component={Page404} />
        {session ? (
          <Layout>
            <Route path="/app" />
            <ProtectedRoute roles={[0, 2]}>
              <Route path="/app/new-request" component={NewRequestPage} />
            </ProtectedRoute>
            <ProtectedRoute roles={[0]}>
              <Route path="/app/request-management" component={RequestManagementPage} />
            </ProtectedRoute>
            <ProtectedRoute roles={[0]}>
              <Route path="/app/export-management" component={ExportManagementPage} />
            </ProtectedRoute>
            <ProtectedRoute roles={[2]}>
              <Route path="/app/my-requests" component={MyRequestPage} />
            </ProtectedRoute>
            <ProtectedRoute roles={[0, 2]}>
              <Route path="/app/partners-management" component={PartnersManagementPage} />
            </ProtectedRoute>
            <ProtectedRoute roles={[0]}>
              <Route path="/app/compaigns-management" component={CompaignsManagementPage} />
            </ProtectedRoute>
            <ProtectedRoute roles={[0]}>
              <Route path="/app/medicine-management" component={MedicineManagementPage} />
            </ProtectedRoute>
            <ProtectedRoute roles={[0]}>
              <Route path="/app/product-management" component={ProductManagementPage} />
            </ProtectedRoute>
            <ProtectedRoute roles={[0, 2]}>
              <Route path="/app/form/:id" component={DynamicFormPage} />
            </ProtectedRoute>
            <ProtectedRoute roles={[0, 2]}>
              <Route path="/app/update-form/:id" component={DynamicFormPageEdit} />
            </ProtectedRoute>
          </Layout>
        ) : (
          <Redirect to="/" />
        )}
      </Switch>
    </BrowserRouter>
  );
}

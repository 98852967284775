import { ProductsService } from 'services/ProductService/ProductService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { newRequestEvent } from 'stores/NewRequest/NewRequestEvents';

const execute = async () => {
  try {
    newRequestEvent({ isLoading: true });
    const { data } = await ProductsService.getAllProducts();
    newRequestEvent({ list: data.products });
  } catch (err) {
    alertEvent({
      open: true,
      type: 'error',
      text: 'Não foi possível buscar os produtos no momento, tente mais tarde',
    });
  } finally {
    newRequestEvent({ isLoading: false });
  }
};
export const getNewRequestProductsUseCase = { execute };

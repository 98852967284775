import { RequestService } from 'services/RequestService/RequestService';

import { Common } from 'utils';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';
import { requestManagementEvent } from 'stores/RequestManagement/RequestManagementEvents';
import requestManagementStore from 'stores/RequestManagement/RequestManagementStore';

import { IAtuhEnum } from 'domains/auth/AuthDomain';

const execute = async () => {
  try {
    requestManagementEvent({ isLoadingExport: true });
    const { sector, role } = authStore.getState();
    const { filters } = requestManagementStore.getState();
    const sectorStr = role === IAtuhEnum.ADMIN ? IAtuhEnum.ADMIN_CODE : sector;
    const params = {
      UserSector: sectorStr,
      StartDate: filters?.StartDate || null,
      EndDate: filters?.EndDate || null,
      Status: filters?.Status || null,
      ProductId: filters?.ProductId || null,
    };

    const result = await RequestService.exportRequests(params);
    await Common.sleep(3000);

    if (!result?.data) {
      requestManagementEvent({ isLoadingExport: false });
      alertEvent({
        open: true,
        text: 'Ocorreu um erro na exportação, tente novamente mais tarde.',
        type: 'error',
      });
      return;
    }

    const base64Data = result?.data || '';
    const decodedData = atob(base64Data);
    const blob = new Blob([decodedData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'solicitacoes_cardio360.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    alertEvent({
      open: true,
      text: 'Exportação realizada com sucesso.',
      type: 'success',
    });
  } catch (err) {
    alertEvent({
      open: true,
      text: 'Não foi possível realizar a exportação no momento, tente novamente mais tarde.',
      type: 'error',
    });
  } finally {
    requestManagementEvent({ isLoadingExport: false });
  }
};

export const exportRequestsUseCase = { execute };

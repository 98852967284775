/* eslint-disable react/require-default-props */
import { useEffect, useState } from 'react';

import { useStore } from 'effector-react';

import { CloseOutlined } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Drawer, IconButton, Stack, Typography } from '@mui/material';

import { approveUseCase } from 'useCases/notifications/approveUseCase';
import { getNotificationsUseCase } from 'useCases/notifications/getNotificationsUseCase';
import { reproveUseCase } from 'useCases/notifications/reproveUseCase';

import { notificationEvent } from 'stores/Notifications/NotificationsEvents';
import notificationStore from 'stores/Notifications/NotificationsStore';

import ConfirmModal from '../confirmModal/ConfirmModal';
import DrawerNotificationsLoading from './DrawerLoading';
import {
  solicitationsContainerStyle,
  solicitationsContentStyle,
} from './DrawerNotifications.styles';
import ListEquipmentReturn from './ListEquipmentReturn';
import ListPartnerTransfer from './ListPartnerTransfer';

function DrawerNotifications() {
  const [activeTab, setActiveTab] = useState(0);
  const {
    loading,
    open,
    openModalApprove,
    openModalReprove,
    pageTabManagerTransfer,
    pageTabEquipmentReturn,
  } = useStore(notificationStore);
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    getNotificationsUseCase.execute();
  }, [open, pageTabEquipmentReturn, pageTabManagerTransfer]);

  const onClose = () => notificationEvent({ open: false });

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Stack direction="column" flex={1} sx={solicitationsContainerStyle}>
        <Stack sx={solicitationsContentStyle}>
          <Box>
            <Typography fontSize={22} fontWeight={700}>
              Solicitações
            </Typography>
            <Typography fontSize={14}>
              Acompanhe suas transferências de parcerias e remanejamentos para matriz.
            </Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Stack>
        <ButtonGroup variant="outlined" sx={{ px: 3, width: '100%' }}>
          <Button
            fullWidth
            variant="contained"
            sx={{ bgcolor: activeTab === 0 ? '#005391' : '#3B98E5' }}
            onClick={() => setActiveTab(0)}
          >
            TRANSFERÊNCIAS DE PARCERIA
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ bgcolor: activeTab === 1 ? '#005391' : '#3B98E5' }}
            onClick={() => setActiveTab(1)}
          >
            RETORNO DE EQUIPAMENTO
          </Button>
        </ButtonGroup>
        {loading ? (
          <DrawerNotificationsLoading />
        ) : (
          <Stack p={2} bgcolor="white" flex={1} direction="column" gap={2}>
            {
              [
                <ListPartnerTransfer setSelectedId={setSelectedId} />,
                <ListEquipmentReturn setSelectedId={setSelectedId} />,
              ][activeTab]
            }
          </Stack>
        )}
      </Stack>
      <ConfirmModal
        open={openModalApprove}
        setOpen={v => notificationEvent({ openModalApprove: v })}
        text="Deseja realmente aprovar essa solicitação ?"
        onConfirm={() => approveUseCase.execute(selectedId)}
        isLoading={false}
        title="Atenção"
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
      <ConfirmModal
        open={openModalReprove}
        setOpen={v => notificationEvent({ openModalReprove: v })}
        text="Deseja realmente recusar essa solicitação ?"
        onConfirm={() => reproveUseCase.execute(selectedId)}
        isLoading={false}
        title="Atenção"
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
    </Drawer>
  );
}

export default DrawerNotifications;

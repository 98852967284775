import { RequestService } from 'services/RequestService/RequestService';

import { Common } from 'utils';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { IAtuhEnum } from 'domains/auth/AuthDomain';

const execute = async (type: 'history' | 'partners') => {
  try {
    partnersManagementEvent({ isLoadingExport: true });
    const { sector, role } = authStore.getState();
    const { filters } = partnersManagementStore.getState();
    const sectorStr = role === IAtuhEnum.ADMIN ? IAtuhEnum.ADMIN_CODE : sector;
    const params = { ...filters, UserSector: sectorStr };

    const result =
      type === 'history'
        ? await RequestService.exportPartnersHistory(params)
        : await RequestService.exportPartners(params);

    await Common.sleep(3000);

    if (!result?.data) {
      partnersManagementEvent({ isLoadingExport: false });
      alertEvent({
        open: true,
        text: 'Ocorreu um erro na exportação, tente novamente mais tarde.',
        type: 'error',
      });
      return;
    }

    const base64Data = result?.data || '';
    const decodedData = atob(base64Data);
    const blob = new Blob([decodedData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = type === 'history' ? 'movimentacoes_cardio360.csv' : 'parcerias_cardio360.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    alertEvent({
      open: true,
      text: 'Exportação realizada com sucesso.',
      type: 'success',
    });
  } catch (err) {
    alertEvent({
      open: true,
      text: 'Não foi possível realizar a exportação no momento, tente novamente mais tarde.',
      type: 'error',
    });
  } finally {
    partnersManagementEvent({ isLoadingExport: false });
  }
};

export const exportPartnersUseCase = { execute };

/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/require-default-props */
import { Stack, TextField, TextFieldProps, Typography, useTheme } from '@mui/material';

import { Formatter } from 'utils';
import './style.css';

type InputProps = {
  maxLength?: number;
  height?: number;
  onlyWords?: boolean;
  mask?: 'CPF' | 'CNPJ' | 'CEP' | 'PHONE' | 'CPF_CNPJ';
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
};

type ExtendedInputProps = InputProps & TextFieldProps;

function TextInput({ ...others }: ExtendedInputProps) {
  const params = { ...others };
  const inputError = params.helperText;
  delete params.helperText;
  const inputId = `${params?.name}-input`;
  const theme = useTheme();

  const applyMask = val => {
    const strippedValue = val?.replace(/[^\d]/g, ''); // Remove all non-digit characters
    switch (params?.mask) {
      case 'CPF':
        return Formatter.maskCpf(val);
      case 'CNPJ':
        return Formatter.maskCnpj(val);
      case 'CEP':
        return Formatter.maskCep(val);
      case 'PHONE':
        return Formatter.maskPhone(val);
      case 'CPF_CNPJ':
        if (strippedValue?.length === 11) {
          // Apply CPF mask for 11 characters
          return Formatter.maskCpf(strippedValue);
        }
        if (strippedValue?.length === 14) {
          // Apply CNPJ mask for 14 characters
          return Formatter.maskCnpj(strippedValue);
        }
        return val; // If the length is neither 11 nor 14, return the original value

      default:
        return val;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const validatedValue = inputValue;

    if (params?.type === 'number' && typeof validatedValue === 'string' && validatedValue !== '') {
      const numericValue = Number(validatedValue);
      if (isNaN(numericValue) || numericValue < 0) {
        event.preventDefault();
        return;
      }
    }
    if (params?.type === 'number' && validatedValue !== '' && params.maxLength) {
      const numericValue = validatedValue;
      if (numericValue.length > params.maxLength) {
        event.preventDefault();
        return;
      }
    }

    if (params?.onChange) {
      params.onChange({ ...event });
    }
  };
  function maskedValue(val) {
    const value = applyMask(val);

    return value;
  }
  return (
    <Stack direction="column" sx={{ width: '100%' }}>
      <Typography component="label" htmlFor={inputId} fontSize={14}>
        {params?.label}
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        id={inputId}
        {...params}
        label=""
        margin="none"
        onChange={handleChange}
        value={maskedValue(params.value)}
        InputProps={{
          startAdornment: params?.startAdornment && params.startAdornment,
          endAdornment: params?.endAdornment && params.endAdornment,
        }}
        inputProps={{
          maxLength: params?.maxLength ?? null,
          min: 0,
          style: {
            fontSize: 16,
            height: !params?.height ? 'auto' : params?.height,
            outline: '#F8F9FD',
            borderInlineColor: '#F8F9FD',
            background: '#FFF',
            borderColor: params?.error ? theme.palette.error.main : theme.palette.primary.main,
          },
        }}
      />
      {!!inputError && (
        <Typography fontSize="12px" color="red" style={{ height: 8 }}>
          {inputError}
        </Typography>
      )}
    </Stack>
  );
}

export default TextInput;

import { ProductsService } from 'services/ProductService/ProductService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';

import { getAutomationListProductManagementUseCase } from './getAutomationUseCase';

const execute = async (automationId: string, productId: string) => {
  try {
    productManagementEvent({ isLoading: true });

    ProductsService.deleteAutomationProduct(automationId)
      .then(() => {
        getAutomationListProductManagementUseCase.execute(productId);
        productManagementEvent({ isOpenModalDeleteAutomationSubmit: false });
      })
      .catch(() => {
        alertEvent({
          open: true,
          text: `Ocorreu um erro ao excluir a automação, tente novamente mais tarde`,
          type: 'error',
        });
      });
  } catch {
    alertEvent({
      open: true,
      text: `Ocorreu um erro ao excluir a automação, tente novamente mais tarde`,
      type: 'error',
    });
  } finally {
    alertEvent({
      open: true,
      text: `Automação excluída com sucesso`,
      type: 'success',
    });
    productManagementEvent({ isLoading: false, isOpenModalDeleteAutomationSubmit: false });
  }
};

export const deleteAutomationUseCase = { execute };

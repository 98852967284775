/* eslint-disable camelcase */
import SelectInput from 'components/form/selectInput';
import TextInput from 'components/form/textInput';

import {
  validateCep,
  validateCheckboxes,
  validateCnpj,
  validateCpfCNPJ,
  validateInputNumber,
  validateRadioForm,
  validateRequestCRMs,
  validateSessionPhoneNumber,
  validateSessionUserName,
  validateShippingCPF,
  validateShippingEmail,
} from './DynamicFormValidators';

export function getComponentAndProps(item) {
  const { questionType } = item.question;
  const { options } = item;
  const label = item.question.description;
  if (questionType === 'CPF_CNPJ') {
    return { component: TextInput, props: { label, mask: 'CPF_CNPJ', maxLength: 18 } };
  }
  if (questionType === 'CPF' || questionType === 'PartnerCPF') {
    return {
      component: TextInput,
      props: { label, mask: 'CPF', maxLength: 14, placeholder: '000.000.000-00' },
    };
  }
  if (questionType === 'CNPJ') {
    return {
      component: TextInput,
      props: { label, mask: 'CNPJ', maxLength: 18, placeholder: '00.000.000/0000-00' },
    };
  }
  if (questionType === 'Email') {
    return { component: TextInput, props: { label } };
  }
  if (questionType === 'Address') {
    return { component: 'Address' };
  }
  if (questionType === 'TextInput') {
    return { component: TextInput, props: { label } };
  }
  if (questionType === 'Textarea') {
    return {
      component: TextInput,
      props: { label, multiline: true, rows: 4 },
    };
  }
  if (questionType === 'Phone') {
    return {
      component: TextInput,
      props: { label, mask: 'PHONE', maxLength: 15, placeholder: '(00) 0000-0000' },
    };
  }
  if (['NumberInput', 'Sector', 'EquipmentQty', 'PatientQty', 'ReportQty'].includes(questionType)) {
    return { component: TextInput, props: { label, type: 'number', maxLength: 8 } };
  }
  if (questionType === 'Business') {
    return { component: 'radiobutton', props: { label } };
  }
  if (questionType === 'UF_CRM') {
    return { component: 'ufCrm', props: { label } };
  }
  if (questionType === 'Medicine' || questionType === 'Investment') {
    return { component: 'medicine', props: { label } };
  }
  if (options && options.length > 0) {
    return { component: SelectInput, props: { label, options } };
  }

  return { component: TextInput, props: { label } };
}

export function getComponentRulesValidation(item) {
  const { questionType } = item.question;

  if (questionType === 'CPF_CNPJ') {
    return { ruleValidation: validateCpfCNPJ };
  }
  if (questionType === 'CPF' || questionType === 'PartnerCPF') {
    return { ruleValidation: validateShippingCPF };
  }
  if (questionType === 'CEP') {
    return { ruleValidation: validateCep };
  }
  if (questionType === 'CNPJ') {
    return { ruleValidation: validateCnpj };
  }
  if (questionType === 'Email') {
    return { ruleValidation: validateShippingEmail };
  }
  if (questionType === 'TextInput') {
    return { ruleValidation: validateSessionUserName };
  }
  if (['NumberInput', 'Sector', 'EquipmentQty', 'PatientQty', 'ReportQty'].includes(questionType)) {
    return { ruleValidation: validateInputNumber };
  }
  if (questionType === 'Phone') {
    return { ruleValidation: validateSessionPhoneNumber };
  }
  if (questionType === 'UF_CRM') {
    return { ruleValidation: validateRequestCRMs };
  }
  if (questionType === 'Medicine' || questionType === 'Investment') {
    return { ruleValidation: validateCheckboxes };
  }
  if (questionType === 'Business') {
    return { ruleValidation: validateRadioForm };
  }

  return { ruleValidation: validateSessionUserName };
}

/* eslint-disable no-unsafe-optional-chaining */
import axios, { AxiosError } from 'axios';

import { getApiHost } from 'services/HostService/HostService';

import { authStore } from 'stores/AuthStore/AuthStore';

const api = axios.create({
  baseURL: getApiHost(),
});

const authInterceptor: any = async config => {
  const { token } = authStore.getState();
  if (!token) return config;
  if (!config?.headers?.Authorization) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

api.interceptors.request.use(
  async config => {
    const newConfig = await authInterceptor(config);
    return newConfig;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => {
    return response;
  },
  async (error: AxiosError) => {
    return Promise.reject(error);
  },
);

export default api;

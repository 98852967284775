import { ProductsService } from 'services/ProductService/ProductService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';
import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';
import productManagementStore from 'stores/ProductManagement/ProductManagementStore';

import { IParamsCreateProduct } from 'domains/product';

import { getProductManagementUseCase } from './getProductManagementUseCase';

const execute = async (formData: any) => {
  try {
    const { sector } = authStore.getState();
    productManagementEvent({ isLoadingForm: true });
    const {
      listClientQuestions,
      listIdentificationQuestions,
      listNegotiationQuestions,
      listShippingQuestions,
      listCompaign,
    } = productManagementStore.getState();

    const formattedQuestionList = [
      ...listClientQuestions,
      ...listIdentificationQuestions,
      ...listNegotiationQuestions,
      ...listShippingQuestions,
    ]
      ?.filter(obj => obj.isActive)
      ?.sort((a, b) => (a.order < b.order ? -1 : 1))
      ?.map((obj, idx) => ({
        id: obj.id,
        order: idx + 1,
        isRequired: true,
      }));

    const base64 = formData?.image?.contentFile;
    const submitData: IParamsCreateProduct = {
      userSector: sector,
      name: formData?.name,
      description: formData?.description,
      thumbnail: base64 || '',
      questions: formattedQuestionList,
      campaigns: listCompaign?.filter(obj => obj.isActive)?.map(it => ({ id: it.id })) || [],
    };
    await ProductsService.createProduct(submitData);
    alertEvent({
      open: true,
      text: 'Produto criado com sucesso.',
      type: 'success',
    });
  } catch (err) {
    alertEvent({
      open: true,
      text: 'Ocorreu um erro ao criar o produto, tente novamente mais tarde.',
      type: 'error',
    });
  } finally {
    productManagementEvent({ isOpenForm: false, isLoadingForm: false, isOpenModalSubmit: false });
    getProductManagementUseCase.execute();
  }
};

export const createProductUseCase = { execute };

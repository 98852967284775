import { RequestService } from 'services/RequestService/RequestService';

import { Formatter } from 'utils';

import { authStore } from 'stores/AuthStore/AuthStore';
import { formDynamicFormEvent } from 'stores/DynamicForm/FormDynamicFormEvents';
import formDynamicFormStore from 'stores/DynamicForm/FormDynamicFormStore';

import { IAtuhEnum } from 'domains/auth/AuthDomain';

const execute = async (form: any) => {
  try {
    const { role } = authStore.getState();
    formDynamicFormEvent({ isLoading: true });
    const { editForm, relocateType, equipmentsIds } = formDynamicFormStore.getState();
    const { sector } = authStore.getState();
    const formattedSubmit = Formatter.formatterDynamicFormSubmitFormData(sector, form);
    if (relocateType && equipmentsIds) {
      await RequestService.createRequest({
        ...formattedSubmit,
        equipmentIds: equipmentsIds,
        requestOriginId: editForm.id,
      });
    } else {
      await RequestService.updateRequest(editForm.id, formattedSubmit);
    }
    formDynamicFormEvent({ isLoading: false, openModal: true });
    window.location.replace(
      role === IAtuhEnum.ADMIN ? '/app/request-management' : '/app/my-requests',
    );
  } catch (error: any) {
    const errorMessage = error?.response?.data?.error;
    formDynamicFormEvent({ isLoading: false, openModalError: true, errorMessage, editForm: null });
  }
};

export const updateFormDynamicFormUseCase = { execute };

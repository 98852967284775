import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContent = styled('div')(() => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export default function Page404() {
  return (
    <Container style={{ backgroundColor: '#fff', borderRadius: 28 }}>
      <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="caption" paragraph>
          Erro: 404
        </Typography>
        <Typography variant="h3" paragraph>
          Página não encontrada
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          Desculpe, não conseguimos encontrar a página que você está procurando. Talvez você tenha
          digitado incorretamente a URL? Certifique-se de verificar erros de ortografia.
        </Typography>
        <Box
          component="img"
          src="/assets/illustration_404.svg"
          sx={{ height: 260, mx: 'auto', my: 4 }}
        />
        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Voltar
        </Button>
      </StyledContent>
    </Container>
  );
}

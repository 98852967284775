import { ProductsService } from 'services/ProductService/ProductService';

import { Common } from 'utils';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';
import productManagementStore from 'stores/ProductManagement/ProductManagementStore';

import { IParamsUpdateProduct } from 'domains/product';

import { getProductManagementUseCase } from './getProductManagementUseCase';

const execute = async (formData: any) => {
  try {
    productManagementEvent({ isLoadingForm: true });
    const {
      listIdentificationEditQuestions,
      listClientEditQuestions,
      listNegotiationEditQuestions,
      listShippingEditQuestions,
      listEditCompaign,
      rowId,
      imageDeleted,
    } = productManagementStore.getState();

    const formattedQuestionList = [
      ...listClientEditQuestions,
      ...listIdentificationEditQuestions,
      ...listNegotiationEditQuestions,
      ...listShippingEditQuestions,
    ]
      ?.filter(obj => obj.isActive)
      ?.sort((a, b) => (a.order < b.order ? -1 : 1))
      ?.map((obj, idx) => ({
        id: obj.id,
        order: idx + 1,
        isRequired: true,
      }));

    const base64 = formData?.image?.contentFile;
    const submitData: IParamsUpdateProduct = {
      userSector: JSON.parse(Common.getSessionValue('logCookie'))?.sector,
      name: formData?.name,
      description: formData?.description,
      questions: formattedQuestionList,
      campaigns: listEditCompaign?.filter(obj => obj.isActive)?.map(it => ({ id: it.id })) || [],
    };
    if (base64) {
      submitData.thumbnail = base64;
    } else if (imageDeleted === true) {
      submitData.thumbnail = '';
    }

    await ProductsService.editProduct(rowId, submitData);
    alertEvent({
      open: true,
      text: 'Produto editado com sucesso.',
      type: 'success',
    });
    getProductManagementUseCase.execute();
  } catch (err) {
    alertEvent({
      open: true,
      text: `Ocorreu um erro ao editar o produto, tente novamente mais tarde.${err}`,
      type: 'error',
    });
  } finally {
    productManagementEvent({
      imageDeleted: false,
      isOpenModalEditSubmit: false,
      isLoadingForm: false,
      isOpenFormEdit: false,
    });
  }
};

export const editProductManagementUseCase = { execute };

/* eslint-disable no-dupe-else-if */
import { RequestService } from 'services/RequestService/RequestService';

import formDynamicFormStore from 'stores/DynamicForm/FormDynamicFormStore';

import { IRelocateTypesEnum } from 'domains/partnersManagement/Partners.domain';

interface IParams {
  data: any;
  cbReset: (v: boolean) => void;
  cbSetValue: (formData) => void;
  getCepInfo: (cep: string, complement?: string) => Promise<void>;
}

const execute = async (params: IParams) => {
  try {
    const { relocateType, editForm } = formDynamicFormStore.getState();
    const { data: form, cbReset, cbSetValue } = params;
    cbReset(true);

    const isRelocation = !!relocateType;
    let data;

    if (relocateType) {
      data = editForm;
    } else {
      const { data: result } = await RequestService.getRequestById(editForm.id);
      data = result;
    }

    const dataCompaigns = form?.campaigns?.map(obj => {
      const row = {};
      const objKey = `${obj.id}__Campaign`;
      const questionAnswer = data?.campaignsAnswers?.find(it => it.campaign.id === obj.id)?.answer;
      row[objKey] = questionAnswer;
      return row;
    });

    const listRelocationQuestions = form?.productQuestions?.filter(obj => {
      const excludedCategoryRelocateExisting = ['Identification', 'Shipping', 'Negotiation'];
      const excludedCategoryRelocateNew = ['Identification', 'Client', 'Shipping', 'Negotiation'];
      const excludedCategoryList =
        relocateType === IRelocateTypesEnum.EXISTING
          ? excludedCategoryRelocateExisting
          : excludedCategoryRelocateNew;
      const isExcludedItem = !excludedCategoryList.includes(obj.question.questionCategory);
      const validation =
        obj.question.questionType === 'EquipmentQty' ||
        (isExcludedItem && obj.question.questionType !== 'UF_CRM');
      return validation;
    });

    const listEditQuestions = form?.productQuestions;

    const listQuestions = isRelocation ? listRelocationQuestions : listEditQuestions;

    const formData = listQuestions?.map(obj => {
      const { id, questionType, questionCategory } = obj.question;
      const objKey = `${id}__${questionType}__${questionCategory}`;
      let questionAnswer = '';
      if (questionType) {
        const questionTypeLowerCase = questionType.toLowerCase();
        if (questionTypeLowerCase.includes('medicine')) {
          questionAnswer = data?.medicines?.map(x => x.id);
        } else if (questionTypeLowerCase.includes('investment')) {
          questionAnswer = data?.investments?.map(x => x.id);
        } else if (questionTypeLowerCase.includes('business')) {
          questionAnswer = data?.business?.[0]?.id || '';
        } else {
          const findAnswer = data?.answers?.find(answer => answer.question.id === id);
          const isUfCrm = questionTypeLowerCase.includes('uf_crm');
          const answer = findAnswer?.answer[0] || '';
          const ufCrmAnswer = findAnswer?.answer.join('__') || '';
          questionAnswer = isUfCrm ? ufCrmAnswer : answer;
        }
      }
      return { [objKey]: questionAnswer };
    });

    const result = {};
    for (const row of formData) {
      const key = Object.keys(row)[0];
      if (key?.toLowerCase()?.includes('uf_crm')) {
        const arr = [];
        for (const item of row[key].split('__')) {
          const id = Math.random() * 999;
          const crm = item.split('-')[1];
          const uf = item.split('-')[0];
          arr.push({ id, crm, uf });
        }
        result[key] = arr;
      } else if (key?.toLowerCase()?.includes('address')) {
        const zipCode = row[key]?.split('_')[0];
        const complement = row[key]?.split('_')[3] || '';
        params.getCepInfo(zipCode, complement);
        result[key] = zipCode;
      } else {
        result[key] = row[key];
      }
    }

    if (!isRelocation) {
      for (const row of dataCompaigns) {
        const key = Object.keys(row)[0];
        result[key] = row[key];
      }
    }

    cbSetValue(result);
    cbReset(false);
  } catch (err) {
    console.log(err);
  }
};

export const loadEditFormUseCase = { execute };

import { AuthService } from 'services/Auth/AuthService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';
import { formDynamicFormEvent } from 'stores/DynamicForm/FormDynamicFormEvents';

interface IParams {
  searchSector: string;
  data: any;
  questionCategoryList: string[];
  cbReset: (v: boolean) => void;
  setIsErrorUser: (v: boolean) => void;
  cbSetValue: (input: string, value: string) => void;
}

const execute = async (params: IParams) => {
  const invalidSector = params?.searchSector?.length < 8;
  if (!params?.data || !params?.searchSector || invalidSector) {
    return;
  }
  const { sector, role } = authStore.getState();
  const result = await AuthService.searchUserBySector({
    Search: params?.searchSector,
    UserRole: role || null,
    UserSector: sector,
  });

  const user = result?.data[0];

  const isGD = (setor: string) => {
    if (!setor) return false;
    const lastNum = setor.slice(-2);
    return lastNum === '00';
  };

  if (!isGD(user?.setor)) {
    const fixSector = `${user?.setor?.slice(0, -2)}00`;
    const resultGD = await AuthService.searchUserBySector({
      Search: fixSector,
      UserRole: role || null,
      UserSector: sector,
    });
    const gd = resultGD?.data[0];
    formDynamicFormEvent({ gdSector: gd?.setor, gdName: gd?.nome });
  }

  if (!user) {
    params.setIsErrorUser(true);
    alertEvent({
      open: true,
      type: 'error',
      text: 'Nenhum usuário encontrado, verifique o setor digitado e tente novamente.',
    });
    return;
  }
  params.setIsErrorUser(false);
  params?.cbReset(true);
  const sectionIdentification = params?.data?.productQuestions?.filter(
    question => question?.question?.questionCategory === 'Identification',
  );
  const nameInput = sectionIdentification?.find(
    obj =>
      obj.question.questionType === 'TextInput' &&
      obj.question.description?.toLowerCase().includes('nome'),
  );
  const emailInput = sectionIdentification?.find(obj => obj.question.questionType === 'Email');
  if (nameInput) {
    const input = `${nameInput.question.id}__${nameInput.question.questionType}__${nameInput.question.questionCategory}`;
    params?.cbSetValue(input, user?.nome);
  }
  if (emailInput) {
    const input = `${emailInput.question.id}__${emailInput.question.questionType}__${emailInput.question.questionCategory}`;
    params?.cbSetValue(input, user?.email_Corporativo);
  }
  params?.cbReset(false);
};

export const loadSearchUserInfoUseCase = { execute };

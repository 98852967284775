/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable max-params */
import { useEffect } from 'react';

import { useStore } from 'effector-react';
import TooltipBootstrap from 'pages/MyRequestPage/TooltipBootstrap';
import {
  columnsStyle,
  containerStyle,
  rowsStyle,
  rowsStyleBold,
  rowsStyleBoldDarkBlue,
  rowsStyleBoldGreen,
  rowsStyleTag,
  rowsStyleTagMore,
  rowsStyleTextTag,
  rowsStyleTextTagMore,
  tooltipTxtStyle,
} from 'styles/global.styles';

import { EmojiFlags, FmdGood } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ContentPage from 'components/ui/content';
import FiltersPartner from 'components/ui/filtersPartner/FiltersPartner';
import CustomPagination from 'components/ui/pagination/Pagination';
import SkeletonTableLoading from 'components/ui/skeletonTable/SkeletonTable';
import { StatusCard } from 'components/ui/status';

import { getPartnersManagementUseCase } from 'useCases/partnersManagement/getPartnersManagementUseCase';

import { Common, Formatter } from 'utils';

import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { StatusDiagnostic } from 'components/ui/statusDiagnostic';
import DrawerEditCrm from './drawers/DrawerEditCrm';
import DrawerEditMedicine from './drawers/DrawerEditMedicine';
import DrawerPartnerReportQty from './drawers/DrawerEditReportQty';
import { DrawerFilters } from './drawers/DrawerFilters';
import DrawerPartnerCoManagers from './drawers/DrawerPartnerCoManagers';
import DrawerPartnerManager from './drawers/DrawerPartnerManager';
import DrawerPartnersDetails from './drawers/DrawerPartnersDetails';
import DrawerRelocateEquipment from './drawers/DrawerRelocateEquipment';
import DrawerSerialNumber from './drawers/DrawerSerialNumber';

export default function PartnersManagementPage(): JSX.Element {
  const { dataSource, pageSize, pageNow, isLoading, filters } = useStore(partnersManagementStore);

  useEffect(() => {
    getPartnersManagementUseCase.execute();
  }, [pageNow, pageSize, filters]);

  useEffect(() => {
    partnersManagementEvent({ filters: null, pageNow: 1 });
  }, []);

  useEffect(() => {
    partnersManagementEvent({ pageNow: 1 });
  }, [filters]);

  const tableData = dataSource?.data?.partners;

  return (
    <Box sx={{ ...containerStyle, flexDirection: 'column' }}>
      <ContentPage title="Gestão de Parcerias">
        <FiltersPartner />
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 0px 5px 0px' }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={columnsStyle} align="left">
                  Parceiro
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Diagnósticos
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Vigência
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Status
                </TableCell>
                <TableCell sx={columnsStyle} align="center">
                  Gestão
                </TableCell>
                <TableCell sx={columnsStyle} align="center">
                  Cogestão
                </TableCell>
                <TableCell sx={columnsStyle} align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <SkeletonTableLoading />
              ) : (
                <>
                  {tableData?.length === 0 && (
                    <Box p={3} minWidth="300px">
                      <Typography variant="caption" textAlign="center">
                        Nenhum registro encontrado...
                      </Typography>
                    </Box>
                  )}

                  {tableData?.map(row => {
                    if (row.requests.filter(it => it.productName !== 'Recompra SRA').length > 0)
                      return (
                        <TableRow
                          key={Math.random() * new Date().getTime()}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" sx={rowsStyle}>
                            <Stack direction="column" gap={1}>
                              <Typography sx={{ ...rowsStyleBoldDarkBlue, minWidth: 116 }}>
                                {row.partner?.name || '-'}
                              </Typography>
                              {row.partner?.initiative && <StatusDiagnostic statusCode='Initiative' label={row.partner?.initiative} />}
                            </Stack>
                          </TableCell>
                          <TableCell align="left" sx={rowsStyle}>
                            {row.requests.filter(it => it.productName !== 'Recompra SRA').length >
                              2 ? (
                              <Stack flexDirection="row" gap={1} width="auto">
                                {row.requests
                                  .filter(it => it.productName !== 'Recompra SRA')
                                  .slice(0, 2)
                                  .map(item => (
                                    <Box sx={rowsStyleTag}>
                                      <Typography sx={rowsStyleTextTag}>
                                        {item.productName}
                                      </Typography>
                                    </Box>
                                  ))}
                                <Box sx={rowsStyleTagMore}>
                                  <TooltipBootstrap
                                    title={row?.requests
                                      .filter(it => it.productName !== 'Recompra SRA')
                                      .map(item => (
                                        <Box>
                                          <Typography sx={tooltipTxtStyle}>
                                            {`${item.productName};`}
                                          </Typography>
                                        </Box>
                                      ))}
                                    placement="top"
                                  >
                                    <Typography sx={rowsStyleTextTagMore} whiteSpace="nowrap">
                                      {`+${Math.max(row?.requests?.length, 0) - 2}`}
                                    </Typography>
                                  </TooltipBootstrap>
                                </Box>
                              </Stack>
                            ) : (
                              <Stack flexDirection="row" gap={1}>
                                {row.requests
                                  .filter(it => it.productName !== 'Recompra SRA')
                                  .map(item => (
                                    <Box sx={rowsStyleTag}>
                                      <Typography sx={rowsStyleTextTag} whiteSpace="nowrap">
                                        {item.productName}
                                      </Typography>
                                    </Box>
                                  ))}
                              </Stack>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Typography sx={rowsStyleBold}>
                              <Box py={1}>
                                <Stack direction="row" gap={1}>
                                  <FmdGood sx={{ color: 'green' }} />
                                  <Typography sx={rowsStyleBoldGreen}>
                                    {Common.getEarliestRequestValidityDate(row.requests)}
                                  </Typography>
                                </Stack>
                                <Stack direction="row" gap={1} mt={1}>
                                  <EmojiFlags sx={{ color: '#005391' }} />
                                  <Typography sx={rowsStyleBoldDarkBlue}>
                                    {Common.getMostRecentExpiryDateOfRequests(row.requests)}
                                  </Typography>
                                </Stack>
                              </Box>
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <StatusCard statusCode={Common.getStatusPartners(row.requests)} />
                          </TableCell>
                          <TableCell align="center">
                            {row?.users
                              ?.filter(obj => obj.owner)
                              .map(obj => (
                                <Typography sx={rowsStyleBold}>
                                  {Formatter.parseName(obj.name)}
                                </Typography>
                              ))}
                          </TableCell>
                          <TableCell align="center">
                            {row?.users
                              ?.filter(obj => !obj.owner)
                              .map(obj => (
                                <Typography sx={rowsStyle}>
                                  {Formatter.parseName(obj.name)}
                                </Typography>
                              ))}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Opções da parceria">
                              <IconButton
                                onClick={() =>
                                  partnersManagementEvent({
                                    isOpenDrawerDetails: true,
                                    selectedRow: row,
                                    requestDetails: [],
                                  })
                                }
                              >
                                <img src="/assets/editEye.png" alt="editar" width="30px" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    return null;
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          total={dataSource?.data?.totalPages || 1}
          page={dataSource?.data?.currentPage || 1}
          setPage={v => partnersManagementEvent({ pageNow: v })}
          isLoading={isLoading}
        />
      </ContentPage>
      <DrawerFilters />
      <DrawerPartnersDetails />
      <DrawerEditMedicine />
      <DrawerEditCrm />
      <DrawerRelocateEquipment />
      <DrawerPartnerCoManagers />
      <DrawerPartnerManager />
      <DrawerSerialNumber />
      <DrawerPartnerReportQty />
    </Box>
  );
}

/* eslint-disable max-params */
import { useEffect, useState } from 'react';

import { useStore } from 'effector-react';
import TooltipBootstrap from 'pages/MyRequestPage/TooltipBootstrap';
import {
  columnsStyle,
  containerStyle,
  rowsStyle,
  rowsStyleBold,
  rowsStyleBoldDarkBlue,
  rowsStyleSub,
  rowsStyleTag,
  rowsStyleTagMore,
  rowsStyleTextTag,
  rowsStyleTextTagMore,
  tooltipTxtStyle,
} from 'styles/global.styles';
import { theme } from 'styles/theme';

import { CommentOutlined, EditOutlined } from '@mui/icons-material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TextInput from 'components/form/textInput';
import ConfirmModal from 'components/ui/confirmModal/ConfirmModal';
import ContentPage from 'components/ui/content';
import FiltersRequest from 'components/ui/filtersRequest/FiltersRequest';
import RequestManagementDrawerDetail from 'components/ui/managementRequest/drawerDetail';
import DropDownRequest from 'components/ui/managementRequest/DropDownRequest/DropDownRequest';
import EditValidityModal from 'components/ui/managementRequest/Modals/EditValidityModal/EditValidityModal';
import CustomPagination from 'components/ui/pagination/Pagination';
import SkeletonTableLoading from 'components/ui/skeletonTable/SkeletonTable';
import { StatusCard } from 'components/ui/status';

import { getRequestManagementUseCase } from 'useCases/requestManagement/getRequestManagementUseCase';
import { editValidityModalRequestManagementUseCase } from 'useCases/requestManagementDetail/editValidityRequestManagementModalUseCase';
import { getRequestManagementDetailUseCase } from 'useCases/requestManagementDetail/getRequestManagementDetailUseCase';

import { Formatter } from 'utils';

import { requestManagementEvent } from 'stores/RequestManagement/RequestManagementEvents';
import { requestManagementDetailEvent } from 'stores/RequestManagementDetail/RequestManagementDetailEvents';
import { requestManagementDetailInitialState } from 'stores/RequestManagementDetail/RequestManagementDetailState';
import requestManagementDetailStore from 'stores/RequestManagementDetail/RequestManagementStore';

import { StatusStringEnum } from 'domains/status';

import requestManagementStore from '../../stores/RequestManagement/RequestManagementStore';

import './styles.css';

export default function MyRequestPage(): JSX.Element {
  const {
    dataSource,
    pageSize,
    filters,
    pageNow,
    isLoading,
    isOpenEditValidityModal,
    isOpenCommentAlertModal,
    isOpenAlertModal,
  } = useStore(requestManagementStore);

  const setOpenModal = (val: boolean) => requestManagementEvent({ isOpenAlertModal: val });
  const setOpenCommentModal = (val: boolean) =>
    requestManagementEvent({ isOpenCommentAlertModal: val });

  useEffect(() => {
    requestManagementEvent({ filters: null, pageNow: 1 });
  }, []);

  useEffect(() => {
    requestManagementEvent({ pageNow: 1 });
  }, [filters]);

  useEffect(() => {
    getRequestManagementUseCase.execute();
  }, [pageNow, pageSize, filters]);

  function handleCloseModal() {
    requestManagementEvent({ isOpenEditValidityModal: false });
  }

  const [comment, setComment] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { dataDetail } = useStore(requestManagementDetailStore);

  const closeForm = () => {
    requestManagementDetailEvent(requestManagementDetailInitialState);
    setIsOpen(false);
  };

  const openForm = (id: string) => {
    getRequestManagementDetailUseCase.execute(id);
    setIsOpen(true);
  };

  const [dateValidity, setDateValidity] = useState({ id: '', startDate: '', endDate: '' });

  const handleEditValidity = (id: string, startDate: string, endDate: string) => {
    setDateValidity({ id, startDate, endDate });
  };

  const renderCommentAlertModal = () => {
    return (
      <ConfirmModal
        id="commentAlertUserModal"
        open={isOpenCommentAlertModal}
        setOpen={v => {
          setOpenCommentModal(v);
        }}
        confirmText="OK"
        title="Comentários sobre a solicitação"
        text=""
        customText={<TextInput maxLength={1024} value={comment} multiline disabled height={190} />}
        onConfirm={() => {
          setOpenCommentModal(false);
        }}
        buttonAgreeColorType="primary"
      />
    );
  };

  const handleCommentIconClick = (rowComment = '') => {
    if (rowComment) setComment(rowComment);
    setOpenCommentModal(true);
  };

  const mustShowCommentIcon = (statusId, observation = '') => {
    return (
      [StatusStringEnum.InAnalysis, StatusStringEnum.Reproved].includes(statusId) && !!observation
    );
  };

  return (
    <Box sx={{ ...containerStyle, flexDirection: 'column' }}>
      <ContentPage title="Minhas Solicitações">
        <FiltersRequest />
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 0px 5px 0px' }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={columnsStyle} width="200px" align="left">
                  Solicitante
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Solução
                </TableCell>
                <TableCell sx={columnsStyle} align="center">
                  CRM
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Produtos Negociados
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Status
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Ações
                </TableCell>
                <TableCell sx={columnsStyle} align="left" />
                <TableCell sx={columnsStyle} align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <SkeletonTableLoading />
              ) : (
                <>
                  {(!dataSource || dataSource?.data?.requests?.length === 0) && (
                    <Box p={3} minWidth="300px">
                      <Typography variant="caption" textAlign="center">
                        Nenhum registro encontrado...
                      </Typography>
                    </Box>
                  )}
                  {dataSource !== null &&
                    dataSource?.data?.requests?.map(row => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" sx={rowsStyle}>
                          {row?.users?.map(obj => {
                            return (
                              <>
                                <Typography sx={rowsStyleBoldDarkBlue}>
                                  {Formatter.parseName(obj.name)}
                                </Typography>
                                <Typography sx={rowsStyleSub}>Setor: {obj.sector}</Typography>
                              </>
                            );
                          })}
                        </TableCell>
                        <TableCell align="left">
                          <Typography sx={rowsStyleBold}>{row.product?.name}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          {row?.professionals.length > 2 ? (
                            <Stack flexDirection="row" gap={1}>
                              {row?.professionals.slice(0, 2).map(item => (
                                <Box>
                                  <Typography sx={rowsStyle}>{`${item.ufcrm
                                    .split('_')[0]
                                    ?.replace('-', '')};`}</Typography>
                                </Box>
                              ))}
                              <Box>
                                <TooltipBootstrap
                                  title={row?.professionals.map(item => (
                                    <Box>
                                      <Typography sx={tooltipTxtStyle}>
                                        {`${item.ufcrm.split('_')[0]?.replace('-', '')};`}
                                      </Typography>
                                    </Box>
                                  ))}
                                  placement="top"
                                >
                                  <Typography sx={rowsStyleBoldDarkBlue}>
                                    {`+${Math.max(row?.professionals?.length, 0) - 2}`}
                                  </Typography>
                                </TooltipBootstrap>
                              </Box>
                            </Stack>
                          ) : (
                            <Stack flexDirection="row" gap={1}>
                              {row?.professionals?.map(item => (
                                <Box>
                                  <Typography sx={rowsStyle}>{`${item.ufcrm?.replace(
                                    '-',
                                    '',
                                  )};`}</Typography>
                                </Box>
                              ))}
                            </Stack>
                          )}
                        </TableCell>
                        <TableCell align="left" sx={rowsStyle}>
                          {row.medicines.length > 2 ? (
                            <Stack flexDirection="row" gap={1} width="auto">
                              {row.medicines
                                .slice(0, 2)
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(item => (
                                  <Box sx={rowsStyleTag}>
                                    <Typography sx={rowsStyleTextTag} whiteSpace="nowrap">
                                      {item.name}
                                    </Typography>
                                  </Box>
                                ))}
                              <Box sx={rowsStyleTagMore}>
                                <TooltipBootstrap
                                  title={row.medicines
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map(item => (
                                      <Box>
                                        <Typography sx={tooltipTxtStyle}>{item.name}</Typography>
                                      </Box>
                                    ))}
                                  placement="top"
                                >
                                  <Typography sx={rowsStyleTextTagMore}>
                                    {`+${Math.max(row?.medicines?.length, 0) - 2}`}
                                  </Typography>
                                </TooltipBootstrap>
                              </Box>
                            </Stack>
                          ) : (
                            <Stack flexDirection="row" gap={1}>
                              {row.medicines
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(item => (
                                  <Box sx={rowsStyleTag}>
                                    <Typography sx={rowsStyleTextTag} whiteSpace="nowrap">
                                      {item.name}
                                    </Typography>
                                  </Box>
                                ))}
                            </Stack>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <StatusCard statusCode={row.statusId ?? 'none'} />
                        </TableCell>
                        <TableCell align="left">
                          {row.statusId !== StatusStringEnum.Deleted ? (
                            <DropDownRequest
                              row={row}
                              handleEditValidity={handleEditValidity}
                              handleReprove={null}
                            />
                          ) : (
                            <EditOutlined sx={{ color: '#cecece' }} />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {mustShowCommentIcon(row?.statusId, row?.observation) && (
                            <IconButton
                              color={
                                row?.statusId === StatusStringEnum.Reproved ? 'error' : 'warning'
                              }
                              onClick={() => handleCommentIconClick(row?.observation)}
                            >
                              <CommentOutlined />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            disabled={row.statusId === StatusStringEnum.Deleted}
                            onClick={() => openForm(row.id)}
                            sx={{
                              color:
                                row.statusId === StatusStringEnum.Deleted
                                  ? '#cecece'
                                  : theme.palette.secondary.main,
                            }}
                          >
                            <VisibilityOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          total={dataSource?.data?.totalPages || 1}
          page={dataSource?.data?.currentPage || 1}
          setPage={v => requestManagementEvent({ pageNow: v })}
          isLoading={isLoading}
        />
        <EditValidityModal
          isOpenEditValidityModal={isOpenEditValidityModal}
          handleCloseModal={() => handleCloseModal()}
          submitUseCase={editValidityModalRequestManagementUseCase.execute}
          id={dateValidity.id}
          startDate={dateValidity.startDate}
          endDate=""
          title="Informe a data final de vigência dessa parceria"
          declineText="CANCELAR"
          confirmText="CONFIRMAR"
        />

        <RequestManagementDrawerDetail
          isOpen={isOpen}
          onClose={() => closeForm()}
          data={dataDetail}
        />
        {renderCommentAlertModal()}
        <ConfirmModal
          open={isOpenAlertModal}
          setOpen={v => {
            setOpenModal(v);
            window.location.replace('/app/new-request');
          }}
          confirmText="OK, ENTENDI"
          title="Registrado com sucesso!"
          text="Acompanhe o progresso no menu"
          customText={<b style={{ color: '#005391' }}>Minhas Solicitações</b>}
          onConfirm={() => {
            setOpenModal(false);
          }}
        />
      </ContentPage>
    </Box>
  );
}

import { ICompaignsPagedResponse } from 'domains/compaigns';

export interface CompaignManagementState {
  isLoading: boolean;
  isOpenForm: boolean;
  isOpenModalSubmit: boolean;
  isLoadingForm: boolean;
  list: ICompaignsPagedResponse | null;
  pageNow: number;
  pageSize: number;
}

export const compaignManagementInitialState: CompaignManagementState = {
  isLoading: false,
  isOpenForm: false,
  isOpenModalSubmit: false,
  isLoadingForm: false,
  list: null,
  pageNow: 1,
  pageSize: 10,
};

import { Box, Typography } from '@mui/material';

import { StatusType } from 'domains/status';

import { changedBoxColor, changedTxtColor, newBoxColor, newTxtColor } from './styles';

export function StatusDiagnostic({ statusCode, label = '' }: StatusType) {
  const statusInfo = {
    New: {
      color: newBoxColor,
      txtStyle: newTxtColor,
      text: 'Novo',
    },
    Relocation: {
      color: changedBoxColor,
      txtStyle: changedTxtColor,
      text: 'Remanejado',
    },
    Relocate: {
      color: changedBoxColor,
      txtStyle: changedTxtColor,
      text: 'Remanejamento',
    },
    Initiative: {
      color: changedBoxColor,
      txtStyle: changedTxtColor,
      text: label,
    },
  };

  const status = statusInfo[statusCode];

  if (!status) {
    return null;
  }

  return (
    <div style={{ width: 'fit-content', boxSizing: 'content-box', overflow: 'hidden' }}>
      <Box sx={status.color}>
        <Typography sx={status.txtStyle}>{status.text}</Typography>
      </Box>
    </div>
  );
}

import { AlertColor } from '@mui/material';

export interface AlertState {
  open: boolean;
  type: AlertColor;
  text: string;
}

export const alertInitialState: AlertState = {
  open: false,
  type: 'success',
  text: '',
};

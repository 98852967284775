import { createStore } from 'effector';

import { requestDynamicFormEvent } from './RequestDynamicFormEvents';
import { RequestDynamicFormState, requestDynamicFormInitialState } from './RequestDynamicFormState';

const requestDynamicFormStore = createStore<RequestDynamicFormState>(
  requestDynamicFormInitialState,
).on(requestDynamicFormEvent, (state, payload) => ({
  ...state,
  ...payload,
}));

export default requestDynamicFormStore;

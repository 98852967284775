import { SxProps } from '@mui/material';

export const containerStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100vh',
  backgroundColor: '#F7F7F7',
};

export const cardLoginStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: '#FFF',
  borderRadius: 3,
  padding: 6,
};

export const titleStyle: SxProps = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
};

export const subtitleStyle: SxProps = {
  fontSize: '1rem',
  fontWeight: 'semibold',
  paddingBottom: 3,
};

import { MedicineService } from 'services/MedicineService/MedicineService';

import { Common } from 'utils';

import { medicineManagementEvent } from 'stores/MedicineManagement/MedicineManagementEvents';
import medicineManagementStore from 'stores/MedicineManagement/MedicineManagementStore';

const execute = async () => {
  try {
    medicineManagementEvent({ isLoading: true });
    const { pageNow, pageSize } = medicineManagementStore.getState();
    const result = await MedicineService.getMedicines(pageNow, pageSize);
    await Common.sleep(1000);
    medicineManagementEvent({ list: result });
  } catch (err) {
    // console.log(err)
  } finally {
    medicineManagementEvent({ isLoading: false });
  }
};

export const getMedicineManagementUseCase = { execute };

import { useEffect } from 'react';

import { useStore } from 'effector-react';

import { Box, Card, Typography } from '@mui/material';

import ContentPage from 'components/ui/content';
import { cardStyles } from 'components/ui/content/styles';
import CardRequest from 'components/ui/NewRequest/CardsRequest/CardRequest';
import SkeletonLoad from 'components/ui/NewRequest/CardsRequest/SkeletonLoad';

import { getNewRequestProductsUseCase } from 'useCases/newRequest/getNewRequestProductsUseCase';

import newRequestStore from 'stores/NewRequest/NewRequestStore';

import { containerProducts } from './NewRequestPage.styles';

export default function NewRequestPage(): JSX.Element {
  const { list } = useStore(newRequestStore);

  useEffect(() => {
    getNewRequestProductsUseCase.execute();
  }, []);

  return (
    <ContentPage title="Nova Solicitação">
      <Card sx={cardStyles}>
        <Typography fontSize="14px" mt={1} fontWeight="medium">
          Inicie sua solicitação, selecionando abaixo uma das soluções disponíveis:
        </Typography>
        <Box sx={containerProducts}>
          {list && list.length > 0 ? (
            list.filter(x => x.status).map(item => <CardRequest data={item} key={item.id} />)
          ) : (
            <>
              <SkeletonLoad />
              <SkeletonLoad />
              <SkeletonLoad />
            </>
          )}
        </Box>
      </Card>
    </ContentPage>
  );
}

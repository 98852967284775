import { RequestService } from 'services/RequestService/RequestService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';

import { getRequestManagementUseCase } from './getRequestManagementUseCase';

const execute = async (id: string, text: string) => {
  try {
    const { sector } = authStore.getState();
    await RequestService.changeMessage(id, {
      text,
      userSector: sector,
    });
    alertEvent({
      open: true,
      type: 'success',
      text: 'Sucesso ao enviar a mensagem.',
    });
  } catch (err) {
    alertEvent({
      open: true,
      type: 'error',
      text: 'Não foi possível enviar a mensagem no momento, tente mais tarde.',
    });
  } finally {
    getRequestManagementUseCase.execute();
  }
};

export const sendChangeMessageUseCase = { execute };

/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-params */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useStore } from 'effector-react';
import { columnsStyle, containerStyle, rowsStyle } from 'styles/global.styles';
import { theme } from 'styles/theme';

import { yupResolver } from '@hookform/resolvers/yup';
import { EditOutlined, LinkOutlined, MailOutlined, SettingsOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import SwitchToggle from 'components/form/switchToggle';
import ConfirmModal from 'components/ui/confirmModal/ConfirmModal';
import ContentPage from 'components/ui/content';
import DropDownConfig from 'components/ui/dropdownConfig/DropDownConfig';
import CustomPagination from 'components/ui/pagination/Pagination';
import SkeletonTableLoading from 'components/ui/skeletonTable/SkeletonTable';

import { ChangeStatusProductManagementUseCase } from 'useCases/productManagement/changeStatusProductUseCase';
import { createProductUseCase } from 'useCases/productManagement/createProductUseCase';
import { getAutomationProductManagementUseCase } from 'useCases/productManagement/getAutomationProductManagementUseCase';
import { getProductManagementEditUseCase } from 'useCases/productManagement/getProductManagementEditUseCase';
import { getProductManagementUseCase } from 'useCases/productManagement/getProductManagementUseCase';

import { authStore } from 'stores/AuthStore/AuthStore';
import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';
import productManagementStore from 'stores/ProductManagement/ProductManagementStore';

import { ProductConfigEnum } from 'domains/product';

import AutomationListDrawer from './Drawers/AutomationListDrawer';
import EmailAutomationDrawer from './Drawers/EmailAutomationDrawer';
import ProductManagementDrawer from './Drawers/ProductManagementDrawer';
import ProductManagementEditDrawer from './Drawers/ProductManagementEditDrawer';
import SpreadsheetAutomationDrawer from './Drawers/SpreadsheetAutomationDrawer';
import { ProductManagementValidators } from './ProductManagementValidators';

export default function ProductManagementPage(): JSX.Element {
  const {
    list,
    isLoading,
    isLoadingForm,
    pageNow,
    pageSize,
    isOpenModalSubmit,
    isOpenModalChangeStatusSubmit,
  } = useStore(productManagementStore);
  const [rowId, setRowId] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(ProductManagementValidators),
    mode: 'all',
  });
  const { permission } = useStore(authStore);

  const openForm = () => productManagementEvent({ isOpenForm: true });

  useEffect(() => {
    getProductManagementUseCase.execute();
  }, [pageNow, pageSize]);

  const handleChangeStatus = (id: string, status: boolean) => {
    ChangeStatusProductManagementUseCase.execute(id, !status);
  };

  return (
    <>
      <Box sx={{ ...containerStyle, flexDirection: 'column' }}>
        <ContentPage title="Gestão de Produtos">
          <Grid
            container
            alignItems="flex-end"
            alignContent="flex-end"
            justifyContent="flex-end"
            mt={5}
            mb={2}
            mr={1}
          >
            <Stack gap={2} flexDirection="row">
              <Box>
                <Button
                  variant="contained"
                  onClick={() => {
                    reset({});
                    openForm();
                  }}
                >
                  CRIAR PRODUTO
                </Button>
              </Box>
            </Stack>
          </Grid>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 0px 5px 0px' }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={columnsStyle} align="left">
                    Imagem
                  </TableCell>
                  <TableCell sx={columnsStyle} align="left">
                    Nome
                  </TableCell>
                  <TableCell sx={columnsStyle} align="left">
                    Descrição
                  </TableCell>
                  {/* <TableCell sx={columnsStyle} align="left" /> */}
                  <TableCell sx={columnsStyle} align="left">
                    Ações
                  </TableCell>
                  <TableCell sx={columnsStyle} align="left" />
                  <TableCell sx={columnsStyle} align="left" />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <Box sx={{ width: '100%' }}>
                    <SkeletonTableLoading />
                  </Box>
                ) : (
                  <>
                    {list?.data?.products?.length === 0 && (
                      <Box p={3} minWidth="300px">
                        <Typography variant="caption" textAlign="center">
                          Nenhum registro encontrado...
                        </Typography>
                      </Box>
                    )}
                    {list?.data?.products?.map(row => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" component="th" scope="row">
                          <Avatar src={row?.thumbnail || '/assets/empty.png'} alt={row.name} />
                        </TableCell>
                        <TableCell align="left" component="th" scope="row">
                          <Typography sx={rowsStyle}>{row?.name}</Typography>
                        </TableCell>
                        <TableCell align="left" component="th" scope="row">
                          <Typography sx={rowsStyle}>{row?.description}</Typography>
                        </TableCell>
                        <TableCell align="left" component="th" scope="row">
                          <IconButton
                            onClick={() => {
                              productManagementEvent({ isOpenFormEdit: true, rowId: row.id });
                              getProductManagementEditUseCase.execute(row.id);
                            }}
                          >
                            <EditOutlined
                              sx={{ color: theme.palette.primary.main, fontSize: '26px' }}
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell align="left" component="th" scope="row">
                          <DropDownConfig
                            options={[
                              {
                                id: ProductConfigEnum.email,
                                name: 'Criar automação via email',
                                icon: (
                                  <MailOutlined
                                    sx={{ color: theme.palette.primary.main, fontSize: '26px' }}
                                  />
                                ),
                                action: () => {
                                  setRowId(row.id);
                                  productManagementEvent({
                                    isOpenEmailAutomation: true,
                                  });
                                  getAutomationProductManagementUseCase.execute(row.id);
                                },
                                visible: permission === 0,
                              },
                              {
                                id: ProductConfigEnum.spreadsheet,
                                name: 'Criar automação via planilha',
                                icon: (
                                  <LinkOutlined
                                    sx={{ color: theme.palette.primary.dark, fontSize: '26px' }}
                                  />
                                ),
                                action: () => {
                                  setRowId(row.id);
                                  productManagementEvent({ isOpenSpreadsheetAutomation: true });
                                  getAutomationProductManagementUseCase.execute(row.id);
                                },
                                visible: permission === 0,
                              },
                              {
                                id: ProductConfigEnum.email,
                                name: 'Gerenciar automações',
                                icon: (
                                  <SettingsOutlined
                                    sx={{
                                      color: theme.palette.text.secondary,
                                      fontSize: '26px',
                                    }}
                                  />
                                ),
                                action: () => {
                                  setRowId(row.id);
                                  productManagementEvent({ isOpenAutomationListDrawer: true });
                                },
                                visible: permission === 0,
                              },
                            ]}
                          />
                        </TableCell>
                        <TableCell>
                          <SwitchToggle
                            value={row?.status}
                            onChange={() => {
                              setIsActive(row?.status);
                              setRowId(row?.id);
                              productManagementEvent({ isOpenModalChangeStatusSubmit: true });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            total={list?.data?.totalPages || 1}
            page={list?.data?.currentPage || 1}
            setPage={v => productManagementEvent({ pageNow: v })}
            isLoading={isLoading}
          />
        </ContentPage>
      </Box>

      <ProductManagementDrawer control={control} errors={errors} isValid={isValid} />
      <ProductManagementEditDrawer />
      <EmailAutomationDrawer rowId={rowId} />
      <SpreadsheetAutomationDrawer rowId={rowId} />
      <AutomationListDrawer rowId={rowId} />

      <ConfirmModal
        open={isOpenModalSubmit}
        setOpen={v => productManagementEvent({ isOpenModalSubmit: v })}
        text="Tem certeza que deseja criar um novo produto?"
        onConfirm={handleSubmit(createProductUseCase.execute)}
        isLoading={isLoadingForm}
        title="Atenção"
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />

      <ConfirmModal
        open={isOpenModalChangeStatusSubmit}
        setOpen={v => productManagementEvent({ isOpenModalChangeStatusSubmit: v })}
        text="Tem certeza que deseja seguir com a ativação/inativação do produto?"
        onConfirm={() => handleChangeStatus(rowId, isActive)}
        isLoading={isLoadingForm}
        title="Atenção"
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
    </>
  );
}

import { AuthService } from 'services/Auth/AuthService';

import { authStore } from 'stores/AuthStore/AuthStore';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';

const execute = async (searchSector: string, isManager = false) => {
  if (!searchSector) return;
  try {
    const { sector, role } = authStore.getState();
    const result = await AuthService.searchUserBySector({
      Search: searchSector,
      UserRole: role || null,
      UserSector: sector,
    });
    const response = { ...result, ...result.data };
    partnersManagementEvent({
      [isManager ? 'managerUserInfo' : 'coManagerUserInfo']: response,
    });
  } catch (err) {
    console.log(err);
  }
};

export const searchUserBySectorUseCase = { execute };

import { CompaignsService } from 'services/CompaignService/CompaignService';

import { Common } from 'utils';

import { compaignManagementEvent } from 'stores/CompaignManagement/CompaignManagementEvents';
import compaignManagementStore from 'stores/CompaignManagement/CompaignManagementStore';

const execute = async () => {
  try {
    compaignManagementEvent({ isLoading: true });
    const { pageNow, pageSize } = compaignManagementStore.getState();
    const result = await CompaignsService.getCompaigns(pageNow, pageSize);
    await Common.sleep(1000);
    compaignManagementEvent({ list: result });
  } catch (err) {
    // console.log(err)
  } finally {
    compaignManagementEvent({ isLoading: false });
  }
};

export const getCompaignManagementUseCase = { execute };

/* eslint-disable max-params */
import { useEffect, useState } from 'react';

import { addHours } from 'date-fns';
import dayjs from 'dayjs';
import { useStore } from 'effector-react';
import TooltipBootstrap from 'pages/MyRequestPage/TooltipBootstrap';
import {
  columnsStyle,
  containerStyle,
  rowsStyle,
  rowsStyleBold,
  rowsStyleBoldDarkBlue,
  rowsStyleSub,
  rowsStyleTag,
  rowsStyleTagMore,
  rowsStyleTextTag,
  rowsStyleTextTagMore,
  tooltipTxtStyle,
} from 'styles/global.styles';
import { theme } from 'styles/theme';

import { CommentOutlined, EditOutlined } from '@mui/icons-material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TextInput from 'components/form/textInput';
import ConfirmModal from 'components/ui/confirmModal/ConfirmModal';
import ContentPage from 'components/ui/content';
import FiltersRequest from 'components/ui/filtersRequest/FiltersRequest';
import RequestManagementDrawerDetail from 'components/ui/managementRequest/drawerDetail';
import DropDownRequest from 'components/ui/managementRequest/DropDownRequest/DropDownRequest';
import EditValidityModal from 'components/ui/managementRequest/Modals/EditValidityModal/EditValidityModal';
import CustomPagination from 'components/ui/pagination/Pagination';
import SkeletonTableLoading from 'components/ui/skeletonTable/SkeletonTable';
import { StatusCard } from 'components/ui/status';
import { StatusDiagnostic } from 'components/ui/statusDiagnostic';

import { changeStatusRequestUseCase } from 'useCases/requestManagement/changeStatusRequestUseCase';
import { getRequestManagementUseCase } from 'useCases/requestManagement/getRequestManagementUseCase';
import { sendChangeMessageUseCase } from 'useCases/requestManagement/sendChangeMessageUseCase';
import { editValidityModalRequestManagementUseCase } from 'useCases/requestManagementDetail/editValidityRequestManagementModalUseCase';
import { getRequestManagementDetailUseCase } from 'useCases/requestManagementDetail/getRequestManagementDetailUseCase';

import { Formatter } from 'utils';

import { requestManagementEvent } from 'stores/RequestManagement/RequestManagementEvents';
import { requestManagementDetailEvent } from 'stores/RequestManagementDetail/RequestManagementDetailEvents';
import { requestManagementDetailInitialState } from 'stores/RequestManagementDetail/RequestManagementDetailState';
import requestManagementDetailStore from 'stores/RequestManagementDetail/RequestManagementStore';

import { PartnerStatus } from 'domains/request';
import { StatusEnum, StatusStringEnum } from 'domains/status';

import requestManagementStore from '../../stores/RequestManagement/RequestManagementStore';

import './styles.css';

export default function RequestManagementPage(): JSX.Element {
  const {
    dataSource,
    pageSize,
    pageNow,
    filters,
    isLoading,
    isOpenEditValidityModal,
    isOpenAlertModal,
    isOpenCommentAlertModal,
    isAdmin,
  } = useStore(requestManagementStore);

  const setOpenModal = (val: boolean) => requestManagementEvent({ isOpenAlertModal: val });
  const setOpenCommentModal = (val: boolean) =>
    requestManagementEvent({ isOpenCommentAlertModal: val });

  useEffect(() => {
    requestManagementEvent({ filters: null, pageNow: 1 });
  }, []);

  useEffect(() => {
    requestManagementEvent({ pageNow: 1 });
  }, [filters]);

  useEffect(() => {
    getRequestManagementUseCase.execute();
  }, [pageNow, pageSize, filters]);

  function handleCloseModal() {
    requestManagementEvent({ isOpenEditValidityModal: false });
  }

  const [comment, setComment] = useState('');
  const [requestId, setRequestId] = useState('');
  const [statusRequest, setStatusRequest] = useState('');
  const [isJustMessage, setJustMessage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { dataDetail } = useStore(requestManagementDetailStore);

  const closeForm = () => {
    requestManagementDetailEvent(requestManagementDetailInitialState);
    setIsOpen(false);
  };
  const openForm = (id: string) => {
    getRequestManagementDetailUseCase.execute(id);
    setIsOpen(true);
  };

  const [dateValidity, setDateValidity] = useState({ id: '', startDate: '', endDate: '' });

  const handleEditValidity = (id: string, startDate: string, endDate: string) => {
    setDateValidity({ id, startDate, endDate });
  };

  const handleReprove = (actualStatus, newStatus, id, observation = '') => {
    if (observation && actualStatus === newStatus) setComment(observation);
    else setComment('');
    setStatusRequest(newStatus);
    setRequestId(id);
    setJustMessage(false);
    setOpenCommentModal(true);
  };

  const handleCommentIconClick = (status: string, rowComment = '', id = '') => {
    if (rowComment) setComment(rowComment);
    setStatusRequest(status);
    setRequestId(id);
    setJustMessage(true);
    setOpenCommentModal(true);
  };

  const getPartnerStatus = (status: string) => {
    if (status === PartnerStatus.newPartner) return 'Nova Parceria';
    return 'Parceria Existente';
  };

  const sendMessage = async () => {
    await sendChangeMessageUseCase.execute(requestId, comment);
    cleanCommentModalValues();
  };

  const cleanCommentModalValues = () => {
    setComment('');
    setStatusRequest('');
    setRequestId('');
    setJustMessage(false);
  };

  const actionWhenAccept = async () => {
    if (isJustMessage && comment) {
      await sendMessage();
      setComment('');
      cleanCommentModalValues();
      return;
    }
    await changeStatusRequestUseCase.execute({
      id: requestId,
      status:
        statusRequest === StatusStringEnum.Reproved ? StatusEnum.Reproved : StatusEnum.InAnalysis,
    });
    await sendMessage();
    cleanCommentModalValues();
  };

  const renderCommentAlertModal = () => {
    const isInAnalysis = statusRequest === StatusStringEnum.InAnalysis;
    const confirmTextAdmin = isInAnalysis ? 'CONFIRMAR' : 'SIM, RECUSAR';
    const confirmText = isAdmin ? confirmTextAdmin : 'OK';
    const declineText = isAdmin ? 'CANCELAR' : null;
    const title = isInAnalysis
      ? 'Comentários sobre a solicitação'
      : 'Deseja realmente recusar essa solicitação?';
    const disableInput = !isAdmin;
    const colorAgreeButton = isInAnalysis ? 'primary' : 'error';
    const colorDeclineButton = isInAnalysis ? 'primary' : null;
    const buttonDeclineVariant = isInAnalysis ? 'outlined' : null;

    return (
      <ConfirmModal
        id="commentAlertModal"
        open={isOpenCommentAlertModal}
        setOpen={v => {
          setOpenCommentModal(v);
        }}
        confirmText={confirmText}
        declineText={declineText}
        title={title}
        text=""
        customText={
          <TextInput
            maxLength={1024}
            value={comment}
            onChange={e => setComment(e?.target?.value)}
            multiline
            disabled={disableInput}
            height={190}
          />
        }
        onConfirm={() => {
          setOpenCommentModal(false);
          actionWhenAccept();
        }}
        onDecline={() => {
          setOpenCommentModal(false);
          cleanCommentModalValues();
        }}
        buttonAgreeColorType={colorAgreeButton}
        buttonDeclineColorType={colorDeclineButton}
        buttonDeclineVariant={buttonDeclineVariant}
      />
    );
  };

  const mustShowCommentIcon = (statusId: string) => {
    return [StatusStringEnum.InAnalysis, StatusStringEnum.Reproved].includes(statusId);
  };

  return (
    <Box sx={{ ...containerStyle, flexDirection: 'column' }}>
      <ContentPage title="Gestão de solicitações">
        <FiltersRequest />
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 0px 5px 0px' }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={columnsStyle} align="left">
                  Solicitação
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Solicitante
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Diagnóstico
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Equipamentos
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Laudos
                </TableCell>
                <TableCell sx={columnsStyle} align="center">
                  CRM
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Produtos
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Pacientes
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Status
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Ações
                </TableCell>
                <TableCell sx={columnsStyle} align="left" />
                <TableCell sx={columnsStyle} align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <SkeletonTableLoading />
              ) : (
                <>
                  {(!dataSource || dataSource?.data?.requests?.length === 0) && (
                    <Box p={3} minWidth="300px">
                      <Typography variant="caption" textAlign="center">
                        Nenhum registro encontrado...
                      </Typography>
                    </Box>
                  )}
                  {dataSource !== null &&
                    dataSource?.data?.requests?.map(row => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" sx={rowsStyle}>
                          <Typography width="90px" sx={rowsStyleBoldDarkBlue}>
                            {dayjs(
                              addHours(
                                new Date(row?.createdAt),
                                new Date().getTimezoneOffset() / 60,
                              ),
                            ).format('DD/MM/YYYY')}
                          </Typography>
                          <Typography sx={{ ...rowsStyleSub, width: '108px' }}>
                            {getPartnerStatus(row.partnerStatus)}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={rowsStyle}>
                          {row?.users?.map(obj => (
                            <Box>
                              <Typography sx={{ ...rowsStyleSub, minWidth: 130 }}>
                                {Formatter.parseName(obj.name)}
                              </Typography>
                              <Typography sx={rowsStyleSub}>Setor: {obj.sector}</Typography>
                            </Box>
                          ))}
                        </TableCell>
                        <TableCell align="left">
                          <Stack direction="column" gap={1}>
                            <Typography sx={{ ...rowsStyleBold, minWidth: 116 }}>
                              {row.product?.name}
                            </Typography>
                            <StatusDiagnostic statusCode={row.origin} />
                          </Stack>
                        </TableCell>
                        <TableCell align="center">
                          <Typography sx={rowsStyleBold}>
                            {Number(row?.equipmentsQty) ? Number(row?.equipmentsQty) : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography sx={rowsStyleBold}>
                            {Number(row?.reportsQty) ? Number(row?.reportsQty) : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {row?.professionals.length > 1 ? (
                            <Stack flexDirection="row" gap={1}>
                              {row?.professionals.slice(0, 1).map(item => (
                                <Box>
                                  <Typography sx={{ ...rowsStyle, minWidth: 110 }}>{`${item.ufcrm
                                    .split('_')[0]
                                    ?.replace('-', '')};`}</Typography>
                                </Box>
                              ))}
                              <Box>
                                <TooltipBootstrap
                                  title={row?.professionals.map(item => (
                                    <Box>
                                      <Typography sx={tooltipTxtStyle}>
                                        {`${item.ufcrm?.split('_')[0]?.replace('-', '')};`}
                                      </Typography>
                                    </Box>
                                  ))}
                                  placement="top"
                                >
                                  <Typography sx={rowsStyleBoldDarkBlue}>
                                    {`+${Math.max(row?.professionals?.length, 0) - 1}`}
                                  </Typography>
                                </TooltipBootstrap>
                              </Box>
                            </Stack>
                          ) : (
                            <Stack flexDirection="row" gap={1}>
                              {row?.professionals?.map(item => (
                                <Box>
                                  <Typography
                                    sx={{ ...rowsStyle, minWidth: 110 }}
                                  >{`${item.ufcrm?.replace('-', '')};`}</Typography>
                                </Box>
                              ))}
                            </Stack>
                          )}
                        </TableCell>
                        <TableCell align="left" sx={rowsStyle}>
                          {row.medicines.length > 1 ? (
                            <Stack flexDirection="row" gap={1} width="auto">
                              {row.medicines
                                .slice(0, 1)
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(item => (
                                  <Box sx={rowsStyleTag}>
                                    <Typography sx={rowsStyleTextTag} whiteSpace="nowrap">
                                      {item.name}
                                    </Typography>
                                  </Box>
                                ))}
                              <Box sx={rowsStyleTagMore}>
                                <TooltipBootstrap
                                  title={row.medicines
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map(item => (
                                      <Box>
                                        <Typography sx={tooltipTxtStyle}>{item.name}</Typography>
                                      </Box>
                                    ))}
                                  placement="top"
                                >
                                  <Typography sx={rowsStyleTextTagMore}>
                                    {`+${Math.max(row?.medicines?.length, 0) - 1}`}
                                  </Typography>
                                </TooltipBootstrap>
                              </Box>
                            </Stack>
                          ) : (
                            <Stack flexDirection="row" gap={1}>
                              {row.medicines
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(item => (
                                  <Box sx={rowsStyleTag}>
                                    <Typography sx={rowsStyleTextTag} whiteSpace="nowrap">
                                      {item.name}
                                    </Typography>
                                  </Box>
                                ))}
                            </Stack>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Typography sx={rowsStyleBold}>{row.patientsQty || '-'}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <StatusCard statusCode={row.statusId ?? 'none'} />
                        </TableCell>
                        <TableCell align="left">
                          {row.statusId !== StatusStringEnum.Deleted ? (
                            <DropDownRequest
                              row={row}
                              handleEditValidity={handleEditValidity}
                              handleReprove={handleReprove}
                            />
                          ) : (
                            <EditOutlined sx={{ color: '#cecece' }} />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {mustShowCommentIcon(row?.statusId) && (
                            <IconButton
                              color={
                                row?.statusId === StatusStringEnum.Reproved ? 'error' : 'warning'
                              }
                              onClick={() =>
                                handleCommentIconClick(
                                  row?.statusId,
                                  row?.observation || '',
                                  row?.id,
                                )
                              }
                            >
                              <CommentOutlined />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            onClick={() => openForm(row.id)}
                            disabled={row.statusId === StatusStringEnum.Deleted}
                            sx={{
                              color:
                                row.statusId === 'Deleted'
                                  ? '#cecece'
                                  : theme.palette.secondary.main,
                            }}
                          >
                            <VisibilityOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          total={dataSource?.data?.totalPages || 1}
          page={dataSource?.data?.currentPage || 1}
          setPage={v => requestManagementEvent({ pageNow: v })}
          isLoading={isLoading}
        />
        <EditValidityModal
          isOpenEditValidityModal={isOpenEditValidityModal}
          handleCloseModal={() => handleCloseModal()}
          submitUseCase={editValidityModalRequestManagementUseCase.execute}
          id={dateValidity.id}
          startDate={dateValidity.startDate}
          endDate=""
          title="Informe a data final de vigência dessa parceria"
          declineText="CANCELAR"
          confirmText="CONFIRMAR"
        />

        <RequestManagementDrawerDetail
          isOpen={isOpen}
          onClose={() => closeForm()}
          data={dataDetail}
        />
        {renderCommentAlertModal()}
        <ConfirmModal
          open={isOpenAlertModal}
          setOpen={v => {
            setOpenModal(v);
            window.location.replace('/app/new-request');
          }}
          confirmText="OK, ENTENDI"
          title="Registrado com sucesso!"
          text="Acompanhe o progresso no menu"
          customText={<b style={{ color: theme.palette.secondary.main }}>Minhas Solicitações</b>}
          onConfirm={() => {
            setOpenModal(false);
          }}
        />
      </ContentPage>
    </Box>
  );
}

import { SxProps } from '@mui/material';

export const txtColor: SxProps = {
  color: '#FFF',
  fontWeight: 'bold',
  fontSize: '12px',
};
export const boxTxt: SxProps = {
  display: 'flex',
  alignItems: 'center',
  pl: '2px',
};
export const newTxtColor: SxProps = {
  fontSize: '12px',
  py: 0.5,
  px: 1,
  color: 'green',
};
export const newBoxColor: SxProps = {
  border: '1px solid green',
  borderRadius: 2,
};
export const changedTxtColor: SxProps = {
  fontSize: '12px',
  py: 0.5,
  px: 1,
  color: '#005391',
};
export const changedBoxColor: SxProps = {
  border: '1px solid #005391',
  borderRadius: 2,
};

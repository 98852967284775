import { ExportService } from 'services/ExportService/ExportService';

const execute = async (id: string) => {
  try {
    const response = await ExportService.getDownloadLink(id);
    const link = document.createElement('a');
    link.href = response?.data || '';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    console.log(err);
  }
};

export const getExportDownloadLinkUseCase = { execute };

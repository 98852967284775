import { Box, Divider, Skeleton, Typography } from '@mui/material';

export default function SkeletonAutomationList(): JSX.Element {
  return (
    <div>
      <Typography fontSize="26px" fontWeight="bold">
        <Skeleton animation="wave" height="60px" />
      </Typography>
      <Typography fontSize="16px">
        <Skeleton animation="wave" sx={{ height: '30px' }} />
      </Typography>
      <div style={{ marginTop: 18 }} />
      <Typography fontSize="12px" fontWeight="bold" mt={4}>
        <Skeleton animation="wave" height="20px" />
      </Typography>
      <Divider />
      <Box mt={2} gap={2}>
        <Skeleton animation="wave" sx={{ px: 1, mt: 1, height: '80px' }} />
        <Divider />

        <Skeleton animation="wave" sx={{ px: 1, mt: 1, height: '80px' }} />
        <Divider />

        <Skeleton animation="wave" sx={{ px: 1, mt: 1, height: '80px' }} />
        <Divider />

        <Skeleton animation="wave" sx={{ px: 1, mt: 1, height: '80px' }} />
        <Divider />

        <Skeleton animation="wave" sx={{ px: 1, mt: 1, height: '80px' }} />
        <Divider />
      </Box>
    </div>
  );
}

/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-params */
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useStore } from 'effector-react';
import { columnsStyle, containerStyle, rowsStyle } from 'styles/global.styles';

import { yupResolver } from '@hookform/resolvers/yup';
import { AddOutlined, ArrowForwardOutlined } from '@mui/icons-material';
import { Box, Button, Drawer, Grid, Stack, Typography, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import SwitchToggle from 'components/form/switchToggle';
import TextInput from 'components/form/textInput';
import ConfirmModal from 'components/ui/confirmModal/ConfirmModal';
import ContentPage from 'components/ui/content';
import CustomPagination from 'components/ui/pagination/Pagination';
import SkeletonTableLoading from 'components/ui/skeletonTable/SkeletonTable';

import { changeStatusMedicineUseCase } from 'useCases/medicineManagement/changeStatusMedicineUseCase';
import { createMedicineUseCase } from 'useCases/medicineManagement/createMedicineUseCase';
import { getMedicineManagementUseCase } from 'useCases/medicineManagement/getMedicineManagementUseCase';

import { medicineManagementEvent } from 'stores/MedicineManagement/MedicineManagementEvents';
import medicineManagementStore from 'stores/MedicineManagement/MedicineManagementStore';

import { medicineManagementValidators } from './MedicineManagementValidators';

export default function MedicineManagementPage(): JSX.Element {
  const theme = useTheme();
  const {
    isLoading,
    list,
    isOpenForm,
    isLoadingForm,
    pageNow,
    pageSize,
    isOpenModalStatus,
    isLoadingModalStatus,
    isOpenModalSubmit,
  } = useStore(medicineManagementStore);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(medicineManagementValidators),
    mode: 'all',
  });

  const closeForm = () => medicineManagementEvent({ isOpenForm: false });
  const openForm = () => medicineManagementEvent({ isOpenForm: true });

  useEffect(() => {
    getMedicineManagementUseCase.execute();
  }, [pageNow, pageSize]);

  return (
    <>
      <Box sx={{ ...containerStyle, flexDirection: 'column' }}>
        <ContentPage title="Gestão de Medicamentos">
          <Grid container justifyContent="flex-end" mt={5} mb={2} mr={1}>
            <Stack gap={2} flexDirection="row">
              <Box>
                <Button
                  variant="contained"
                  startIcon={<AddOutlined color="inherit" />}
                  onClick={() => {
                    reset({});
                    openForm();
                  }}
                >
                  CRIAR MEDICAMENTO
                </Button>
              </Box>
            </Stack>
          </Grid>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 0px 5px 0px' }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={columnsStyle} align="left">
                    Medicamento
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <SkeletonTableLoading />
                ) : (
                  <>
                    {list?.data?.medicines?.length === 0 && (
                      <Box p={3} minWidth="300px">
                        <Typography variant="caption" textAlign="center">
                          Nenhum registro encontrado...
                        </Typography>
                      </Box>
                    )}
                    {list?.data?.medicines?.map(row => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" component="th" scope="row">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                              [theme.breakpoints.down('sm')]: {
                                paddingRight: 0,
                                maxWidth: 280,
                              },
                              [theme.breakpoints.up('md')]: {
                                paddingRight: 20,
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                ...rowsStyle,
                              }}
                            >
                              {row?.name}
                            </Typography>
                            <SwitchToggle
                              value={row.status}
                              onChange={() =>
                                medicineManagementEvent({
                                  isOpenModalStatus: true,
                                  changeStatus: {
                                    id: row.id,
                                    isActive: !row.status,
                                  },
                                })
                              }
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            total={list?.data?.totalPages || 1}
            page={list?.data?.currentPage || 1}
            setPage={v => medicineManagementEvent({ pageNow: v })}
            isLoading={isLoading}
          />
        </ContentPage>
      </Box>

      <Drawer anchor="right" open={isOpenForm} onClose={closeForm}>
        <Box
          sx={{
            [theme.breakpoints.between('sm', 'xs')]: {
              width: 480,
              maxWidth: 480,
            },
            [theme.breakpoints.up('md')]: {
              width: 580,
              maxWidth: 580,
            },
            minHeight: '100%',
            borderRadius: '10px 0 0 10px !important',
            backgroundColor: '#FFF',
          }}
        >
          <Stack
            px={4}
            py={4}
            direction="column"
            justifyContent="space-between"
            flex={1}
            sx={{
              height: '100%',
            }}
          >
            <div>
              <Typography fontSize="26px" fontWeight="bold">
                Criar medicamento
              </Typography>
              <Box mt={8}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <TextInput
                        label="Insira o nome do medicamento"
                        name={name}
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                      />
                    );
                  }}
                />
              </Box>
            </div>

            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ width: 150 }}
                disabled={!isValid}
                onClick={() => medicineManagementEvent({ isOpenModalSubmit: true })}
                endIcon={<ArrowForwardOutlined color="inherit" />}
              >
                CRIAR
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Drawer>

      <ConfirmModal
        open={isOpenModalStatus}
        setOpen={v => medicineManagementEvent({ isOpenModalStatus: v })}
        text="Tem certeza que deseja realizar essa ação ?"
        onConfirm={() => changeStatusMedicineUseCase.execute()}
        onDecline={() => getMedicineManagementUseCase.execute()}
        isLoading={isLoadingModalStatus}
        title="Atenção"
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />

      <ConfirmModal
        open={isOpenModalSubmit}
        setOpen={v => medicineManagementEvent({ isOpenModalSubmit: v })}
        text="Tem certeza que deseja criar um novo medicamento ?"
        onConfirm={handleSubmit(createMedicineUseCase.execute)}
        isLoading={isLoadingForm}
        title="Atenção"
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
    </>
  );
}

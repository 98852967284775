import api from 'clients/api/Api';

import {
  IGetExport,
  IExportDownloadLinkResponse,
  IExportsManagementResponse,
} from 'domains/exportManagement/Exports.domain';

const getAll = async (params: IGetExport) => {
  const { data } = await api.get<IExportsManagementResponse>('cardio/ExportControl', {
    params,
  });
  return data;
};

const getDownloadLink = async (id: string) => {
  const { data } = await api.get<IExportDownloadLinkResponse>(`cardio/ExportControl/${id}`);
  return data;
};

export const ExportService = { getAll, getDownloadLink };

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import { CloseOutlined } from '@mui/icons-material';
import * as MUI from '@mui/material';

import {
  closeButtonStyles,
  containerStyles,
  contentStyles,
  modalTextStyles,
  modalTitleStyles,
} from './PendingEquipmentsModal.styles';

interface IPendingEquipmentsModal {
  open: boolean;
  isLoading?: boolean;
  setOpen: (v: boolean) => void;
  onConfirm: () => void;
  onDecline?: () => void;
  title?: string;
  text: string;
  cancelText: string;
  confirmText: string;
  declineText?: string;
  customText?: JSX.Element;
  id?: string;
  buttonDeclineColorType?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  buttonAgreeColorType?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  buttonDeclineVariant?: 'contained' | 'outlined';
  buttonAgreeVariant?: 'contained' | 'outlined';
}

function PendingEquipmentsModal(props: IPendingEquipmentsModal): JSX.Element {
  return (
    <MUI.Modal
      open={props.open}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id={props?.id || ''}
    >
      <MUI.Box sx={containerStyles}>
        <MUI.Box sx={contentStyles}>
          <MUI.IconButton sx={closeButtonStyles} onClick={() => props.setOpen(false)}>
            <CloseOutlined />
          </MUI.IconButton>
          <MUI.Stack direction="column">
            {!!props?.title && (
              <MUI.Typography id="modal-modal-title" sx={modalTitleStyles}>
                {props.title}
              </MUI.Typography>
            )}
            <MUI.Typography id="modal-modal-description" sx={modalTextStyles}>
              {props.text} {!!props?.customText && props.customText}
            </MUI.Typography>
            <MUI.Stack direction="row" gap={4} justifyContent="center" marginTop="30px">
              <MUI.Button
                variant={props?.buttonDeclineVariant || 'contained'}
                color={props?.buttonDeclineColorType || 'inherit'}
                sx={{ minWidth: 100, mt: 4 }}
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                {props?.cancelText}
              </MUI.Button>
              {!!props?.declineText && (
                <MUI.Button
                  variant={props?.buttonDeclineVariant || 'contained'}
                  color={props?.buttonDeclineColorType || 'secondary'}
                  sx={{ minWidth: 100, mt: 4 }}
                  onClick={() => {
                    if (props?.onDecline) {
                      props.onDecline();
                    }
                  }}
                >
                  {props?.declineText}
                </MUI.Button>
              )}
              <MUI.Button
                variant={props?.buttonAgreeVariant || 'contained'}
                color={props?.buttonAgreeColorType || 'primary'}
                sx={{ minWidth: 100, mt: 4 }}
                disabled={props.isLoading}
                onClick={props.onConfirm}
              >
                {props?.isLoading ? (
                  <MUI.CircularProgress color="inherit" size="25px" />
                ) : (
                  props.confirmText
                )}
              </MUI.Button>
            </MUI.Stack>
          </MUI.Stack>
        </MUI.Box>
      </MUI.Box>
    </MUI.Modal>
  );
}

export default PendingEquipmentsModal;

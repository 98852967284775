import { RequestService } from 'services/RequestService/RequestService';

import { Common } from 'utils';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

type IParams = {
  quantity: string;
  reset: () => void;
};

const execute = async (params: IParams) => {
  try {
    partnersManagementEvent({ isLoadingReportQty: true });
    const { reportQtyRequestId } = partnersManagementStore.getState();
    await RequestService.syncMicromedData(reportQtyRequestId, {
      reportsBalance: Number(params?.quantity) || null,
    });
    await Common.sleep(1000);
    alertEvent({
      open: true,
      text: 'Quantidade de laudos atualizada com sucesso.',
      type: 'success',
    });
  } catch (err) {
    alertEvent({ open: true, text: 'Ocorreu um erro, tente novamente mais tarde.', type: 'error' });
  } finally {
    partnersManagementEvent({
      isLoadingReportQty: false,
      isOpenDrawerDetails: true,
      isOpenDrawerPartnerReportQty: false,
      isOpenDrawerPartnerReportQtyModal: false,
    });
    params?.reset();
  }
};

export const changeReportQtyUseCase = { execute };

import { createStore } from 'effector';

import { exportsManagementEvent } from './ExportsManagementEvents';
import { ExportsManagementState, exportsManagementInitialState } from './ExportsManagementState';

const exportsManagementStore = createStore<ExportsManagementState>(
  exportsManagementInitialState,
).on(exportsManagementEvent, (state, payload) => ({
  ...state,
  ...payload,
}));

export default exportsManagementStore;

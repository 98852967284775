import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#3B98E5',
    },
    secondary: {
      main: '#005391',
    },
    error: {
      main: '#CA3C3C',
    },
    success: {
      main: '#0A9865',
    },
    warning: {
      main: '#E67A00',
    },
    info: {
      main: '#17305A',
    },
    background: {
      default: '#FAFAFA',
      paper: '#F8F9FA',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontSize: '9.6rem',
      fontWeight: 400,
    },
    h2: {
      fontSize: '6rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '4.8rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '3.4rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '2.4rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '2rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1.4rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '1.8rem',
      fontWeight: 700,
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          padding: 0,
          margin: 0,
          boxSizing: 'border-box',
        },
      },
    },
  },
});

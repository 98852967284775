/* eslint-disable max-params */
import { useEffect } from 'react';

import { addHours } from 'date-fns';
import dayjs from 'dayjs';
import { useStore } from 'effector-react';
import { columnsStyle, containerStyle, rowsStyle, txt14ItalicNormal } from 'styles/global.styles';

import { FileDownloadOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ContentPage from 'components/ui/content';
import CustomPagination from 'components/ui/pagination/Pagination';
import SkeletonTableLoading from 'components/ui/skeletonTable/SkeletonTable';

import { getExportDownloadLinkUseCase } from 'useCases/exportManagement/getExportDownloadLinkUseCase';
import { getExportManagementUseCase } from 'useCases/exportManagement/getExportManagementUseCase';

import { exportsManagementEvent } from 'stores/ExportsManagement/ExportsManagementEvents';

import { IExportTypesEnum } from 'domains/exportManagement/Exports.domain';

import exportManagementStore from '../../stores/ExportsManagement/ExportsManagementStore';
import './styles.css';

export default function ExportManagementPage(): JSX.Element {
  const { dataSource, pageSize, pageNow, filters, isLoading } = useStore(exportManagementStore);

  useEffect(() => {
    exportsManagementEvent({ filters: null, pageNow: 1 });
  }, []);

  useEffect(() => {
    exportsManagementEvent({ pageNow: 1 });
  }, [filters]);

  useEffect(() => {
    getExportManagementUseCase.execute();
  }, [pageNow, pageSize, filters]);

  const getExportDescription = (type: IExportTypesEnum) => {
    switch (type) {
      case 'Requests':
        return 'Solicitações';

      case 'Partners':
        return 'Parcerias';
      default:
        return 'Movimentações de Parcerias';
    }
  };

  return (
    <Box sx={{ ...containerStyle, flexDirection: 'column' }}>
      <ContentPage title="Extrações">
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 0px 5px 0px', marginTop: '100px' }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={columnsStyle} align="left">
                  Nome
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Categoria
                </TableCell>
                <TableCell sx={columnsStyle} align="left">
                  Data da Extração
                </TableCell>
                <TableCell sx={columnsStyle} align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <SkeletonTableLoading />
              ) : (
                <>
                  {(!dataSource || dataSource?.data?.items?.length === 0) && (
                    <Box p={3} minWidth="300px">
                      <Typography variant="caption" textAlign="center">
                        Nenhum registro encontrado...
                      </Typography>
                    </Box>
                  )}
                  {dataSource !== null &&
                    dataSource?.data?.items?.map(row => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">
                          <Typography sx={rowsStyle}> {row?.filePath?.split('/')[1]} </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography sx={txt14ItalicNormal}>
                            {getExportDescription(row?.exportType)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={rowsStyle}>
                          <Typography width="90px" sx={rowsStyle}>
                            {dayjs(
                              addHours(
                                new Date(row?.createdAt),
                                new Date().getTimezoneOffset() / 60,
                              ),
                            ).format('DD/MM/YYYY')}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            title="Baixar arquivo"
                            onClick={() => getExportDownloadLinkUseCase.execute(row?.id)}
                          >
                            <FileDownloadOutlined sx={{ color: '#3B98E5' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          total={dataSource?.data?.totalPages || 1}
          page={dataSource?.data?.currentPage || 1}
          setPage={v => exportsManagementEvent({ pageNow: v })}
          isLoading={isLoading}
        />
      </ContentPage>
    </Box>
  );
}

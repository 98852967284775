import { Box, Grid, Skeleton, Typography } from '@mui/material';

import { cardContainer, cardImg, cardLink, cardTitle, cardTxt } from './CardRequest.styles';

export default function SkeletonLoad(): JSX.Element {
  return (
    <Box sx={cardContainer} height={350}>
      <Grid container gap={2}>
        <Grid item>
          <Box sx={cardImg}>
            <Skeleton variant="rectangular" width={90} height={90} />
          </Box>
        </Grid>
        <Grid item>
          <Box sx={cardTitle}>
            <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} width={100} />
          </Box>
        </Grid>
      </Grid>
      <Box sx={cardTxt}>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />{' '}
      </Box>
      <Box sx={cardLink} mt={1}>
        <Typography fontSize={16} fontWeight="700" style={{ color: '#3B98E5', cursor: 'pointer' }}>
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} width={80} />
        </Typography>
      </Box>
    </Box>
  );
}

import { getNotificationsUseCase } from 'useCases/notifications/getNotificationsUseCase';

import { SolicitationService } from 'services/SolicitationService/SolicitationService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

interface IParams {
  request: any;
  selectedEquipments: string[];
}

const execute = async (params: IParams) => {
  try {
    partnersManagementEvent({ isLoadingRelocate: true });
    const { sector } = authStore.getState();
    const { selectedRow } = partnersManagementStore.getState();
    await SolicitationService.create({
      userSector: sector,
      equipmentIds: params.selectedEquipments,
      currentUserSector: null,
      newUserSector: sector,
      requestId: params.request.id,
      partnerCode: selectedRow?.partner?.cnpj_cpf || null,
      partnerName: selectedRow?.partner?.name || null,
      type: 'EquipmentReturn',
    });
    alertEvent({
      open: true,
      text: 'Solicitação de remanejamento para matriz criada com sucesso.',
      type: 'success',
    });
    getNotificationsUseCase.execute();
  } catch (err) {
    alertEvent({
      open: true,
      text: 'Ocorreu um erro ao remanejar equipamento, tente novamente mais tarde.',
      type: 'error',
    });
  } finally {
    partnersManagementEvent({
      isLoadingRelocate: false,
      isOpenDrawerRelocateEquipment: false,
      isOpenDrawerDetails: true,
    });
  }
};

export const relocationMatrixUseCase = { execute };

import { createStore } from 'effector';

import { medicineManagementEvent } from './MedicineManagementEvents';
import { MedicineManagementState, medicineManagementInitialState } from './MedicineManagementState';

const medicineManagementStore = createStore<MedicineManagementState>(
  medicineManagementInitialState,
).on(medicineManagementEvent, (state, payload) => ({
  ...state,
  ...payload,
}));

export default medicineManagementStore;

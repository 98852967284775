export interface IAuthResponse {
  data: {
    accessToken: string;
    expiresIn: number;
  };
  hasError: false;
  error: null;
}

export interface IUserInfoResponse {
  data: IUser;
  hasError: false;
  error: null;
}

export interface IUser {
  name: string;
  email: string;
  username: string;
  sector: string;
  groups: string[];
}

export enum IAtuhEnum {
  ADMIN = 'ADMIN',
  ADMIN_CODE = '03600360',
}

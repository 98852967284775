import { createStore } from 'effector';

import { requestManagementEvent } from './RequestManagementEvents';
import { RequestManagementState, requestManagementInitialState } from './RequestManagementState';

const requestManagementStore = createStore<RequestManagementState>(
  requestManagementInitialState,
).on(requestManagementEvent, (state, payload) => ({
  ...state,
  ...payload,
}));

export default requestManagementStore;

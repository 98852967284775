import {
  IExportsManagementResponse,
  IExportTypesEnum,
} from 'domains/exportManagement/Exports.domain';

export interface ExportsManagementState {
  isLoading: boolean;
  isLoadingExport: boolean;
  dataSource: IExportsManagementResponse | null;
  pageSize: number;
  pageNow: number;
  filters: {
    exportType: IExportTypesEnum | null;
  };
}

export const exportsManagementInitialState: ExportsManagementState = {
  isLoading: false,
  isLoadingExport: false,
  dataSource: null,
  pageSize: 10,
  pageNow: 1,
  filters: {
    exportType: null,
  },
};

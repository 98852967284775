import dayjs from 'dayjs';
import { txt16normal } from 'pages/PartnersManagementPage/PartnersManagementPage.styles';
import { txt14ItalicNormal, txt16Bold } from 'styles/global.styles';
import { theme } from 'styles/theme';

import { DeleteOutline, LinkOutlined, MailOutline } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';

import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';

import { IParamsGetAutomationList } from 'domains/product';

function AutomationList(props: IParamsGetAutomationList) {
  if (props?.automation.type === 'Email')
    return (
      <Box>
        <Box>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Stack flexDirection="row" alignItems="center">
              <MailOutline sx={{ width: 25, height: 25, color: theme.palette.primary.main }} />
              <Box p={2}>
                <Typography sx={txt16Bold}>Automação via e-mail</Typography>
                <Typography sx={txt16normal}>{props?.automation?.email}</Typography>
                <Typography sx={txt14ItalicNormal}>
                  Configurada {dayjs(props?.automation?.createdAt).format('DD/MM/YYYY')}
                </Typography>
              </Box>
            </Stack>
            <Button
              onClick={() => {
                props?.setStateAutomationIds(props?.automation?.id, props?.productId);
                productManagementEvent({ isOpenModalDeleteAutomationSubmit: true });
              }}
            >
              <DeleteOutline sx={{ width: 25, height: 25, color: '#e7002a' }} />
            </Button>
          </Stack>
          <Divider />
        </Box>
      </Box>
    );
  if (props?.automation?.type === 'Sheet')
    return (
      <Box>
        <Box>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Stack flexDirection="row" alignItems="center">
              <LinkOutlined sx={{ width: 25, height: 25, color: theme.palette.primary.dark }} />
              <Box p={2}>
                <Typography sx={txt16Bold}>Automação via planilha</Typography>
                <Typography sx={txt16normal}>{props?.automation?.sheetPath}</Typography>
                <Typography sx={txt14ItalicNormal}>
                  Configurada {dayjs(props?.automation?.createdAt).format('DD/MM/YYYY')}
                </Typography>
              </Box>
            </Stack>
            <Button
              onClick={() => {
                props?.setStateAutomationIds(props?.automation?.id, props?.productId);
                productManagementEvent({ isOpenModalDeleteAutomationSubmit: true });
              }}
            >
              <DeleteOutline sx={{ width: 25, height: 25, color: '#e7002a' }} />
            </Button>
          </Stack>
          <Divider />
        </Box>
      </Box>
    );
  return null;
}

export default AutomationList;

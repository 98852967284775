import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';

import { formDynamicFormEvent } from 'stores/DynamicForm/FormDynamicFormEvents';
import { requestDynamicFormEvent } from 'stores/RequestDynamicForm/RequestDynamicFormEvents';
import { requestDynamicFormInitialState } from 'stores/RequestDynamicForm/RequestDynamicFormState';

import { IProduct } from 'domains/product';

import { cardContainer, cardImg, cardLink, cardTitle, cardTxt } from './CardRequest.styles';

export interface PropsData {
  data: IProduct; // Needs work in ts
}

export default function CardRequest({ data }: PropsData): JSX.Element {
  const [imgError, setImgError] = useState(false);
  const [imgLoading, setImgLoading] = useState(true);

  const navigation = useHistory();
  return (
    <Box sx={cardContainer}>
      <Grid container gap={2}>
        <Grid item xs={5} sm={6} md={4}>
          <Box sx={cardImg}>
            <img
              src={
                imgError || imgLoading ? '/assets/empty.png' : data.thumbnail || '/assets/empty.png'
              }
              alt={data.thumbnail}
              width={90}
              onLoad={() => setImgLoading(false)}
              onError={() => setImgError(true)}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={cardTitle}>{data?.name}</Box>
        </Grid>
      </Grid>
      <Box sx={cardTxt}>{data?.description || ''}</Box>
      <Box sx={cardLink} mt={1}>
        <Typography
          fontSize={16}
          fontWeight="700"
          style={{ color: '#3B98E5', cursor: 'pointer' }}
          onClick={() => {
            requestDynamicFormEvent(requestDynamicFormInitialState);
            formDynamicFormEvent({ cnpjInfo: null });
            navigation.push(`/app/form/${data.id}`);
          }}
        >
          INICIAR SOLICITAÇÃO
        </Typography>
      </Box>
    </Box>
  );
}

import { createStore } from 'effector';

import { compaignManagementEvent } from './CompaignManagementEvents';
import { CompaignManagementState, compaignManagementInitialState } from './CompaignManagementState';

const compaignManagementStore = createStore<CompaignManagementState>(
  compaignManagementInitialState,
).on(compaignManagementEvent, (state, payload) => ({
  ...state,
  ...payload,
}));

export default compaignManagementStore;

import { useStore } from 'effector-react';
import { theme } from 'styles/theme';

import { MenuOutlined, NotificationsOutlined } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Grid,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { Common } from 'utils';

import { authStore } from 'stores/AuthStore/AuthStore';
import { notificationEvent } from 'stores/Notifications/NotificationsEvents';
import notificationStore from 'stores/Notifications/NotificationsStore';

const NAV_WIDTH = 258;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;
const StyledRoot = styled(AppBar)(() => ({
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(() => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function Header({ onOpenNav }) {
  const { name, role } = useStore(authStore);
  const { pendingNotifications } = useStore(notificationStore);

  const badgeValue = pendingNotifications;

  return (
    <StyledRoot sx={{ display: { lg: 'none' } }}>
      <StyledToolbar>
        <Box sx={{ position: 'absolute', right: 60 }}>
          <Grid container>
            <Grid item width={50}>
              <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>
                <Typography fontSize={20} fontWeight="bold">
                  {Common.convertNameToInitials(name)}
                </Typography>
              </Avatar>
            </Grid>
            <Grid item minWidth={100}>
              <Typography fontSize={16} sx={{ color: '#fff' }}>
                {name}
              </Typography>
              <Typography fontSize={12} fontWeight="bold" color="#fff">
                {role}
              </Typography>
            </Grid>
            <Grid item width={50} flex={1} display="flex">
              <Box display="flex" alignItems="center" flex={1} ml={2}>
                <Stack direction="row" alignItems="center">
                  <IconButton color="inherit" onClick={() => notificationEvent({ open: true })}>
                    <Badge color="error" badgeContent={badgeValue}>
                      <NotificationsOutlined />
                    </Badge>
                  </IconButton>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <IconButton
          onClick={onOpenNav}
          color="inherit"
          sx={{
            mr: 1,
            display: { lg: 'none' },
          }}
        >
          <MenuOutlined />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
      </StyledToolbar>
    </StyledRoot>
  );
}

import { RequestService } from 'services/RequestService/RequestService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';

import { getRequestManagementUseCase } from './getRequestManagementUseCase';

const execute = async (id: string) => {
  try {
    const { sector } = authStore.getState();
    await RequestService.deleteRequest({ id, userSector: sector });
    alertEvent({
      open: true,
      type: 'success',
      text: 'Sucesso ao excluir solicitação.',
    });
  } catch (err) {
    alertEvent({
      open: true,
      type: 'error',
      text: 'Não foi possível excluir a solicitação no momento, tente mais tarde.',
    });
  } finally {
    getRequestManagementUseCase.execute();
  }
};

export const deleteRequestUseCase = { execute };

import { RequestService } from 'services/RequestService/RequestService';

import { Common } from 'utils';

import { authStore } from 'stores/AuthStore/AuthStore';
import { requestManagementEvent } from 'stores/RequestManagement/RequestManagementEvents';
import requestManagementStore from 'stores/RequestManagement/RequestManagementStore';

import { IAtuhEnum } from 'domains/auth/AuthDomain';

const execute = async () => {
  try {
    requestManagementEvent({ isLoading: true });
    const { sector, isAdmin } = authStore.getState();
    const { pageSize, pageNow, filters } = requestManagementStore.getState();
    let obj = {
      Page: pageNow,
      Size: pageSize,
      UserSector: isAdmin ? IAtuhEnum.ADMIN_CODE : sector,
    };
    if (filters) {
      obj = { ...obj, ...filters };
    }
    const result = await RequestService.getRequestManagement(obj);
    await Common.sleep(1000);
    requestManagementEvent({ dataSource: result, isAdmin });
  } catch (err) {
    console.log(err);
  } finally {
    requestManagementEvent({ isLoading: false });
  }
};

export const getRequestManagementUseCase = { execute };

import { addHours, format } from 'date-fns';
import { useStore } from 'effector-react';

import { CompareArrowsOutlined } from '@mui/icons-material';
import { Box, Button, Card, Stack, Typography } from '@mui/material';

import { Formatter } from 'utils';

import { authStore } from 'stores/AuthStore/AuthStore';
import { notificationEvent } from 'stores/Notifications/NotificationsEvents';
import notificationStore from 'stores/Notifications/NotificationsStore';

import { StatusStringEnum } from 'domains/status';

import CustomPagination from '../pagination/Pagination';
import { cardIconAvatarStyles } from './DrawerNotifications.styles';

type IProps = {
  setSelectedId: (value: string) => void;
};

function ListPartnerTransfer({ setSelectedId }: IProps): JSX.Element {
  const { managerTransfer, pageTabManagerTransfer } = useStore(notificationStore);
  const { permission, sector } = useStore(authStore);
  const timezone = new Date().getTimezoneOffset() / 60;

  return (
    <>
      {managerTransfer?.data?.solicitations?.length === 0 && (
        <Card sx={{ width: '100%', padding: 4, height: 90, mt: 4 }}>
          <Typography style={{ fontSize: 16 }} textAlign="center">
            Nenhuma solicitação de transferência de parceria encontrada...
          </Typography>
        </Card>
      )}
      {managerTransfer?.data?.solicitations?.map(obj => {
        const isShowActions = permission === 0 || obj.toUser.sector === sector;
        return (
          <Card key={obj.id} sx={{ width: '100%', p: 2, my: 1 }}>
            <Stack direction="row" gap={2} alignItems="center">
              <div style={{ width: 50 }}>
                <Box sx={cardIconAvatarStyles}>
                  <CompareArrowsOutlined sx={{ color: '#FFF' }} />
                </Box>
              </div>
              <Typography fontSize={14}>
                <b>{obj?.createdBy?.name || '-'}</b> está requisitando a transferência de parceria
                para <b style={{ color: '#005391' }}>{obj.toUser.name}</b>
              </Typography>
            </Stack>
            <Box mt={2}>
              <Typography fontSize={14}>
                <b>Parceiro</b> {obj.partnerName} - {obj.partnerCode}
              </Typography>
              <Typography fontSize={14}>
                <b>Atual gestão:</b>{' '}
                {`${obj.fromUser.sector} - ${Formatter.parseName(obj.fromUser.name)}`}
              </Typography>
              <Typography fontSize={14}>
                <b>Nova gestão:</b>{' '}
                {`${obj.toUser.sector} - ${Formatter.parseName(obj.toUser.name)}`}
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography fontSize={14}>
                Ao aceitar, você poderá acompanhar e editar os detalhes desta parceria no menu{' '}
                <b style={{ color: '#005391' }}>Gestão de parcerias.</b>
              </Typography>
            </Box>
            {obj?.status !== StatusStringEnum.InAnalysis ? (
              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
                mt={2}
              >
                <Typography style={{ fontSize: 14 }}>
                  {format(addHours(new Date(obj.createdAt), -timezone), 'dd/MM/yy HH:mm')}
                </Typography>
                <Typography
                  style={{ fontSize: 16 }}
                  color={obj.status === 'Approved' ? 'green' : 'error'}
                  fontWeight="bold"
                >
                  {obj.status === 'Approved' ? 'Aprovado' : 'Recusado'}
                </Typography>
              </Stack>
            ) : (
              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
                mt={2}
              >
                <Typography style={{ fontSize: 14 }}>
                  {format(addHours(new Date(obj.createdAt), -timezone), 'dd/MM/yy HH:mm')}
                </Typography>
                {isShowActions ? (
                  <Stack direction="row" gap={2}>
                    <Button
                      color="error"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setSelectedId(obj.id);
                        notificationEvent({ openModalReprove: true });
                      }}
                    >
                      RECUSAR
                    </Button>
                    <Button
                      color="success"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setSelectedId(obj.id);
                        notificationEvent({ openModalApprove: true });
                      }}
                    >
                      ACEITAR
                    </Button>
                  </Stack>
                ) : (
                  <Typography style={{ fontSize: 16 }} color="warning" fontWeight="bold">
                    Pendente
                  </Typography>
                )}
              </Stack>
            )}
          </Card>
        );
      })}

      {!!managerTransfer?.data?.totalPages && (
        <CustomPagination
          total={managerTransfer?.data?.totalPages}
          page={pageTabManagerTransfer}
          setPage={v => notificationEvent({ pageTabManagerTransfer: v })}
          position="center"
        />
      )}
    </>
  );
}

export default ListPartnerTransfer;

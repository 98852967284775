import { RequestService } from 'services/RequestService/RequestService';

import { authStore } from 'stores/AuthStore/AuthStore';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { IAtuhEnum } from 'domains/auth/AuthDomain';

const execute = async () => {
  try {
    partnersManagementEvent({ isLoading: true });
    const { sector, isAdmin } = authStore.getState();
    const { pageSize, pageNow, filters } = partnersManagementStore.getState();
    let params = {
      Page: pageNow,
      Size: pageSize,
      UserSector: isAdmin ? IAtuhEnum.ADMIN_CODE : sector,
    };
    if (filters) {
      params = { ...params, ...filters };
    }
    const result = await RequestService.getPartners(params);
    partnersManagementEvent({ dataSource: result });
  } catch (err) {
    // console.log(err)
  } finally {
    partnersManagementEvent({ isLoading: false });
  }
};

export const getPartnersManagementUseCase = { execute };

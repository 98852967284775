import { ProductsService } from 'services/ProductService/ProductService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';

const execute = async (productId: string) => {
  try {
    productManagementEvent({ isLoading: true });

    ProductsService.getAutomationProduct(productId).then(async data => {
      productManagementEvent({ dataAutomationList: data });
    });
  } catch {
    alertEvent({
      open: true,
      text: `Ocorreu um erro ao obter os dados, tente novamente mais tarde`,
      type: 'error',
    });
  } finally {
    productManagementEvent({ isLoading: false });
  }
};

export const getAutomationListProductManagementUseCase = { execute };

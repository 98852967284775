import { FormControl, MenuItem, Select, Typography, useTheme } from '@mui/material';

export type ISelectInputProps = any;

function SelectInput({ options, helperText, ...others }: ISelectInputProps) {
  const inputId = `${others?.name}-input`;
  const theme = useTheme();
  return (
    <FormControl fullWidth>
      <Typography component="label" htmlFor={inputId} fontSize={14}>
        {others?.label}
      </Typography>
      <Select
        {...others}
        labelId={inputId}
        id={inputId}
        label=""
        placeholder=""
        variant="outlined"
        margin="none"
        sx={{
          fontSize: 16,
          background: '#F8F9FD',
          height: 48,
          borderColor: others?.error ? theme.palette.error.main : theme.palette.primary.main,
        }}
      >
        {options &&
          options.map(obj => {
            return (
              <MenuItem value={obj?.name} key={obj?.name}>
                <Typography fontSize={16}>{obj?.name}</Typography>
              </MenuItem>
            );
          })}
      </Select>
      {helperText && <Typography fontSize="12px">{helperText}</Typography>}
    </FormControl>
  );
}

export default SelectInput;

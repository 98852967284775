import { getNotificationsUseCase } from 'useCases/notifications/getNotificationsUseCase';

import { SolicitationService } from 'services/SolicitationService/SolicitationService';

import { Common } from 'utils';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { getPartnersDetailsUseCase } from './getPartnersDetailsUseCase';
import { getPartnersManagementUseCase } from './getPartnersManagementUseCase';

type IParams = {
  newUserSector: string;
  reset: () => void;
};

const execute = async (params: IParams) => {
  try {
    partnersManagementEvent({ isLoadingManager: true });
    const { selectedRow } = partnersManagementStore.getState();
    const { sector } = authStore.getState();
    const currentUser = selectedRow?.users?.find(obj => obj?.owner);
    await SolicitationService.create({
      partnerName: selectedRow?.partner?.name || null,
      partnerCode: selectedRow?.partner?.cnpj_cpf || null,
      currentUserSector: currentUser?.sector || null,
      userSector: sector,
      newUserSector: params.newUserSector,
      type: 'ManagerTransfer',
      equipmentIds: null,
      requestId: null,
    });
    await getPartnersManagementUseCase.execute();
    await Common.sleep(1000);
    const { dataSource } = partnersManagementStore.getState();
    const refreshedSelectedRow = dataSource?.data?.partners?.find(
      obj => obj?.partner?.cnpj_cpf === selectedRow?.partner?.cnpj_cpf,
    );
    partnersManagementEvent({
      selectedRow: refreshedSelectedRow,
    });
    await getPartnersDetailsUseCase.execute();
    alertEvent({ open: true, text: 'Solicitação realizada com sucesso.', type: 'success' });
  } catch (err) {
    alertEvent({ open: true, text: 'Ocorreu um erro, tente novamente mais tarde.', type: 'error' });
  } finally {
    partnersManagementEvent({
      isLoadingManager: false,
      isOpenDrawerPartnerManager: false,
      isOpenDrawerPartnerManagerModal: false,
    });
    params?.reset();
    getNotificationsUseCase.execute();
  }
};

export const changeManagerUseCase = { execute };

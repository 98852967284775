import * as Yup from 'yup';

export const EmailAutomationProductManagementValidators = Yup.object().shape({
  rowId: Yup.string(),
  type: Yup.string(),
  subject: Yup.string().required('* Campo obrigatório'),
  emailBody: Yup.string().required('* Campo obrigatório'),
  email: Yup.string()
    .required('* Campo é obrigatório')
    .email('* E-mail inválido')
    .matches(
      /^([a-z]){1,}([a-z0-9._-]){1,}([@]){1}([a-z0-9]){2,}([.]){1}([a-z]){2,}([.]?){1}([a-z]?){2,}$/i,
      '* E-mail inválido',
    ),
});

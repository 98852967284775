import { createStore } from 'effector';

import { notificationEvent } from './NotificationsEvents';
import { NotificationState, notificationInitialState } from './NotificationsState';

const notificationStore = createStore<NotificationState>(notificationInitialState).on(
  notificationEvent,
  (state, payload) => ({
    ...state,
    ...payload,
  }),
);

export default notificationStore;

import { RequestService } from 'services/RequestService/RequestService';

import { Common } from 'utils';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { getPartnersDetailsUseCase } from './getPartnersDetailsUseCase';
import { getPartnersManagementUseCase } from './getPartnersManagementUseCase';

type IParams = {
  listCoManagers: { sector: string }[];
  reset: () => void;
};

const execute = async (params: IParams) => {
  try {
    partnersManagementEvent({ isLoadingCoManagers: true });
    const { selectedRow } = partnersManagementStore.getState();
    const { sector } = authStore.getState();
    const payload = {
      partnerCode: selectedRow?.partner?.cnpj_cpf || null,
      userSector: sector,
      coManagersSectors: params?.listCoManagers?.map(obj => obj.sector) || [],
    };
    await RequestService.changePartnerCoManagers(payload);
    await getPartnersManagementUseCase.execute();
    await Common.sleep(1000);
    const { dataSource } = partnersManagementStore.getState();
    const refreshedSelectedRow = dataSource?.data?.partners?.find(
      obj => obj?.partner?.cnpj_cpf === selectedRow?.partner?.cnpj_cpf,
    );
    partnersManagementEvent({
      selectedRow: refreshedSelectedRow,
    });
    await getPartnersDetailsUseCase.execute();
  } catch (err) {
    alertEvent({ open: true, text: 'Ocorreu um erro, tente novamente mais tarde.', type: 'error' });
  } finally {
    partnersManagementEvent({
      isLoadingCoManagers: false,
      isOpenDrawerPartnerCoManagers: false,
      isOpenDrawerPartnerCoManagersModal: false,
    });
    params?.reset();
  }
};

export const changeCoManagersUseCase = { execute };

import { useEffect } from 'react';

import { useStore } from 'effector-react';

import { Card } from '@mui/material';

import ContentPage from 'components/ui/content';
import { cardStyles } from 'components/ui/content/styles';

import { getRequestDynamicFormUseCase } from 'useCases/requestDynamicForm/getRequestDynamicFormUseCase';

import requestDynamicFormStore from 'stores/RequestDynamicForm/RequestDynamicFormStore';

import DynamicFormSkeleton from '../DynamicFormSkeleton';
import DynamicForm from './DynamicForm';

export default function DynamicFormPage({ match }): JSX.Element {
  const { id } = match.params;

  const { data } = useStore(requestDynamicFormStore);

  useEffect(() => {
    getRequestDynamicFormUseCase.execute(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentPage title="Nova Solicitação">
      {data?.data ? (
        <Card sx={cardStyles}>{data?.data && <DynamicForm data={data?.data} />}</Card>
      ) : (
        <DynamicFormSkeleton />
      )}
    </ContentPage>
  );
}

import { addHours, format } from 'date-fns';
import { useStore } from 'effector-react';

import { FactoryOutlined } from '@mui/icons-material';
import { Box, Button, Card, Stack, Typography } from '@mui/material';

import { authStore } from 'stores/AuthStore/AuthStore';
import { notificationEvent } from 'stores/Notifications/NotificationsEvents';
import notificationStore from 'stores/Notifications/NotificationsStore';

import { StatusStringEnum } from 'domains/status';

import CustomPagination from '../pagination/Pagination';
import { cardIconAvatarStyles } from './DrawerNotifications.styles';

type IProps = {
  setSelectedId: (value: string) => void;
};

function ListEquipmentReturn({ setSelectedId }: IProps): JSX.Element {
  const { equipmentReturn, pageTabEquipmentReturn } = useStore(notificationStore);

  return (
    <>
      {equipmentReturn?.data?.solicitations?.length === 0 && (
        <Card sx={{ width: '100%', padding: 4, height: 90, mt: 4 }}>
          <Typography style={{ fontSize: 16 }} textAlign="center">
            Nenhuma solicitação de remanejamento para matriz encontrada...
          </Typography>
        </Card>
      )}
      {equipmentReturn?.data?.solicitations
        ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        ?.map(obj => {
          const isNotification = obj.subType === 'Notification';
          if (isNotification) {
            return <CardNotificationEquipmentReturn {...{ obj, setSelectedId }} />;
          }
          return <CardSolicitationEquipmentReturn {...{ obj, setSelectedId }} />;
        })}
      {!!equipmentReturn?.data?.totalPages && (
        <CustomPagination
          total={equipmentReturn?.data?.totalPages}
          page={pageTabEquipmentReturn}
          setPage={v => notificationEvent({ pageTabEquipmentReturn: v })}
          position="center"
        />
      )}
    </>
  );
}

function CardSolicitationEquipmentReturn(props) {
  const { obj, setSelectedId } = props;
  const { permission, sector } = useStore(authStore);
  const timezone = new Date().getTimezoneOffset() / 60;
  const isShowActions = permission === 0 || obj.toUser.sector === sector;
  return (
    <Card key={obj.id} sx={{ width: '100%', p: 2, my: 1, height: 'auto' }}>
      <Stack direction="row" gap={2} alignItems="center">
        <Box sx={cardIconAvatarStyles}>
          <FactoryOutlined sx={{ color: '#FFF' }} />
        </Box>
        <Typography fontSize={14}>
          <b>{obj?.createdBy?.name || '-'}</b> está requisitando um remanejamento para{' '}
          <b style={{ color: '#005391' }}>Matriz</b>
        </Typography>
      </Stack>
      <Box mt={2}>
        <Typography fontSize={14}>
          <b>Origem:</b> {obj.partnerName} - {obj.partnerCode}
        </Typography>
        <Typography fontSize={14}>
          <b>Equipamento:</b> {obj?.product?.name || '-'}
        </Typography>
        <Typography fontSize={14} mt={2}>
          <b>Quantidade:</b> {obj.equipmentSerials.length}
        </Typography>
        <Typography fontSize={14}>
          <b>Números de série:</b>
          <Box ml={2}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${obj.equipmentSerials?.length > 3 ? 2 : 1}, 1fr)`,
                gap: '2px',
                height: obj.equipmentSerials?.length > 2 ? 107 : 'auto',
                overflow: 'auto',
              }}
            >
              {obj.equipmentSerials
                ?.sort((a, b) => a.localeCompare(b))
                ?.map(item => (
                  <Typography fontSize="14px">{`${item || 'N/A'};`}</Typography>
                ))}
            </div>
          </Box>
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography fontSize={14}>
          Ao aceitar esta solicitação, o equipamento será remanejado para a matriz e não constará
          mais nesse parceiro.
        </Typography>
      </Box>
      {obj?.status !== StatusStringEnum.InAnalysis ? (
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          mt={2}
        >
          <Typography style={{ fontSize: 14 }}>
            {format(addHours(new Date(obj.createdAt), -timezone), 'dd/MM/yy HH:mm')}
          </Typography>
          <Typography
            style={{ fontSize: 16 }}
            color={obj.status === 'Approved' ? 'green' : 'error'}
            fontWeight="bold"
          >
            {obj.status === 'Approved' ? 'Aprovado' : 'Recusado'}
          </Typography>
        </Stack>
      ) : (
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          mt={2}
        >
          <Typography style={{ fontSize: 14 }}>
            {format(addHours(new Date(obj.createdAt), -timezone), 'dd/MM/yy HH:mm')}
          </Typography>
          {isShowActions ? (
            <Stack direction="row" gap={2}>
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={() => {
                  setSelectedId(obj.id);
                  notificationEvent({ openModalReprove: true });
                }}
              >
                RECUSAR
              </Button>
              <Button
                color="success"
                variant="contained"
                size="small"
                onClick={() => {
                  setSelectedId(obj.id);
                  notificationEvent({ openModalApprove: true });
                }}
              >
                ACEITAR
              </Button>
            </Stack>
          ) : (
            <Typography style={{ fontSize: 16 }} color="warning" fontWeight="bold">
              Pendente
            </Typography>
          )}
        </Stack>
      )}
    </Card>
  );
}

function CardNotificationEquipmentReturn(props) {
  const timezone = new Date().getTimezoneOffset() / 60;
  const { obj } = props;
  return (
    <Card key={obj.id} sx={{ width: '100%', p: 2, my: 1, height: 'auto' }}>
      <Stack direction="row" gap={2} alignItems="center">
        <Box sx={cardIconAvatarStyles}>
          <FactoryOutlined sx={{ color: '#FFF' }} />
        </Box>
        <Typography fontSize={14}>
          <b>{obj?.createdBy?.name || '-'}</b> {obj.status === 'Approved' ? 'aprovou' : 'recusou'}{' '}
          uma solicitação de remanejamento de <b>{obj?.product?.name || '-'}</b> para{' '}
          <b style={{ color: '#005391' }}>Matriz</b>
        </Typography>
      </Stack>
      <Typography fontSize={14} mt={1}>
        <b>Origem:</b> {obj.partnerName} - {obj.partnerCode}
      </Typography>
      <Typography fontSize={14} mt={1}>
        <b>Solicitante:</b> {obj?.toUser?.name}
      </Typography>
      <Stack width="100%" mt={2}>
        <Typography style={{ fontSize: 14 }}>
          {format(addHours(new Date(obj.createdAt), -timezone), 'dd/MM/yy HH:mm')}
        </Typography>
      </Stack>
    </Card>
  );
}

export default ListEquipmentReturn;

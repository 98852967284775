/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';

import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useStore } from 'effector-react';

import { FileDownloadOutlined, FilterAltOffOutlined, SearchOutlined } from '@mui/icons-material';
import { Button, CircularProgress, MenuItem, Select, Stack, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { exportRequestsUseCase } from 'useCases/requestManagement/exportRequestUseCase';

import { ProductsService } from 'services/ProductService/ProductService';

import { Formatter } from 'utils';

import { authStore } from 'stores/AuthStore/AuthStore';
import { requestManagementEvent } from 'stores/RequestManagement/RequestManagementEvents';
import requestManagementStore from 'stores/RequestManagement/RequestManagementStore';

import 'dayjs/locale/pt-br';
import { IProduct } from 'domains/product';
import { StatusStringEnum } from 'domains/status';
import TextInput from 'components/form/textInput';

dayjs.locale('pt-br');

function FiltersRequest() {
  const { isLoadingExport } = useStore(requestManagementStore);
  const { permission } = useStore(authStore);
  const [status, setStatus] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [productId, setProductId] = useState('');
  const [dateStart, setDateStart] = useState<Date | null>(null);
  const [dateEnd, setDateEnd] = useState<Date | null>(null);
  const [listProducts, setListProducts] = useState<IProduct[]>([]);

  const handleFilter = () => {
    const formattedStartDate = format(new Date(dateStart), 'yyyy-MM-dd');
    const formattedEndDate = format(new Date(dateEnd), 'yyyy-MM-dd');
    const filters = {
      StartDate: !dateStart ? null : formattedStartDate,
      EndDate: !dateEnd ? null : formattedEndDate,
      Status: !status ? null : status,
      ProductId: !productId ? null : productId,
      Cnpj: !cnpj ? null : cnpj,
    };
    requestManagementEvent({ filters });
  };

  const getSolutions = async () => {
    ProductsService.getAllProducts()
      .then(({ data }) => {
        setListProducts(data.products);
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    getSolutions();
  }, []);

  return (
    <Stack flex={1} py={2} mb={2} mt={5} gap={2} flexDirection="row" justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap={2}>
        <Stack direction="row" alignItems="center" display="contents">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                bgcolor: '#fff',
                '& .MuiInputBase-input': { height: '22px', fontSize: '14px' },
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  style: {
                    width: '180px',
                  },
                },
              }}
              format="DD/MM/YYYY"
              disableFuture
              value={dateStart}
              onChange={v => setDateStart(v)}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                bgcolor: '#fff',
                '& .MuiInputBase-input': { height: '22px', fontSize: '14px' },
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  style: {
                    width: '180px',
                  },
                },
              }}
              format="DD/MM/YYYY"
              disableFuture
              value={dateEnd}
              minDate={dateStart}
              onChange={v => setDateEnd(v)}
            />
          </LocalizationProvider>
        </Stack>

        <Select
          displayEmpty
          sx={{
            width: '195px',
            height: '40px',
            fontSize: '16px',
            backgroundColor: 'white',
            '& .MuiSelect-select': { color: productId !== '' ? 'black' : '#ccc' },
          }}
          renderValue={productId !== '' ? undefined : () => 'Diagnóstico'}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={productId}
          onChange={v => setProductId(v.target.value?.toString())}
        >
          {listProducts
            ?.sort((a, b) => a?.name?.localeCompare(b?.name))
            .map(obj => (
              <MenuItem value={obj.id} key={obj.id}>
                <Typography fontSize="16px">{Formatter.truncate(obj.name, 15)}</Typography>
              </MenuItem>
            ))}
        </Select>
        <Select
          displayEmpty
          sx={{
            width: '208px',
            height: '40px',
            fontSize: '16px',
            backgroundColor: 'white',
            '& .MuiSelect-select': { color: status !== '' ? 'black' : '#ccc' },
          }}
          renderValue={status !== '' ? undefined : () => 'Status'}
          labelId="demo-simple-select-label"
          placeholder="Status"
          id="demo-simple-select"
          value={status}
          onChange={v => setStatus(v.target.value?.toString())}
        >
          {[
            { value: StatusStringEnum.InAnalysis, label: 'Pendente', visible: [0, 2] },
            { value: StatusStringEnum.Approved, label: 'Aprovado', visible: [0, 2] },
            { value: StatusStringEnum.Reproved, label: 'Recusada', visible: [0, 2] },
            { value: StatusStringEnum.SentSuplyer, label: 'Enviado fornecedor', visible: [0] },
            { value: 'Active', label: 'Concluída', visible: [0, 2] },
            { value: 'Done', label: 'Encerrado', visible: [0] },
            { value: 'SentMatrix', label: 'Enviado matriz', visible: [0] },
            { value: 'Deleted', label: 'Deletado', visible: [0, 2] },
            // { value: 'BackMatrix', label: 'Voltando matriz', visible: [0] },
          ]
            .sort((a, b) => a?.label?.localeCompare(b?.label))
            .filter(obj => obj.visible.includes(permission))
            .map(obj => (
              <MenuItem key={obj.value} value={obj.value}>
                <Typography fontSize="16px">{obj.label}</Typography>
              </MenuItem>
            ))}
        </Select>
        <Stack>
          <TextInput
            placeholder="CNPJ"
            value={cnpj}
            onChange={e => setCnpj(e.target.value)}
            name="cnpj"
            type="text"
            height={24}
            style={{ width: '190px' }}
          />
        </Stack>
        <Stack direction="row">
          <Button
            onClick={() => {
              setDateStart(null);
              setDateEnd(null);
              setProductId('');
              setStatus('');
              setCnpj('');
              requestManagementEvent({ filters: null });
            }}
            variant="contained"
            color="inherit"
          >
            <FilterAltOffOutlined />
          </Button>
          <Button
            sx={{ ml: 1 }}
            onClick={handleFilter}
            variant="contained"
            disabled={!!dateStart && !dateEnd}
          >
            <SearchOutlined />
          </Button>
        </Stack>
      </Stack>
      <Stack alignItems="center" direction="row">
        <Button
          onClick={() => exportRequestsUseCase.execute()}
          variant="contained"
          disabled={isLoadingExport}
          startIcon={isLoadingExport ? <CircularProgress size="22px" /> : <FileDownloadOutlined />}
        >
          EXPORTAR
        </Button>
      </Stack>
    </Stack>
  );
}

export default FiltersRequest;

/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-params */
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useStore } from 'effector-react';

import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowForwardOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Drawer, Grid, Stack, Typography, useTheme } from '@mui/material';

import SwitchToggle from 'components/form/switchToggle';
import TextInput from 'components/form/textInput';
import CardQuestion from 'components/ui/cardQuestion';

import { createProductAutomationUseCase } from 'useCases/productManagement/createAutomationUseCase';
import { getAutomationProductManagementUseCase } from 'useCases/productManagement/getAutomationProductManagementUseCase';

import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';
import { productManagementInitialState } from 'stores/ProductManagement/ProductManagementState';
import productManagementStore from 'stores/ProductManagement/ProductManagementStore';

import { SpreadsheetAutomationProductManagementValidators } from './SpreadsheetAutomationProductManagementValidators';

SpreadsheetAutomationDrawer.defaultProps = {
  rowId: null,
};
function SpreadsheetAutomationDrawer({ rowId }: { rowId?: string | null }) {
  const [reseted, setReseted] = useState(false);

  const theme = useTheme();
  const {
    isOpenSpreadsheetAutomation,
    dataProduct,
    listIdentificationAutomationQuestions,
    listClientAutomationQuestions,
    listNegotiationAutomationQuestions,
    listShippingAutomationQuestions,
  } = useStore(productManagementStore);

  const closeForm = () => {
    reset();
    clearErrors();
    productManagementEvent({
      isOpenSpreadsheetAutomation: false,
      dataProduct: productManagementInitialState.dataProduct,
    });
  };

  const {
    control,
    clearErrors,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(SpreadsheetAutomationProductManagementValidators),
    mode: 'all',
  });

  useEffect(() => {
    if (rowId && isOpenSpreadsheetAutomation) getAutomationProductManagementUseCase.execute(rowId);
    if (!isOpenSpreadsheetAutomation) closeForm();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenSpreadsheetAutomation]);

  if (!isOpenSpreadsheetAutomation) return <></>;

  return (
    <Drawer anchor="right" open={isOpenSpreadsheetAutomation} onClose={closeForm}>
      <Box
        sx={{
          [theme.breakpoints.between('sm', 'xs')]: {
            width: 480,
            maxWidth: 480,
          },
          [theme.breakpoints.up('md')]: {
            width: 580,
            maxWidth: 580,
          },
          minHeight: 'auto',
          borderRadius: '10px 0 0 10px !important',
          backgroundColor: '#FFF',
        }}
      >
        <Stack
          px={4}
          py={4}
          direction="column"
          justifyContent="space-between"
          flex={1}
          sx={{
            height: '100%',
            paddingBottom: 5,
          }}
        >
          <div>
            <Typography fontSize="26px" fontWeight="bold">
              Automação via Planilha
            </Typography>
            <Typography fontSize="16px">
              Aqui, é possível configurar a automação das planilhas para este produto, assim como
              determinar os campos que estarão visíveis na mesma.
            </Typography>
            <div style={{ marginTop: 18 }} />
            <Typography fontSize="12px" fontWeight="bold" mt={4}>
              INFORMAÇÕES DA PLANILHA
            </Typography>
            <Divider />
            <Grid container direction="row" mt={2} gap={2} flexWrap="nowrap">
              <Grid item xs={12}>
                <Controller
                  name="url"
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <div>
                        <TextInput
                          label="Nome do arquivo (.csv)"
                          name={name}
                          value={value}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onChange(e.target.value);
                          }}
                          error={!!errors.url}
                          helperText={errors.url?.message}
                        />
                      </div>
                    );
                  }}
                />
                {!!dataProduct?.data?.productVersionId && (
                  <Controller
                    name="rowId"
                    control={control}
                    defaultValue={dataProduct?.data?.productVersionId}
                    render={({ field: { value, name } }) => {
                      return (
                        <div>
                          <TextInput name={name} value={value} sx={{ display: 'none' }} />
                        </div>
                      );
                    }}
                  />
                )}
                <Controller
                  name="type"
                  control={control}
                  defaultValue="Sheet"
                  render={({ field: { value, name } }) => {
                    return (
                      <div>
                        <TextInput name={name} value={value} sx={{ display: 'none' }} />
                      </div>
                    );
                  }}
                />
              </Grid>
            </Grid>

            {!reseted && dataProduct ? (
              <>
                <Typography fontSize="12px" fontWeight="bold" mt={4}>
                  INFORMAÇÕES DO SOLICITANTE
                </Typography>
                <Divider />
                {listIdentificationAutomationQuestions
                  ?.sort((a, b) => (a?.order < b?.order ? -1 : 1))
                  .map(obj => {
                    return (
                      <CardQuestion
                        key={obj?.id}
                        order={obj?.order}
                        onChange={(draggedOrder, droppedOnOrder) => {
                          setReseted(true);
                          const selectedItemId = listIdentificationAutomationQuestions.find(
                            it => it.order === draggedOrder,
                          );
                          const itemToUpdateId = listIdentificationAutomationQuestions.find(
                            it => it.order === droppedOnOrder,
                          );
                          const newArr = [...listIdentificationAutomationQuestions]?.map(it => ({
                            ...it,
                            order:
                              it.id === selectedItemId?.id
                                ? droppedOnOrder
                                : it.id === itemToUpdateId?.id
                                ? draggedOrder
                                : it.order,
                          }));
                          productManagementEvent({ listIdentificationAutomationQuestions: newArr });
                          setReseted(false);
                        }}
                      >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography fontSize={16}>{obj?.question?.description}</Typography>
                          <SwitchToggle
                            value={obj.isActive}
                            onChange={() => {
                              const newArr = [...listIdentificationAutomationQuestions];
                              const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                              newArr[replaceIdx].isActive = !obj?.isActive;
                              productManagementEvent({
                                listIdentificationAutomationQuestions: newArr,
                              });
                            }}
                          />
                        </Stack>
                      </CardQuestion>
                    );
                  })}

                {listClientAutomationQuestions && listClientAutomationQuestions.length > 0 && (
                  <>
                    <Typography fontSize="12px" fontWeight="bold" mt={4}>
                      INFORMAÇÕES DA CLÍNICA/MÉDICO
                    </Typography>
                    <Divider />
                    {listClientAutomationQuestions
                      ?.sort((a, b) => (a?.order < b?.order ? -1 : 1))
                      .map(obj => {
                        return (
                          <CardQuestion
                            key={obj?.id}
                            order={obj?.order}
                            onChange={(draggedOrder, droppedOnOrder) => {
                              setReseted(true);
                              const selectedItemId = listClientAutomationQuestions.find(
                                it => it.order === draggedOrder,
                              );
                              const itemToUpdateId = listClientAutomationQuestions.find(
                                it => it.order === droppedOnOrder,
                              );
                              const newArr = [...listClientAutomationQuestions]?.map(it => ({
                                ...it,
                                order:
                                  it.id === selectedItemId?.id
                                    ? droppedOnOrder
                                    : it.id === itemToUpdateId?.id
                                    ? draggedOrder
                                    : it.order,
                              }));
                              productManagementEvent({ listClientAutomationQuestions: newArr });
                              setReseted(false);
                            }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography fontSize={16}>{obj?.question?.description}</Typography>
                              <SwitchToggle
                                value={obj.isActive}
                                onChange={() => {
                                  const newArr = [...listClientAutomationQuestions];
                                  const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                                  newArr[replaceIdx].isActive = !obj?.isActive;
                                  productManagementEvent({ listClientAutomationQuestions: newArr });
                                }}
                              />
                            </Stack>
                          </CardQuestion>
                        );
                      })}
                  </>
                )}

                {listNegotiationAutomationQuestions &&
                  listNegotiationAutomationQuestions.length > 0 && (
                    <>
                      <Typography fontSize="12px" fontWeight="bold" mt={4}>
                        INFORMAÇÕES DA PARCERIA
                      </Typography>
                      <Divider />

                      {listNegotiationAutomationQuestions
                        ?.sort((a, b) => (a?.order < b?.order ? -1 : 1))
                        .map(obj => {
                          return (
                            <CardQuestion
                              key={obj.id}
                              order={obj?.order}
                              onChange={(draggedOrder, droppedOnOrder) => {
                                setReseted(true);
                                const selectedItemId = listNegotiationAutomationQuestions.find(
                                  it => it.order === draggedOrder,
                                );
                                const itemToUpdateId = listNegotiationAutomationQuestions.find(
                                  it => it.order === droppedOnOrder,
                                );
                                const newArr = [...listNegotiationAutomationQuestions]?.map(it => ({
                                  ...it,
                                  order:
                                    it?.id === selectedItemId?.id
                                      ? droppedOnOrder
                                      : it.id === itemToUpdateId?.id
                                      ? draggedOrder
                                      : it.order,
                                }));
                                productManagementEvent({
                                  listNegotiationAutomationQuestions: newArr,
                                });

                                setReseted(false);
                              }}
                            >
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography fontSize={16}>{obj?.question?.description}</Typography>
                                <SwitchToggle
                                  value={obj.isActive}
                                  onChange={() => {
                                    const newArr = [...listNegotiationAutomationQuestions];
                                    const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                                    newArr[replaceIdx].isActive = !obj?.isActive;
                                    productManagementEvent({
                                      listNegotiationAutomationQuestions: newArr,
                                    });
                                  }}
                                />
                              </Stack>
                            </CardQuestion>
                          );
                        })}
                    </>
                  )}
                {listShippingAutomationQuestions && listShippingAutomationQuestions.length > 0 && (
                  <>
                    <Typography fontSize="12px" fontWeight="bold" mt={4}>
                      INFORMAÇÕES DE ENTREGA (SOLICITANTE EMS)
                    </Typography>
                    <Divider />
                    {listShippingAutomationQuestions
                      ?.sort((a, b) => (a?.order < b?.order ? -1 : 1))
                      .map(obj => {
                        return (
                          <CardQuestion
                            key={obj.productVersionId}
                            order={obj?.order}
                            onChange={(draggedOrder, droppedOnOrder) => {
                              setReseted(true);
                              const selectedItemId = listShippingAutomationQuestions.find(
                                it => it.order === draggedOrder,
                              );
                              const itemToUpdateId = listShippingAutomationQuestions.find(
                                it => it.order === droppedOnOrder,
                              );
                              const newArr = [...listShippingAutomationQuestions]?.map(it => ({
                                ...it,
                                order:
                                  it.id === selectedItemId?.id
                                    ? droppedOnOrder
                                    : it.id === itemToUpdateId?.id
                                    ? draggedOrder
                                    : it.order,
                              }));
                              productManagementEvent({
                                listShippingAutomationQuestions: newArr,
                              });
                              setReseted(false);
                            }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography fontSize={16}>{obj?.question?.description}</Typography>
                              <SwitchToggle
                                value={obj.isActive}
                                onChange={() => {
                                  const newArr = [...listShippingAutomationQuestions];
                                  const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                                  newArr[replaceIdx].isActive = !obj?.isActive;
                                  productManagementEvent({
                                    listShippingAutomationQuestions: newArr,
                                  });
                                }}
                              />
                            </Stack>
                          </CardQuestion>
                        );
                      })}
                  </>
                )}
              </>
            ) : (
              <Box sx={{ height: 2000 }} />
            )}
          </div>
          <Stack direction="row" justifyContent="flex-end" sx={{ padding: '20px 0' }}>
            <Button
              variant="contained"
              sx={{ width: 'auto' }}
              disabled={!isValid}
              onClick={handleSubmit(createProductAutomationUseCase.execute)}
              endIcon={<ArrowForwardOutlined color="inherit" />}
            >
              CONFIGURAR AUTOMAÇÃO
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
}

export default SpreadsheetAutomationDrawer;

import { createStore } from 'effector';

import { alertEvent } from './AlertEvents';
import { AlertState, alertInitialState } from './AlertState';

const alertStore = createStore<AlertState>(alertInitialState).on(alertEvent, (state, payload) => ({
  ...state,
  ...payload,
}));

export default alertStore;

import { CompaignsService } from 'services/CompaignService/CompaignService';
import { ProductsService } from 'services/ProductService/ProductService';
import { QuestionService } from 'services/QuestionService/QuestionService';

import { Common } from 'utils';

import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';
import productManagementStore from 'stores/ProductManagement/ProductManagementStore';

import { QuestionCategoryEnum } from 'domains/question';

const execute = async () => {
  try {
    productManagementEvent({ isLoading: true });
    const { pageNow, pageSize } = productManagementStore.getState();
    const [list, questions, listCompaign] = await Promise.all([
      ProductsService.getProducts(pageNow, pageSize),
      QuestionService.getQuestions(),
      CompaignsService.getAllCompaigns(),
    ]);

    const formattedArrayQuestions = questions?.data?.questions?.map((obj, i) => ({
      ...obj,
      isActive: obj?.isRequired ?? false,
      order: i,
    }));

    const listIdentificationQuestions = formattedArrayQuestions?.filter(
      obj => obj.questionCategory === QuestionCategoryEnum.IDENTIFICATION,
    );
    const listClientQuestions = formattedArrayQuestions?.filter(
      obj => obj.questionCategory === QuestionCategoryEnum.CLIENT,
    );
    const listNegotiationQuestions = formattedArrayQuestions?.filter(
      obj => obj.questionCategory === QuestionCategoryEnum.NEGOTIATION,
    );
    const listShippingQuestions = formattedArrayQuestions?.filter(
      obj => obj.questionCategory === QuestionCategoryEnum.SHIPPING,
    );

    const lastOrder = [...formattedArrayQuestions]?.pop()?.order;
    const formattedListCompaign = listCompaign?.data?.campaigns?.map((obj, index) => ({
      ...obj,
      isActive: false,
      order: 1 + (lastOrder ? Number(lastOrder) : 1) + index,
    }));
    await Common.sleep(1000);

    productManagementEvent({
      list,
      listIdentificationQuestions,
      listClientQuestions,
      listNegotiationQuestions,
      listShippingQuestions,
      listCompaign: formattedListCompaign,
    });
  } catch (err) {
    // console.log(err)
  } finally {
    productManagementEvent({ isLoading: false });
  }
};

export const getProductManagementUseCase = { execute };

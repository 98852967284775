import { CompaignsService } from 'services/CompaignService/CompaignService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { compaignManagementEvent } from 'stores/CompaignManagement/CompaignManagementEvents';

import { getCompaignManagementUseCase } from './getCompaignManagementUseCase';

const execute = async ({ name }: { name: string }) => {
  try {
    compaignManagementEvent({ isLoadingForm: true });
    await CompaignsService.createCompaign(name);
    alertEvent({
      open: true,
      text: 'Campanha criada com sucesso.',
      type: 'success',
    });
  } catch (err) {
    alertEvent({
      open: true,
      text: 'Ocorreu um erro ao criar campanha, tente novamente mais tarde.',
      type: 'error',
    });
  } finally {
    compaignManagementEvent({ isOpenForm: false, isLoadingForm: false, isOpenModalSubmit: false });
    getCompaignManagementUseCase.execute();
  }
};

export const createCompaignUseCase = { execute };

import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  AddToPhotosOutlined,
  CloudDownloadOutlined,
  ControlPointOutlined,
  ListAltOutlined,
  MedicalServicesOutlined,
  PeopleAltOutlined,
  RuleOutlined,
} from '@mui/icons-material';
import { Box, Button, Drawer, Stack, Typography, styled } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useKeycloak } from '@react-keycloak/web';

import useResponsive from 'hooks/useResponsive';

import { Common } from 'utils';

import { authEvent } from 'stores/AuthStore/AuthEvents';
import { authInitialState } from 'stores/AuthStore/AuthState';

import ConfirmModal from '../confirmModal/ConfirmModal';
import NavSection from './Menus';

const NAV_WIDTH = 256;

export default function Sidebar({ openNav, onCloseNav }) {
  const navigate = useHistory();
  const { pathname } = useLocation();
  const { keycloak } = useKeycloak();

  const [openModal, setOpenModal] = useState(false);

  const isDesktop = useResponsive({ query: 'up', start: 'lg' });

  const getIconColor = (path: string) => {
    return { color: path === pathname ? '#0c0c0c' : '#757575' };
  };

  const MENUS = [
    {
      title: 'Gestão de solicitações',
      path: '/app/request-management',
      icon: <ListAltOutlined sx={getIconColor('/app/request-management')} />,
      visible: [0],
    },
    {
      title: 'Minhas solicitações',
      path: '/app/my-requests',
      icon: <ListAltOutlined sx={getIconColor('/app/request-management')} />,
      visible: [2],
    },
    {
      title: 'Gestão de parcerias',
      path: '/app/partners-management',
      icon: <PeopleAltOutlined sx={getIconColor('/app/partners-management')} />,
      visible: [0, 2],
    },
    {
      title: 'Gestão de medicamentos',
      path: '/app/medicine-management',
      icon: <MedicalServicesOutlined sx={getIconColor('/app/medicine-management')} />,
      visible: [0],
    },
    {
      title: 'Gestão de campanhas',
      path: '/app/compaigns-management',
      icon: <RuleOutlined sx={getIconColor('/app/compaigns-management')} />,
      visible: [0],
    },
    {
      title: 'Gestão de equipamentos',
      path: '/app/product-management',
      icon: <AddToPhotosOutlined sx={getIconColor('/app/product-management')} />,
      visible: [0],
    },
    {
      title: 'Extrações',
      path: '/app/export-management',
      icon: <CloudDownloadOutlined sx={getIconColor('/app/export-management')} />,
      visible: [0],
    },
  ];

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <div style={{ marginTop: 8 }} />
      <Box sx={{ py: 3, margin: '0 auto' }}>
        <img src="/assets/logo-cardio.webp" alt="Logo Cardio 360" width={200} />
      </Box>
      <Button
        size="large"
        variant="contained"
        color="primary"
        sx={{ borderRadius: 0, height: 56 }}
        onClick={() => navigate.push('/app/new-request')} // Open form to add new request
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Typography fontSize="1rem">Nova solicitação</Typography>
          <ControlPointOutlined sx={{ color: '#fff' }} />
        </Stack>
      </Button>
      <NavSection data={MENUS} />
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row' }} alignItems="flex-end">
        <Box color="white" sx={{ borderTop: '1px solid #ccc', pl: 2, pt: 1, pb: 1, width: '100%' }}>
          <Button onClick={() => setOpenModal(true)} style={{ textDecoration: 'none' }}>
            <Stack direction="row" alignItems="center" gap={3}>
              <img src="/assets/logout.png" alt="logout" width={18} />
              <Typography color="black" fontSize={16}>
                Log Out
              </Typography>
            </Stack>
          </Button>
        </Box>
      </Box>
      <ConfirmModal
        open={openModal}
        setOpen={setOpenModal}
        confirmText="Confirmar"
        declineText="Voltar"
        title="Atenção"
        text="Tem certeza que deseja sair do sistema?"
        onConfirm={() => {
          Common.setSessionValue('logCookie', {});
          Common.clearCacheData();
          authEvent(authInitialState);
          keycloak.logout({ redirectUri: window.location.origin });
        }}
      />
    </>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <CustomDrawer open variant="permanent">
          {renderContent}
        </CustomDrawer>
      ) : (
        <CustomDrawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {renderContent}
        </CustomDrawer>
      )}
    </Box>
  );
}

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: NAV_WIDTH,
    backgroundColor: '#fff !important',
    border: 'none',
    borderTopRightRadius: 22,
    borderBottomRightRadius: 22,
    boxShadow: theme.shadows[6], // Adapte o número do shadow conforme necessário
  },
}));

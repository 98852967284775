/* eslint-disable react/require-default-props */
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material';
import { Pagination, PaginationItem, Skeleton, Stack } from '@mui/material';

interface IProps {
  page: number;
  total: number;
  setPage: (v: number) => void;
  position?: 'flex-start' | 'center' | 'flex-end';
  isLoading?: boolean;
}

const paginationItemStyle = {
  fontSize: '12px',
};

function CustomPagination({
  total,
  page,
  setPage,
  position = 'flex-end',
  isLoading = false,
}: IProps) {
  return (
    <Stack width="100%" direction="row" justifyContent={position} mt={2}>
      {!isLoading ? (
        <Pagination
          count={total}
          page={page}
          size="small"
          // color="primary"
          onChange={(_, v) => setPage(v)}
          className="custom-pagination"
          renderItem={item => (
            <PaginationItem
              slots={{ previous: ArrowBackIosOutlined, next: ArrowForwardIosOutlined }}
              style={paginationItemStyle}
              {...item}
            />
          )}
        />
      ) : (
        <Stack direction="row" alignItems="center" gap={2}>
          <Skeleton animation="wave" variant="circular" width={28} height={28} />
          <Skeleton animation="wave" variant="circular" width={28} height={28} />
          <Skeleton animation="wave" variant="circular" width={28} height={28} />
          <Skeleton animation="wave" variant="circular" width={28} height={28} />
        </Stack>
      )}
    </Stack>
  );
}

export default CustomPagination;

import { IProduct } from 'domains/product';

export interface NewRequestState {
  isLoading: boolean;
  list: IProduct[];
}

export const newRequestInitialState: NewRequestState = {
  isLoading: false,
  list: [],
};

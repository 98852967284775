import { useStore } from 'effector-react';

import { NotificationsOutlined } from '@mui/icons-material';
import { Avatar, Badge, Box, Grid, IconButton, Stack, Typography } from '@mui/material';

import { Common } from 'utils';

import { authStore } from 'stores/AuthStore/AuthStore';
import { notificationEvent } from 'stores/Notifications/NotificationsEvents';
import notificationStore from 'stores/Notifications/NotificationsStore';

export default function ContentPage({ title, children }) {
  const { name, role } = useStore(authStore);
  const { pendingNotifications } = useStore(notificationStore);

  const badgeValue = pendingNotifications;

  return (
    <div style={{ zIndex: 999, position: 'relative' }}>
      <Grid container>
        <Grid item>
          <Typography fontSize="26px" fontWeight="bold">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Box sx={{ position: 'absolute', right: 30, display: { xs: 'none', lg: 'block' } }}>
            <Grid container>
              <Grid item width={50}>
                <Avatar sx={{ bgcolor: '#005391' }}>
                  <Typography fontSize={20} fontWeight="bold">
                    {Common.convertNameToInitials(name)}
                  </Typography>
                </Avatar>
              </Grid>
              <Grid item minWidth={150}>
                <Typography fontSize={16} sx={{ color: '#005391' }}>
                  {name}
                </Typography>
                <Typography fontSize={12} fontWeight="bold" color="#5f5e5e">
                  {role}
                </Typography>
              </Grid>
              <Grid item width={50} flex={1} display="flex">
                <Box display="flex" alignItems="center" flex={1} ml={1}>
                  <Stack direction="row" alignItems="center">
                    <IconButton color="primary" onClick={() => notificationEvent({ open: true })}>
                      <Badge color="error" badgeContent={badgeValue}>
                        <NotificationsOutlined />
                      </Badge>
                    </IconButton>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Box>{children}</Box>
    </div>
  );
}

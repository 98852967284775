/* eslint-disable react/require-default-props */
import { Control, Controller, FieldValues } from 'react-hook-form';

import { activeToggleStyles, toggleButtonStyles } from 'styles/global.styles';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';

type IProps = {
  name: string;
  options: {
    value: string;
    label: string;
  }[];
  control: Control<FieldValues>;
};

export default function ToggleButtonInput({ control, name, options }: IProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <ToggleButtonGroup
            color="info"
            value={value}
            exclusive
            onChange={(_, v: string) => onChange(v)}
            aria-label="Platform"
            sx={{ mt: 2 }}
          >
            {options.map(item => (
              <ToggleButton
                value={item.value}
                sx={value === item.value ? activeToggleStyles : toggleButtonStyles}
              >
                {item.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        );
      }}
    />
  );
}

export interface IProductResponse {
  data: {
    products: IProduct[];
  };
  hasError: boolean;
  error: string;
}

export interface IProductByIdResponse {
  data: {
    productVersionId: string;
    authorId: string;
    name: string;
    description: string;
    thumbnail: string | null;
    status: true;
    createdAt: string;
    updatedAt: string;
    author: {
      id: string;
      userId: string;
      name: string;
      sector: string;
      role: string;
    };

    productQuestions: {
      id: string;
      status: boolean;
      isRequired: boolean;
      productVersionId: string;
      order: number;
      question: {
        id: string;
        description: string;
        questionType: string;
        questionSubType: string;
        questionCategory: string;
        isRequired: boolean;
        createdAt: string;
        updatedAt: string;
      };
      options: {
        id: string;
        name: string;
      }[];
    }[];

    campaigns: {
      id: string;
      name: string;
      status: boolean;
      createdAt: string;
    }[];
  };
  hasError: boolean;
  error: string;
}
export interface IProductPagedResponse {
  data: {
    products: IProduct[];
    totalRegistries: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
  };
  hasError: boolean;
  error: string;
}

export interface IProduct {
  id: string;
  productVersionId: string;
  authorId: string;
  name: string;
  description: string;
  thumbnail: string | null;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  author: null | {
    id: string;
    name: string;
    sector: string;
    role: string;
  };
}

export interface IParamsCreateProduct {
  userSector: string;
  name: string;
  description: string;
  thumbnail: string;
  questions: {
    id: string;
    order: number;
    isRequired: boolean;
  }[];
  campaigns: {
    id: string;
  }[];
}
export interface IParamsUpdateProduct {
  id?: string;
  userSector?: string;
  name: string;
  description: string;
  thumbnail?: string;
  questions: {
    id: string;
    order: number;
    isRequired: boolean;
  }[];
  campaigns: {
    id: string;
  }[];
}

export interface IParamsChangeStatusProduct {
  isActive: boolean;
}
export interface IParamsGetAutomationProduct {
  id?: string;
  status: boolean;
  isRequired: boolean;
  productVersionId: string;
  order: number;
  question: {
    id: string;
    description: string;
    questionType: string;
    questionSubType: null;
    questionCategory: string;
    isRequired: boolean;
    createdAt: string;
    updatedAt: string;
  };
  options: {
    id: string;
    name: string;
  }[];
  isActive: boolean;
}

export interface IParamsCreateAutomationProduct {
  automationId?: string | null;
  userSector: string;
  type: string;
  sheetPath?: string;
  sheetAuth?: string;
  supplierEmail?: string;
  emailSubject?: string;
  emailBody: string;
  questions: {
    productVersionQuestionId: string;
    active: boolean;
  }[];
}
export interface IParamsGetAutomationListResponse {
  data: {
    automation: {
      id: string;
      createdAt: string;
      type: string;
    }[];
    productName: string;
  };
  hasError: boolean;
  error: string;
}
export interface IParamsGetAutomationList {
  automation: {
    id: string;
    createdAt: string;
    email?: string | null;
    sheetPath?: string | null;
    type: string;
  };
  productId: string;
  setStateAutomationIds: (automationId: string, productId: string) => void;
}

export enum ProductEditEnum {
  edit = 0,
}
export enum ProductConfigEnum {
  email = 0,
  spreadsheet = 1,
  config = 2,
}

/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useStore } from 'effector-react';

import {
  AddCircleOutlineRounded,
  ArrowForwardOutlined,
  CloseOutlined,
  DeleteOutline,
  MedicalServicesOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import SelectInput from 'components/form/selectInput';
import TextInput from 'components/form/textInput';
import { ButtonBack } from 'components/ui/buttonBack/ButtonBack';

import { editCRMUseCase } from 'useCases/partnersManagement/editCRMUseCase';

import useResponsive from 'hooks/useResponsive';

import { BrazilState } from 'utils/uf';

import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { info1 } from '../PartnersManagementPage.styles';

function DrawerEditCrm() {
  const { isOpenDrawerEditCrm, partnerListCRM, requestDetails, isLoadingEditCRM } =
    useStore(partnersManagementStore);
  const { control, reset, handleSubmit } = useForm({ mode: 'all' });
  const isDesktop = useResponsive({ query: 'up', start: 'lg' });
  const [isFilled, setIsFilled] = useState(false);

  const onClose = () =>
    partnersManagementEvent({ isOpenDrawerEditCrm: false, isOpenDrawerDetails: true });

  useEffect(() => {
    const arr =
      partnerListCRM?.length > 0
        ? partnerListCRM.map(ufcrm => ({
            id: Math.random() * 999,
            uf: ufcrm.split('-')[0],
            crm: ufcrm.split('-')[1],
          }))
        : [{ id: Math.random() * 999, crm: '', uf: '' }];
    reset({ uf_crm: arr });

    setIsFilled(arr.some(i => !i.crm));
  }, [partnerListCRM, requestDetails, reset]);

  return (
    <Drawer anchor="right" open={isOpenDrawerEditCrm} onClose={onClose}>
      <Stack
        p={4}
        direction="column"
        flex={1}
        sx={{
          width: '540px',
          backgroundColor: 'white',
          paddingBottom: 5,
          borderRadius: '15px 0 0 15px',
        }}
      >
        <Stack mt={2} pb={1} direction="row" justifyContent="space-between" alignItems="center">
          <ButtonBack text="VOLTAR" onBack={onClose} />
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Stack>
        <Typography fontSize={22} fontWeight={700} pb={6}>
          Alterar CRMs
        </Typography>

        <Typography variant="caption" fontWeight="700" mb={1}>
          CRMs ENVOLVIDOS
        </Typography>
        <Divider />
        <Stack p={1} direction="row" gap={3} minHeight="154px">
          <Box>
            <MedicalServicesOutlined sx={{ fontSize: '50px' }} />
          </Box>
          <Box mt={1}>
            <Grid container flexDirection="row">
              {partnerListCRM?.map(item => (
                <Grid item xs={6} mb={1} key={item}>
                  <Typography sx={{ ...info1, width: 135 }}>{`${item};`}</Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>

        <Typography variant="caption" fontWeight="700" mt={3} mb={1}>
          ALTERAR CRMs
        </Typography>
        <Divider />
        <Stack mt={1}>
          <Grid item xs={12}>
            <Controller
              name="uf_crm"
              control={control}
              defaultValue={[{ id: Math.random() * 999, crm: '', uf: '' }]}
              render={({ field: { value, onChange } }) => {
                return (
                  <Box>
                    {value?.map((row, index) => (
                      <Grid container mt={2} gap={0} key={row.id}>
                        <Grid item xs={10}>
                          <Grid container gap={3} flexWrap={isDesktop ? 'nowrap' : 'wrap'}>
                            <Grid item xs={12} sm={7} md={7} lg={8}>
                              <TextInput
                                label=""
                                value={value[index]?.crm}
                                type="number"
                                name={`${'crm'}-${index}`}
                                onChange={e => {
                                  const v = e.target.value;
                                  if (v?.length > 7) return;
                                  const newArr = [...value];
                                  newArr[index].crm = v;
                                  setIsFilled(newArr.some(i => !parseInt(i.crm, 10) || !i.uf));
                                  onChange(newArr);
                                }}
                                onBlur={() => {
                                  const newArr = [...value];
                                  newArr[index].crm = newArr[index].crm?.padStart(7, '0');
                                  setIsFilled(newArr.some(i => !parseInt(i.crm, 10) || !i.uf));
                                  onChange(newArr);
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={4}>
                              <SelectInput
                                label=""
                                value={value[index]?.uf}
                                options={BrazilState?.map(obj => {
                                  return {
                                    label: obj.sigla,
                                    name: obj.sigla,
                                    value: obj.sigla,
                                    id: '',
                                    order: 0,
                                  };
                                })}
                                name={`${'uf'}-${index}`}
                                onChange={e => {
                                  const newArr = [...value];
                                  newArr[index].uf = e.target.value;
                                  setIsFilled(newArr.some(i => !parseInt(i.crm, 10) || !i.uf));
                                  onChange(newArr);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2}>
                          <Stack direction="row" ml={1} mt={0.5}>
                            {index === value.length - 1 && (
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  onChange([...value, { id: Math.random() * 999, crm: '', uf: '' }])
                                }
                              >
                                <AddCircleOutlineRounded color="success" style={{ fontSize: 24 }} />
                              </IconButton>
                            )}
                            {[...value].length > 1 && (
                              <IconButton
                                color="error"
                                onClick={() => onChange([...value].filter(it => it.id !== row.id))}
                              >
                                <DeleteOutline style={{ fontSize: 24 }} />
                              </IconButton>
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                );
              }}
            />
          </Grid>
        </Stack>

        <Button
          variant="contained"
          sx={{ width: 250, mt: 4 }}
          disabled={isLoadingEditCRM || isFilled}
          endIcon={!isLoadingEditCRM && <ArrowForwardOutlined color="inherit" />}
          onClick={handleSubmit(editCRMUseCase.execute)}
        >
          {isLoadingEditCRM ? <CircularProgress size="25px" /> : 'ALTERAR CRM'}
        </Button>
      </Stack>
    </Drawer>
  );
}

export default DrawerEditCrm;

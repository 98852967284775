import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import { useStore } from 'effector-react';

import { Box, List, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { authStore } from 'stores/AuthStore/AuthStore';

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export default function NavSection({ data = [], ...other }) {
  const { pathname } = useLocation();
  const { permission, sector } = useStore(authStore);
  const filteredMenuByUserPermission = data.filter(obj => obj.visible.includes(permission));

  return (
    <Box {...other}>
      {sector && (
        <List disablePadding>
          {filteredMenuByUserPermission.map((item, index) => (
            <ListItemButton
              key={index?.toString()}
              disableGutters
              component={RouterLink}
              to={item?.path}
              sx={{
                height: 48,
                borderBottomWidth: 1,
                borderStyle: 'solid',
                borderColor: '#cecece',
              }}
            >
              <StyledNavItemIcon>{item?.icon && item.icon}</StyledNavItemIcon>
              <Typography
                component="span"
                fontSize={14}
                sx={{
                  color: '#212121',
                  fontWeight: item?.path === pathname ? 'Bold' : 'Normal',
                }}
              >
                {item.title}
              </Typography>
            </ListItemButton>
          ))}
          <ListItemButton
            disableGutters
            sx={{
              height: 48,
              borderBottomWidth: 1,
              borderStyle: 'solid',
              borderColor: '#cecece',
            }}
            onClick={() =>
              window.open(
                encodeURI(
                  'https://app.powerbi.com/groups/me/reports/27809971-5eaa-4511-bcd6-c6077e523f11/ReportSection6653af1d9a280e32e023?ctid=e6e393d7-971b-4b04-bce1-ba3967f60dd4&experience=power-bi',
                ),
              )
            }
          >
            <StyledNavItemIcon>
              <img src="/assets/dashboard-icon.png" alt="Dashboard img" width={24} />
            </StyledNavItemIcon>
            <Typography
              component="span"
              fontSize={14}
              sx={{
                color: '#212121',
              }}
            >
              Dashboard
            </Typography>
          </ListItemButton>
          <ListItemButton
            disableGutters
            sx={{
              height: 48,
              borderBottomWidth: 1,
              borderStyle: 'solid',
              borderColor: '#cecece',
            }}
            onClick={() =>
              window.open(encodeURI('https://emspocbi.sharepoint.com/sites/cardio360'))
            }
          >
            <StyledNavItemIcon>
              <img src="/assets/materials-icon.png" alt="Dashboard img" width={24} />
            </StyledNavItemIcon>
            <Typography
              component="span"
              fontSize={14}
              sx={{
                color: '#212121',
              }}
            >
              Materiais
            </Typography>
          </ListItemButton>
        </List>
      )}
    </Box>
  );
}

export const BrazilState = [
  { sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
  { sigla: 'AL', nome: 'Alagoas', regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' } },
  { sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
  { sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
  { sigla: 'BA', nome: 'Bahia', regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' } },
  { sigla: 'CE', nome: 'Ceará', regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' } },
  { sigla: 'DF', nome: 'Distrito Federal', regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' } },
  { sigla: 'ES', nome: 'Espírito Santo', regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' } },
  { sigla: 'GO', nome: 'Goiás', regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' } },
  { sigla: 'MA', nome: 'Maranhão', regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' } },
  { sigla: 'MG', nome: 'Minas Gerais', regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' } },
  { sigla: 'MS', nome: 'Mato Grosso do Sul', regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' } },
  { sigla: 'MT', nome: 'Mato Grosso', regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' } },
  { sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
  { sigla: 'PB', nome: 'Paraíba', regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' } },
  { sigla: 'PE', nome: 'Pernambuco', regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' } },
  { sigla: 'PI', nome: 'Piauí', regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' } },
  { sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
  { sigla: 'RJ', nome: 'Rio de Janeiro', regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' } },
  { sigla: 'RN', nome: 'Rio Grande do Norte', regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' } },
  { sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
  { sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
  { sigla: 'RS', nome: 'Rio Grande do Sul', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
  { sigla: 'SC', nome: 'Santa Catarina', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
  { sigla: 'SE', nome: 'Sergipe', regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' } },
  { sigla: 'SP', nome: 'São Paulo', regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' } },
  { sigla: 'TO', nome: 'Tocantins', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
];

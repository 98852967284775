import { changeStatusRequestUseCase } from 'useCases/requestManagement/changeStatusRequestUseCase';

import { RequestService } from 'services/RequestService/RequestService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { requestManagementEvent } from 'stores/RequestManagement/RequestManagementEvents';

import { StatusEnum } from 'domains/status';
import { authStore } from 'stores/AuthStore/AuthStore';

const execute = async (form: any) => {
  try {
    const { sector } = authStore.getState();
    await RequestService.editValidityRequestManagementDetail(
      form.formId,
      sector,
      form.startDate,
      form.endDate,
    );

    await changeStatusRequestUseCase.execute({ id: form.formId, status: StatusEnum.Active });

    requestManagementEvent({ isOpenAlertModal: true });
  } catch (error) {
    alertEvent({
      open: true,
      type: 'error',
      text: 'Não foi possível registrar sua solicitação',
    });
  } finally {
    requestManagementEvent({ isOpenEditValidityModal: false });
  }
};

export const editValidityModalRequestManagementUseCase = { execute };

import { IUser } from 'domains/auth/AuthDomain';

export interface AuthState extends IUser {
  permission: number;
  token: string;
  isLoading?: boolean;
  role?: string;
  isLogged?: boolean;
  isAdmin?: boolean;
}

export const authInitialState: AuthState = {
  name: '',
  email: '',
  username: '',
  sector: '0',
  groups: [],
  token: '',
  role: '',
  permission: 2,
  isLoading: false,
  isLogged: false,
  isAdmin: false,
};

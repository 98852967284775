import { RequestService } from 'services/RequestService/RequestService';

import { Common } from 'utils';

import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { getPartnersDetailsUseCase } from './getPartnersDetailsUseCase';

const execute = async form => {
  try {
    partnersManagementEvent({ isLoadingEditCRM: true });
    const { selectedRow, requestDetails } = partnersManagementStore.getState();

    const arrQuestionsCRM: any = [];
    for (const obj of requestDetails) {
      const filteredQuestionsCRM = obj?.answers?.filter(
        item => item?.question?.questionType === 'UF_CRM',
      );
      for (const item of filteredQuestionsCRM) {
        arrQuestionsCRM.push(item);
      }
    }

    const newAnswersList = arrQuestionsCRM?.filter(Common.onlyUnique).map(it => ({
      questionId: it.question.id,
      questionCategory: it.question.questionCategory,
      questionType: it.question.questionType,
      answer: form?.uf_crm?.map(obj => `${obj.uf}-${obj.crm}`),
    }));

    const formattedPayload = {
      partnerCode: selectedRow?.partner?.cnpj_cpf,
      submitData: {
        userId: selectedRow?.users ? selectedRow?.users[0]?.id || null : null,
        answers: newAnswersList,
      },
    };

    await RequestService.changePartnerAnswers(formattedPayload);

    await Common.sleep(2000);
  } catch (err) {
    // console.log(err);
  } finally {
    getPartnersDetailsUseCase.execute();
    partnersManagementEvent({ isOpenDrawerEditCrm: false, isLoadingEditCRM: false });
  }
};

export const editCRMUseCase = { execute };

import { RequestService } from 'services/RequestService/RequestService';

import { Common } from 'utils';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';

import { ISearchPartners } from 'domains/partnersManagement/Partners.domain';

const execute = async (documentNumber: string) => {
  const isValidCnpj = Common.cnpjValidator(documentNumber);

  let formattedDocumentStr = '';
  if (isValidCnpj) {
    formattedDocumentStr = Common.reverseCNPJMask(documentNumber);
  } else {
    formattedDocumentStr = Common.reverseCPFMask(documentNumber);
  }

  RequestService.searchPartnerByDocumentNumber(formattedDocumentStr)
    .then((response: ISearchPartners) => {
      partnersManagementEvent({ searchPartnerResult: response });
    })
    .catch(() => {
      alertEvent({ open: true, text: 'Nenhum parceiro encontrado.', type: 'error' });
    });
};

export const searchPartnerByDocumentUseCase = { execute };

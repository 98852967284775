import api from 'clients/api/Api';

import { IQuestionResponse } from 'domains/question';

const getQuestions = async () => {
  const { data } = await api.get<IQuestionResponse>('cardio/Question/All');
  return data;
};

export const QuestionService = {
  getQuestions,
};

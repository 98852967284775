import { ProductsService } from 'services/ProductService/ProductService';

import { requestDynamicFormEvent } from 'stores/RequestDynamicForm/RequestDynamicFormEvents';

const execute = async (productId: string) => {
  try {
    requestDynamicFormEvent({ isLoading: true });
    const result = await ProductsService.getRequestDynamicForm(productId);
    requestDynamicFormEvent({ data: result });
  } catch (err) {
    // console.log(err)
  } finally {
    requestDynamicFormEvent({ isLoading: false });
  }
};

export const getRequestDynamicFormUseCase = { execute };

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ArrowForwardOutlined, CloseOutlined, FilterAltOffOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { useStore } from 'effector-react';
import { drawerStyles } from 'styles/global.styles';

import ToggleButtonInput from 'components/form/toggleButtonInput';
import { IMedicines } from 'domains/medicines';
import { MedicineService } from 'services/MedicineService/MedicineService';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import TextInput from 'components/form/textInputControl';
import SelectInput from 'components/form/selectInputControl';

export function DrawerFilters() {
  const [listMedicines, setListMedicines] = useState<IMedicines[]>([]);
  const { isOpenDrawerFilters, filters } = useStore(partnersManagementStore);

  const { control, handleSubmit, reset } = useForm({
    mode: 'all',
    resolver: yupResolver(
      Yup.object().shape({
        initiative: Yup.string().nullable(),
        cnpj: Yup.string().nullable(),
        medicineId: Yup.string().nullable(),
        sectorFilter: Yup.string().nullable(),
      }),
    ),
  });

  const onClose = () => partnersManagementEvent({ isOpenDrawerFilters: false, selectedRow: null });

  useEffect(() => {
    if (!filters)
      reset({
        cnpj: '',
        initiative: null,
        medicineId: '',
        sectorFilter: '',
      });
  }, [filters, reset]);

  useEffect(() => {
    const getOptionsData = async () => {
      const [medicineData] = await Promise.all([MedicineService.getMedicines(1, 99999)]);
      setListMedicines(medicineData.data?.medicines);
    };
    getOptionsData();
  }, []);

  return (
    <Drawer anchor="right" open={isOpenDrawerFilters} onClose={onClose}>
      <Stack p={4} direction="column" flex={1} sx={drawerStyles}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography fontSize={32} fontWeight={700} mr={6}>
            Filtros avançados
          </Typography>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Stack>

        <Box sx={{ pt: 4 }}>
          <Typography fontSize="14px" fontWeight={700}>
            LINHA/SETOR
          </Typography>
          <Divider />
          <div style={{ marginTop: '15px' }}>
            <TextInput control={control} placeholder="LINHA" name="sectorFilter" type="number" />
          </div>
        </Box>

        <Box sx={{ pt: 4 }}>
          <Typography fontSize="14px" fontWeight={700}>
            CNPJ
          </Typography>
          <Divider />
          <div style={{ marginTop: '15px' }}>
            <TextInput control={control} placeholder="CNPJ" name="cnpj" type="text" />
          </div>
        </Box>

        <Box sx={{ pt: 4 }}>
          <Typography fontSize="14px" fontWeight={700}>
            FAZ PARTE DA INICIATIVA BASE ZERO
          </Typography>
          <Divider />
          <ToggleButtonInput
            name="initiative"
            control={control}
            options={[
              { value: '0', label: 'Não' },
              { value: 'Base Zero', label: 'Sim' },
            ]}
          />
        </Box>

        <Box sx={{ pt: 4 }}>
          <Typography fontSize="14px" fontWeight={700}>
            MEDICAMENTO
          </Typography>
          <Divider />
          <div style={{ marginTop: '15px' }}>
            <SelectInput
              control={control}
              name="medicineId"
              defaultValue={null}
              label=""
              options={listMedicines
                ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                .map(item => ({
                  value: item.id,
                  label: item.name,
                }))}
            />
          </div>
        </Box>

        <div>
          <Stack sx={{ flexDirection: 'row', mt: 14, gap: 2 }}>
            <Button
              startIcon={<FilterAltOffOutlined color="inherit" />}
              onClick={() =>
                reset({
                  cnpj: '',
                  initiative: null,
                  medicineId: '',
                  sectorFilter: '',
                })
              }
              variant="outlined"
            >
              LIMPAR
            </Button>
            <Button
              onClick={handleSubmit(formData =>
                partnersManagementEvent({
                  pageNow: 1,
                  isOpenDrawerFilters: false,
                  filters: { ...filters, ...formData },
                }),
              )}
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardOutlined color="inherit" />}
            >
              APLICAR
            </Button>
          </Stack>
        </div>
      </Stack>
    </Drawer>
  );
}

/* eslint-disable react/jsx-filename-extension */
import { useDrag, useDrop } from 'react-dnd';

import { DragHandleOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

function CardQuestion({ children, order, onChange }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CARD',
    item: { order },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: 'CARD',
    drop: (droppedItem: any) => {
      if (!droppedItem) return;

      const draggedOrder = droppedItem?.order;
      const droppedOnOrder = order;

      if (draggedOrder !== droppedOnOrder) {
        onChange(draggedOrder, droppedOnOrder);
      }
    },
  }));

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div
      ref={node => {
        drag(drop(node));
      }}
      style={{
        padding: '16px 12px',
        backgroundColor: '#f8f9fd',
        marginTop: 18,
        border: '1px dashed #E4E4E4',
        borderRadius: 8,
        opacity,
        cursor: 'grab',
      }}
    >
      <div style={{ position: 'absolute', left: -4 }}>
        <Tooltip title="Clique e arraste para alterar a ordem" placement="top">
          <IconButton>
            <DragHandleOutlined color="inherit" />
          </IconButton>
        </Tooltip>
      </div>
      {children}
    </div>
  );
}

export default CardQuestion;

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import dayjs from 'dayjs';
// import { useStore } from 'effector-react';

import { CloseOutlined, EmojiFlagsOutlined, FmdGoodOutlined } from '@mui/icons-material';
import * as MUI from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Common } from 'utils';

import {
  closeButtonStyles,
  containerStyles,
  contentStyles,
  modalTitleStyles,
} from './EditValidityModal.styles';

interface IConfirmModal {
  handleCloseModal?: any;
  submitUseCase: any; // useCase
  isOpenEditValidityModal: boolean;
  isLoading?: boolean;
  title?: string;
  id?: string;
  startDate?: string;
  endDate?: string;
  confirmText: string;
  declineText?: string;
}

function EditValidityModal(props: IConfirmModal): JSX.Element {
  const { control, setValue, formState, setError, clearErrors, handleSubmit, watch, reset } =
    useForm({
      mode: 'all',
    });
  const startDate = watch('startDate');

  const validateEndDate = value => {
    const isEmpty =
      !value || (typeof value === 'object' && Object.keys(value).length === 0) || value === '';
    const doesNotStartWithFormat = typeof value === 'string' && !value.startsWith('DD/MM/YYYY');

    const newVal = dayjs(value).isValid() ? value : dayjs().subtract(1, 'year');
    if (isEmpty || doesNotStartWithFormat || newVal.isBefore(startDate)) {
      return true;
    }
    return false;
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [disableButton, setDisableButton] = useState<boolean>(true);
  useEffect(() => {
    setDisableButton(true);
    setValue('formId', props.id);
    setValue(
      'startDate',
      dayjs(
        Common.convertDateFormatToEUA(
          props?.startDate === '' ? dayjs().format('DD/MM/YYYY') : props?.startDate,
        ),
      ),
    );
    setValue(
      'endDate',
      props?.endDate ? dayjs(Common.convertDateFormatToEUA(props?.endDate)) : null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUI.Modal
        open={props.isOpenEditValidityModal}
        onClose={() => {
          clearErrors();
          props.handleCloseModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MUI.Box sx={containerStyles}>
          <MUI.Box sx={contentStyles}>
            <MUI.IconButton
              sx={closeButtonStyles}
              onClick={() => {
                clearErrors();
                props.handleCloseModal();
              }}
            >
              <CloseOutlined />
            </MUI.IconButton>
            <Controller
              name="formId"
              control={control}
              render={({ field: { name, value } }) => {
                return (
                  <MUI.TextField
                    name={name}
                    hidden
                    value={value}
                    aria-hidden
                    hiddenLabel
                    sx={{ display: 'none' }}
                  />
                );
              }}
            />

            <MUI.Stack direction="column">
              {!!props?.title && (
                <MUI.Typography id="modal-modal-title" sx={modalTitleStyles}>
                  {props.title}
                </MUI.Typography>
              )}
              <MUI.Stack direction="column" justifyContent="center">
                <MUI.Stack direction="row" mt={2} alignItems="center">
                  <FmdGoodOutlined fontSize="small" sx={{ color: '#1D8F5E' }} />
                  <MUI.Typography fontSize="14px">Data inicial</MUI.Typography>
                </MUI.Stack>

                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { name, value } }) => (
                    <DatePicker
                      value={value}
                      format="DD/MM/YYYY"
                      readOnly
                      onChange={newValue => setValue(name, newValue)}
                    />
                  )}
                />
                <MUI.Stack direction="row" alignItems="center" mt={2}>
                  <EmojiFlagsOutlined fontSize="small" sx={{ color: '#005391' }} />
                  <MUI.Typography fontSize="14px">Data final</MUI.Typography>
                </MUI.Stack>
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { name, value } }) => (
                    <>
                      <DatePicker
                        value={value}
                        format="DD/MM/YYYY"
                        onChange={newValue => {
                          if (validateEndDate(newValue)) {
                            setDisableButton(true);
                            setError('endDate', {
                              type: 'custom',
                              message: '',
                            });
                          } else {
                            setDisableButton(false);
                            clearErrors('endDate');
                          }
                          setValue(name, newValue);
                        }}
                      />
                      {formState.errors.endDate && (
                        <p style={{ color: 'red', fontSize: '14px' }}>
                          A data final deve ser maior que a data inicial.
                        </p>
                      )}
                    </>
                  )}
                />
              </MUI.Stack>

              <MUI.Stack direction="row" gap={4} justifyContent="center">
                <MUI.Button
                  variant="contained"
                  color="primary"
                  sx={{ minWidth: 100, mt: 4 }}
                  disabled={disableButton}
                  onClick={() => {
                    handleSubmit(async data => {
                      const result = await props.submitUseCase(data);
                      if (result) {
                        reset();
                      }
                    })();
                  }}
                >
                  {props?.isLoading ? (
                    <MUI.CircularProgress color="inherit" size="25px" />
                  ) : (
                    props.confirmText
                  )}
                </MUI.Button>
                {!!props?.declineText && (
                  <MUI.Button
                    variant="contained"
                    color="error"
                    sx={{ minWidth: 100, mt: 4 }}
                    onClick={() => {
                      clearErrors();
                      props.handleCloseModal();
                    }}
                  >
                    {props?.declineText}
                  </MUI.Button>
                )}
              </MUI.Stack>
            </MUI.Stack>
          </MUI.Box>
        </MUI.Box>
      </MUI.Modal>
    </LocalizationProvider>
  );
}

export default EditValidityModal;

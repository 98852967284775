import fetchJsonp from 'jsonp';

import ICnpjInfo from 'domains/receita';

const getCnpjInfo = async (cnpj: string): Promise<ICnpjInfo> => {
  return new Promise((resolve, reject) => {
    fetchJsonp(`https://receitaws.com.br/v1/cnpj/${cnpj}`, null, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export const ReceitaService = {
  getCnpjInfo,
};

/* eslint-disable no-plusplus */
import { CompaignsService } from 'services/CompaignService/CompaignService';
import { ProductsService } from 'services/ProductService/ProductService';
import { QuestionService } from 'services/QuestionService/QuestionService';

import { Common } from 'utils';

import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';
import productManagementStore from 'stores/ProductManagement/ProductManagementStore';

import { QuestionCategoryEnum } from 'domains/question';

const execute = async (id: string) => {
  try {
    productManagementEvent({ isLoading: true });

    ProductsService.getProductById(id).then(async data => {
      productManagementEvent({ dataProduct: data });
      const { dataProduct } = productManagementStore.getState();
      const [questions, listEditCompaign] = await Promise.all([
        QuestionService.getQuestions(),
        CompaignsService.getAllCompaigns(),
      ]);
      /* eslint-disable prefer-const */
      const formattedArrayQuestions = questions?.data?.questions?.map(obj => {
        const dataFilter = dataProduct?.data?.productQuestions.find(item => {
          return item?.question?.id === obj?.id;
        });

        // if (dataFilter) {
        //   const existingOrder = questions?.data?.questions?.some(
        //     item => item.order === dataFilter.order,
        //   );

        //   if (!existingOrder) {
        //     obj.order = dataFilter.order;
        //   } else {
        //     let nextOrder = dataFilter.order + 1;
        //     // eslint-disable-next-line no-loop-func
        //     while (questions?.data?.questions?.some(item => item.order === nextOrder)) {
        //       // eslint-disable-next-line no-plusplus
        //       nextOrder++;
        //     }
        //     obj.order = nextOrder;
        //   }
        // } else {
        //   obj.order = idx;
        // }
        return {
          ...obj,
          isActive: !!dataFilter,
          order: dataFilter?.order ?? null,
        };
      });
      const withOrder = formattedArrayQuestions.filter(item => item.order !== null);

      const withNoOrder = formattedArrayQuestions.filter(item => item.order === null);

      const orderDefined = new Set(withOrder.map(item => item.order));

      let nextOrder = Math.max(...orderDefined, 0) + 1;

      withNoOrder.forEach(item => {
        while (orderDefined.has(nextOrder)) {
          nextOrder++;
        }
        item.order = nextOrder++;
      });

      const listIdentificationEditQuestions = formattedArrayQuestions?.filter(
        obj => obj.questionCategory === QuestionCategoryEnum.IDENTIFICATION,
      );
      const listClientEditQuestions = formattedArrayQuestions?.filter(
        obj => obj.questionCategory === QuestionCategoryEnum.CLIENT,
      );
      const listNegotiationEditQuestions = formattedArrayQuestions?.filter(
        obj => obj.questionCategory === QuestionCategoryEnum.NEGOTIATION,
      );
      const listShippingEditQuestions = formattedArrayQuestions?.filter(
        obj => obj.questionCategory === QuestionCategoryEnum.SHIPPING,
      );

      const lastOrder = [...formattedArrayQuestions]?.pop()?.order;

      const formattedListCompaign = listEditCompaign?.data?.campaigns?.map((obj, index) => {
        const dataFilterCampaign = dataProduct?.data?.campaigns.find(item => {
          return item?.id === obj?.id;
        });

        return {
          ...obj,
          isActive: !!dataFilterCampaign,
          order: 1 + (lastOrder ? Number(lastOrder) : 1) + index,
        };
      });

      await Common.sleep(1000);
      productManagementEvent({
        listIdentificationEditQuestions,
        listClientEditQuestions,
        listNegotiationEditQuestions,
        listShippingEditQuestions,
        listEditCompaign: formattedListCompaign,
      });
    });
  } catch (err) {
    // console.log(err)
  } finally {
    productManagementEvent({ isLoading: false });
  }
};

export const getProductManagementEditUseCase = { execute };

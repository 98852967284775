/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';

import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useStore } from 'effector-react';

import {
  FileDownloadOutlined,
  FilterAltOffOutlined,
  FilterListOutlined,
  HistoryOutlined,
  ListOutlined,
  SearchOutlined,
} from '@mui/icons-material';
import { Button, CircularProgress, MenuItem, Select, Stack, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { exportPartnersUseCase } from 'useCases/partnersManagement/exportPartnersUseCase';

import { ProductsService } from 'services/ProductService/ProductService';

import { Formatter } from 'utils';

import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { IProduct } from 'domains/product';

import DropDown from '../dropdown/DropDown';

import 'dayjs/locale/pt-br';

dayjs.locale('pt-br');

function FiltersPartner() {
  const { isLoadingExport, filters } = useStore(partnersManagementStore);
  const [status, setStatus] = useState('');
  const [productId, setProductId] = useState('');
  const [dateStart, setDateStart] = useState<Date | null>(null);
  const [dateEnd, setDateEnd] = useState<Date | null>(null);
  const [listProducts, setListProducts] = useState<IProduct[]>([]);

  const handleFilter = () => {
    const formattedStartDate = format(new Date(dateStart), 'yyyy-MM-dd');
    const formattedEndDate = format(new Date(dateEnd), 'yyyy-MM-dd');
    const filter = {
      StartValidity: !dateStart ? null : formattedStartDate,
      EndValidity: !dateEnd ? null : formattedEndDate,
      Status: !status ? null : status,
      ProductId: !productId ? null : productId,
      sectorFilter: filters?.sectorFilter || null,
      medicineId: filters?.medicineId || null,
      initiative: filters?.initiative || null,
      cnpj: filters?.cnpj || null,
    };
    partnersManagementEvent({ filters: filter });
  };

  useEffect(() => {
    const getOptionsData = async () => {
      const [productsData] = await Promise.all([ProductsService.getAllProducts()]);
      setListProducts(productsData.data?.products);
    };
    getOptionsData();
  }, []);

  return (
    <Stack flex={1} py={2} mb={2} mt={5} gap={2} flexDirection="row" justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap={1}>
        <Stack direction="row" alignItems="center" display="contents">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                bgcolor: '#fff',
                '& .MuiInputBase-input': { height: '22px', fontSize: '14px' },
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  style: {
                    width: '180px',
                  },
                },
              }}
              format="DD/MM/YYYY"
              value={dateStart}
              onChange={v => setDateStart(v)}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                bgcolor: '#fff',
                '& .MuiInputBase-input': { height: '22px', fontSize: '14px' },
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  style: {
                    width: '180px',
                  },
                },
              }}
              format="DD/MM/YYYY"
              value={dateEnd}
              minDate={dateStart}
              onChange={v => setDateEnd(v)}
            />
          </LocalizationProvider>
        </Stack>
        <Select
          displayEmpty
          sx={{
            width: '195px',
            height: '40px',
            fontSize: '16px',
            backgroundColor: 'white',
            '& .MuiSelect-select': { color: productId !== '' ? 'black' : '#ccc' },
          }}
          renderValue={productId !== '' ? undefined : () => 'Diagnóstico'}
          labelId="combo-product"
          id="combo-product"
          value={productId}
          onChange={v => setProductId(v.target.value?.toString())}
        >
          {listProducts
            ?.sort((a, b) => a?.name?.localeCompare(b?.name))
            .map(obj => (
              <MenuItem value={obj.id} key={obj.id}>
                <Typography fontSize="16px">{Formatter.truncate(obj.name, 15)}</Typography>
              </MenuItem>
            ))}
        </Select>

        <Select
          displayEmpty
          sx={{
            width: '128px',
            height: '40px',
            fontSize: '16px',
            backgroundColor: 'white',
            '& .MuiSelect-select': { color: status !== '' ? 'black' : '#ccc' },
          }}
          renderValue={status !== '' ? undefined : () => 'Status'}
          labelId="demo-simple-select-label"
          placeholder="Status"
          id="demo-simple-select"
          value={status}
          onChange={v => setStatus(v.target.value?.toString())}
        >
          {[
            { value: 'Active', label: 'Vigente' },
            { value: 'Done', label: 'Encerrado' },
          ].map(obj => (
            <MenuItem key={obj.value} value={obj.value}>
              <Typography fontSize="16px">{obj.label}</Typography>
            </MenuItem>
          ))}
        </Select>
        <Stack direction="row">
          <Button
            onClick={() => {
              setDateStart(null);
              setDateEnd(null);
              setProductId('');
              setStatus('');
              partnersManagementEvent({ filters: null });
            }}
            variant="contained"
            color="inherit"
          >
            <FilterAltOffOutlined />
          </Button>
          <Button
            sx={{ ml: 1 }}
            onClick={handleFilter}
            variant="contained"
            disabled={!!dateStart && !dateEnd}
          >
            <SearchOutlined />
          </Button>
        </Stack>
        <Button
          onClick={() => partnersManagementEvent({ isOpenDrawerFilters: true })}
          color="primary"
          variant="outlined"
          startIcon={<FilterListOutlined />}
          sx={{ bgcolor: '#fff', ml: 1 }}
        >
          Filtros
        </Button>
      </Stack>
      <Stack alignItems="center" direction="row">
        <DropDown
          // eslint-disable-next-line react/no-unstable-nested-components
          customButton={onPress => (
            <Button
              onClick={onPress}
              variant="contained"
              disabled={isLoadingExport}
              startIcon={
                isLoadingExport ? <CircularProgress size="22px" /> : <FileDownloadOutlined />
              }
            >
              EXPORTAR
            </Button>
          )}
          options={[
            {
              id: '1',
              name: 'Gestão de parcerias',
              icon: <ListOutlined color="primary" />,
              action: () => exportPartnersUseCase.execute('partners'),
              visible: true,
            },
            {
              id: '2',
              name: 'Movimentações de parcerias',
              icon: <HistoryOutlined color="error" />,
              action: () => exportPartnersUseCase.execute('history'),
              visible: true,
            },
          ]}
        />
      </Stack>
    </Stack>
  );
}

export default FiltersPartner;

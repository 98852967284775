import { ReceitaService } from 'services/ReceitaService/ReceitaService';

import { Common } from 'utils';

import { formDynamicFormEvent } from 'stores/DynamicForm/FormDynamicFormEvents';

const execute = async (cnpj: string) => {
  try {
    const isValidCnpj = Common.cnpjValidator(cnpj);
    if (!isValidCnpj) return;
    const formattedCnpj = Common.reverseCNPJMask(cnpj);
    const result = await ReceitaService.getCnpjInfo(formattedCnpj);
    formDynamicFormEvent({ cnpjInfo: result });
  } catch (err) {
    console.log(err);
  }
};

export const getCnpjInfoUseCase = { execute };

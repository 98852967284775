interface IParams {
  data: any;
  questionCategoryList: string[];
  cbSetValue: (inputName: string) => void;
}

const execute = async (params: IParams) => {
  if (!params?.data || !params) return;
  const sectionIdentification = params?.data?.productQuestions?.filter(
    question => question?.question?.questionCategory === params?.questionCategoryList[0],
  );
  if (!sectionIdentification) return;
  const sectorInput = sectionIdentification?.find(obj => obj?.question?.questionType === 'Sector');
  const inputName = `${sectorInput.question.id}__${sectorInput.question.questionType}__${sectorInput.question.questionCategory}`;
  params?.cbSetValue(inputName);
};

export const loadSectorNameInputUseCase = { execute };

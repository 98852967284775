import * as React from 'react';

import { theme } from 'styles/theme';

import { MotionPhotosAutoOutlined } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { dropDownItemStyle } from './DropDownConfig.styles';

interface IDropDownProps {
  options: IDropDownItem[];
}

interface IDropDownItem {
  id: string | number;
  name: string;
  icon: JSX.Element;
  visible: boolean;
  action?: () => void | null;
}

export default function DropDownConfig(props: IDropDownProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = async (item: IDropDownItem) => {
    if (item?.id && item?.id === 5) {
      item.action();
    } else if (item?.action) {
      item.action();
    }
    handleClose();
  };

  const filteredVisibleOptions = props?.options?.filter(obj => obj.visible);

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MotionPhotosAutoOutlined sx={{ color: theme.palette.secondary.main, fontSize: '26px' }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {filteredVisibleOptions?.length === 0 ? (
          <MenuItem onClick={handleClose}>
            <Typography fontSize={14} fontWeight="500">
              Nenhuma ação disponível
            </Typography>
          </MenuItem>
        ) : (
          <>
            {filteredVisibleOptions.map((obj, i) => (
              <MenuItem
                key={obj.name}
                onClick={() => onSelect(obj)}
                // eslint-disable-next-line no-unsafe-optional-chaining
                sx={i !== filteredVisibleOptions?.length - 1 && dropDownItemStyle}
              >
                <Stack direction="row" gap={2} alignItems="center">
                  {obj.icon}
                  <Typography fontSize={14} fontWeight="500">
                    {obj.name}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </>
        )}
      </Menu>
    </div>
  );
}

import * as Yup from 'yup';

import { Box, Button, CircularProgress } from '@mui/material';

import { keycloak } from '../../keycloak';
import { cardLoginStyle, containerStyle } from './LoginPage.styles';

export const validators = Yup.object().shape({
  username: Yup.string().required('* Digite seu usuário'),
  password: Yup.string().required('* Digite sua senha'),
});

export default function LoginPage(): JSX.Element {
  return (
    <Box sx={containerStyle}>
      <Box sx={cardLoginStyle}>
        <Box sx={{ mb: 2 }}>
          <img src="/assets/logo-cardio.webp" alt="Logo Cardio 360" width={264} />
        </Box>
        <Button
          sx={{ mt: 2 }}
          size="large"
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => {
            keycloak.login();
          }}
        >
          <CircularProgress size="23px" />
          &nbsp;&nbsp;Carregando
        </Button>
      </Box>
    </Box>
  );
}

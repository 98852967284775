import { RequestService } from 'services/RequestService/RequestService';

import { Common } from 'utils';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { getPartnersDetailsUseCase } from './getPartnersDetailsUseCase';

interface IParams {
  serialList: {
    id: string | null;
    serialNumber: string;
  }[];
}

const execute = async (params: IParams) => {
  try {
    partnersManagementEvent({ isLoadingDrawerSerialNumber: true });
    const { sector } = authStore.getState();
    const { detailsDrawerSerialNumber } = partnersManagementStore.getState();
    const requestId = detailsDrawerSerialNumber?.id;
    const payload = {
      userSector: sector,
      equipments: params?.serialList?.map(item => ({
        id: item.id.includes('appId') ? null : item.id,
        serialNumber: item.serialNumber || 'N/A',
      })),
    };

    await RequestService.changeEquipmentSerialNumber(requestId, payload);
    await Common.sleep(2000);
    await getPartnersDetailsUseCase.execute();
    alertEvent({ open: true, type: 'success', text: 'Sucesso ao atualizar números de série.' });
  } catch (err: any) {
    const errorMessage =
      err?.response?.data?.error || 'Ocorreu um erro, tente novamente mais tarde.';
    alertEvent({
      open: true,
      type: 'error',
      text: errorMessage,
    });
  } finally {
    partnersManagementEvent({
      isLoadingDrawerSerialNumber: false,
      isOpenDrawerSerialNumber: false,
      detailsDrawerSerialNumber: null,
      isOpenDrawerDetails: true,
    });
  }
};

export const changeSerialNumberUseCase = { execute };

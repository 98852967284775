import { RequestService } from 'services/RequestService/RequestService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';

import { StatusEnum } from 'domains/status';

import { getRequestManagementUseCase } from './getRequestManagementUseCase';

interface IParamsChangeStatusUseCase {
  id: string;
  status: StatusEnum;
  observation?: string;
}

const execute = async (params: IParamsChangeStatusUseCase) => {
  try {
    const { sector } = authStore.getState();
    const response = await RequestService.changeStatus({ ...params, userSector: sector });
    const alertMessage = response?.error || 'Status alterado com sucesso.';
    alertEvent({
      open: true,
      type: 'success',
      text: alertMessage,
    });
  } catch (err) {
    alertEvent({
      open: true,
      type: 'error',
      text:
        err?.response?.data?.error ||
        'Não foi possível alterar o status no momento, tente mais tarde.',
    });
  } finally {
    getRequestManagementUseCase.execute();
  }
};

export const changeStatusRequestUseCase = { execute };

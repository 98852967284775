/* eslint-disable prettier/prettier */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-curly-brace-presence */
import { Box, Divider, Drawer, Grid, Stack, Typography } from '@mui/material';

import StepsStatus from 'components/ui/steps';

import { Common } from 'utils';

interface RequestManagementDrawerDetailProps {
  isOpen: boolean;
  data: any;
  onClose: () => void;
}

export default function RequestManagementDrawerDetail({
  isOpen,
  onClose,
  data,
}: RequestManagementDrawerDetailProps): JSX.Element {
  const questionsWithSectionInfo1 = data?.data?.answers
    .filter(question => question.question.questionCategory === 'Identification')
    .sort((a, b) => a.question?.order - b.question?.order);
  const questionsWithSectionInfo2 = data?.data?.answers
    .filter(question => question.question.questionCategory === 'Client')
    .sort((a, b) => a.question?.order - b.question?.order);
  const questionsWithSectionInfo3 = data?.data?.answers
    .filter(question => question.question.questionCategory === 'Negotiation')
    .sort((a, b) => a.question?.order - b.question?.order);
  const questionsWithSectionInfo4 = data?.data?.answers
    .filter(question => question.question.questionCategory === 'Shipping')
    .sort((a, b) => a.question?.order - b.question?.order);

  const campaignSectionInfo = data?.data?.campaignsAnswers.sort(
    (a, b) => a.question?.order - b.question?.order,
  );

  function formatTextAddress(val: string) {
    const replaceDoubleHiffen = val[0]?.replace('__', '_ _');
    const brkLineAddress = replaceDoubleHiffen.split('_');

    return (
      <div>
        {`${brkLineAddress[1]} ${brkLineAddress[3]} ${brkLineAddress[2]}`} <br />
        {` ${brkLineAddress[5]} ${brkLineAddress[4]}`} <br />
        {`${brkLineAddress[0]}`}
      </div>
    );
  }
  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Stack
        flex={1}
        px={2}
        width="500px"
        sx={{ borderRadius: '10px 0 0 10px !important', backgroundColor: 'white' }}
      >
        <Box>
          <Typography fontSize="32px" fontWeight="bold" mb={1} mt={2}>
            Solicitação de Serviço
          </Typography>
        </Box>
        <Box>
          <Typography fontSize="16px" mb={4}>
            Aqui você pode acompanhar os status da sua solicitação, além de ver as informações
            detalhadas e editá-las caso necessário.
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" fontWeight="700" mt={2} mb={1}>
            ACOMPANHAMENTO DA SOLICITAÇÃO
          </Typography>
          <Divider style={{ marginBottom: '10px' }} />
          {!!data && (
            <Box sx={{ width: '100%' }} my={5}>
              <StepsStatus status={data.data.statusId} date={data?.data?.status} />
            </Box>
          )}
          <Stack width="100%">
            {questionsWithSectionInfo1?.length > 0 && (
              <Box px={0.6} mb={1}>
                <Typography variant="caption" fontWeight="700" mt={2} mb={1}>
                  INFORMAÇÕES DO SOLICITANTE
                </Typography>
                <Divider />
              </Box>
            )}
            <Grid container>
              {questionsWithSectionInfo1?.map(item => {
                return (
                  <Grid item xs={6} mb={2} p={0.8}>
                    <Typography fontSize="14px">{item?.question?.description}:</Typography>
                    <Stack flexDirection="row">
                      <Typography fontSize="12px" fontWeight="bold">
                        {Common.formatTextList(item?.answer)}
                      </Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
            {questionsWithSectionInfo2?.length > 0 && (
              <Box width="100%" px={0.8} mb={1}>
                <Typography variant="caption" fontWeight="700" mt={2} mb={1}>
                  INFORMAÇÕES DA CLÍNICA/MÉDICO
                </Typography>
                <Divider />
              </Box>
            )}
            <Grid container>
              {questionsWithSectionInfo2?.map(item => {
                return (
                  <Grid item xs={6} mb={2} p={0.8}>
                    <Typography fontSize="14px"> {item?.question?.description}:</Typography>
                    <Stack flexDirection="row">
                      <Typography fontSize="12px" fontWeight="bold">
                        {Common.formatTextList(item?.answer, item?.question.questionType)}
                      </Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
            {questionsWithSectionInfo3?.length > 0 && (
              <Box width="100%" px={0.8} mb={1}>
                <Typography variant="caption" fontWeight="700" mt={2} mb={1}>
                  INFORMAÇÕES DA PARCERIA
                </Typography>
                <Divider />
              </Box>
            )}
            <Grid container>
              {questionsWithSectionInfo3?.map(item => {
                return (
                  <Grid item xs={6} mb={2} p={0.8}>
                    <Typography fontSize="14px"> {item?.question?.description}:</Typography>
                    <Stack flexDirection="row">
                      <Typography fontSize="12px" fontWeight="bold">
                        {Common.formatTextList(item?.answer)}
                      </Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
            {questionsWithSectionInfo4?.length > 0 && (
              <Box width="100%" px={0.8} mb={1}>
                <Typography variant="caption" fontWeight="700" mt={2} mb={1}>
                  INFORMAÇÕES DE ENTREGA (SOLICITANTE EMS)
                </Typography>
                <Divider />
              </Box>
            )}
            <Grid container>
              {questionsWithSectionInfo4?.map(item => {
                return (
                  <Grid item xs={6} mb={2} p={0.8}>
                    <Typography fontSize="14px"> {item?.question?.description}:</Typography>
                    <Stack flexDirection="row">
                      <Typography fontSize="12px" fontWeight="bold">
                        {item?.question?.questionType === 'Address'
                          ? formatTextAddress(item?.answer)
                          : Common.formatTextList(item?.answer)}
                      </Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
            {campaignSectionInfo?.length > 0 && (
              <Box width="100%" px={0.8} mb={1}>
                <Typography variant="caption" fontWeight="700" mt={2} mb={1}>
                  INFORMAÇÕES DA CAMPANHA
                </Typography>
                <Divider />
              </Box>
            )}
            <Grid container>
              {campaignSectionInfo?.map(item => {
                return (
                  <Grid item xs={6} mb={2} p={0.8}>
                    <Typography fontSize="14px"> {item?.campaign?.description}:</Typography>
                    <Stack flexDirection="row">
                      <Typography fontSize="12px" fontWeight="bold">
                        <span>{item.answer}</span>
                      </Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Box>
      </Stack>
    </Drawer>
  );
}

import api from 'clients/api/Api';

import { IMedicinesResponse } from 'domains/medicines';

const getMedicines = async (page = 1, size = 10) => {
  const { data } = await api.get<IMedicinesResponse>('cardio/Medicine/All', {
    params: { page, size },
  });
  return data;
};

const createMedicine = async (name: string) => {
  const { data } = await api.post('cardio/Medicine', {
    name,
  });
  return data;
};

const changeStatusMedicine = async (id: string, isActive: boolean) => {
  const { data } = await api.put(`cardio/Medicine/ChangeStatus/${id}`, { isActive });
  return data;
};

export const MedicineService = {
  getMedicines,
  createMedicine,
  changeStatusMedicine,
};

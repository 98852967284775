import { Box, Skeleton, Stack } from '@mui/material';

export default function SkeletonTableLoading(): JSX.Element {
  return (
    <Box sx={{ height: 380, width: '100%' }}>
      <Box sx={{ position: 'absolute', width: '95%', mx: 2, mt: 2 }}>
        <Stack justifyContent="center" alignItems="center" width="100%" ml={2}>
          <Skeleton animation="wave" sx={{ height: 30, width: '100%', borderRadius: 3 }} />
          <Skeleton animation="wave" sx={{ height: 30, width: '100%', borderRadius: 3 }} />
          <Skeleton animation="wave" sx={{ height: 30, width: '100%', borderRadius: 3 }} />
          <Skeleton animation="wave" sx={{ height: 30, width: '100%', borderRadius: 3 }} />
          <Skeleton animation="wave" sx={{ height: 30, width: '100%', borderRadius: 3 }} />
          <Skeleton animation="wave" sx={{ height: 30, width: '100%', borderRadius: 3 }} />
          <Skeleton animation="wave" sx={{ height: 30, width: '100%', borderRadius: 3 }} />
          <Skeleton animation="wave" sx={{ height: 30, width: '100%', borderRadius: 3 }} />
          <Skeleton animation="wave" sx={{ height: 30, width: '100%', borderRadius: 3 }} />
          <Skeleton animation="wave" sx={{ height: 30, width: '100%', borderRadius: 3 }} />
          <Skeleton animation="wave" sx={{ height: 30, width: '100%', borderRadius: 3 }} />
        </Stack>
      </Box>
    </Box>
  );
}

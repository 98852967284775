import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import DrawerNotifications from 'components/ui/drawerNotifications/DrawerNotifications';
import SnackBarComponent from 'components/ui/snackBar/SnackBar';

import { loginSSOUseCase } from 'useCases/Auth/loginSSOUseCase';

import { keycloak, keycloakInitOptions } from './keycloak';
import Routes from './Routes';
import { theme } from './styles/theme';

export default function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      autoRefreshToken={false}
      onTokens={v => {
        if (v?.token) {
          loginSSOUseCase.execute({
            token: v.token,
          });
        }
      }}
      initOptions={{
        ...keycloakInitOptions,
      }}
    >
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes />
          <DrawerNotifications />
          <SnackBarComponent />
        </ThemeProvider>
      </DndProvider>
    </ReactKeycloakProvider>
  );
}

/* eslint-disable react/destructuring-assignment */

import { useEffect, useState } from 'react';

import { useStore } from 'effector-react';

import { ArrowForwardOutlined, CloseOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material';

import TextInput from 'components/form/textInput';
import { ButtonBack } from 'components/ui/buttonBack/ButtonBack';
import ConfirmModal from 'components/ui/confirmModal/ConfirmModal';

import { changeReportQtyUseCase } from 'useCases/partnersManagement/changeReportQtyUseCase';

import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

function DrawerPartnerReportQty() {
  const { isOpenDrawerPartnerReportQty, isLoadingReportQty, isOpenDrawerPartnerReportQtyModal } =
    useStore(partnersManagementStore);

  const [qty, setQty] = useState('');

  const onClose = () => {
    partnersManagementEvent({ isOpenDrawerPartnerReportQty: false, isOpenDrawerDetails: true });
  };

  useEffect(() => {
    setQty('');
  }, []);

  return (
    <>
      <Drawer anchor="right" open={isOpenDrawerPartnerReportQty} onClose={onClose}>
        <Stack
          p={4}
          direction="column"
          flex={1}
          sx={{
            width: '640px',
            backgroundColor: 'white',
            paddingBottom: 5,
            borderRadius: '15px 0 0 15px',
          }}
        >
          <Stack mt={2} pb={1} direction="row" justifyContent="space-between" alignItems="center">
            <ButtonBack text="VOLTAR" onBack={onClose} />
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Stack>
          <Typography fontSize={22} fontWeight={700} pb={6}>
            Quantidade de laudos
          </Typography>

          <Typography fontSize={14} fontWeight={700} mt={1}>
            ALTERAR QUANTIDADE DE LAUDOS
          </Typography>
          <Divider />
          <Box mt={2}>
            <TextInput
              maxLength={9}
              label="Quantidade"
              placeholder="Digite aqui"
              value={qty}
              onChange={e => {
                const val = e.target.value;
                setQty(val);
              }}
            />
          </Box>

          <Button
            variant="contained"
            sx={{ width: 250, mt: 8 }}
            endIcon={<ArrowForwardOutlined color="inherit" />}
            onClick={() => partnersManagementEvent({ isOpenDrawerPartnerReportQtyModal: true })}
          >
            ALTERAR
          </Button>
        </Stack>
      </Drawer>
      <ConfirmModal
        open={isOpenDrawerPartnerReportQtyModal}
        setOpen={v => partnersManagementEvent({ isOpenDrawerPartnerReportQtyModal: v })}
        title="Atenção"
        text="Tem certeza que deseja alterar a quantidade de laudos?"
        onConfirm={() =>
          changeReportQtyUseCase.execute({
            quantity: qty,
            reset: () => setQty(''),
          })
        }
        isLoading={isLoadingReportQty}
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
    </>
  );
}

export default DrawerPartnerReportQty;

import { ProductsService } from 'services/ProductService/ProductService';

import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';
import productManagementStore from 'stores/ProductManagement/ProductManagementStore';

const execute = async (id: string) => {
  try {
    productManagementEvent({ isLoading: true });

    ProductsService.getProductById(id).then(async data => {
      productManagementEvent({ dataProduct: data });
      const { dataProduct } = productManagementStore.getState();

      const listIdentificationAutomationQuestions = dataProduct?.data?.productQuestions
        .filter(question => question.question.questionCategory === 'Identification')
        .map(obj => ({
          ...obj,
          isActive: true,
          order: obj.order,
        }))
        .sort((a, b) => (a?.order < b?.order ? -1 : 1));

      const listClientAutomationQuestions = dataProduct?.data?.productQuestions
        .filter(question => question.question.questionCategory === 'Client')
        .map(obj => ({
          ...obj,
          isActive: true,
          order: obj.order,
        }))
        .sort((a, b) => (a?.order < b?.order ? -1 : 1));

      const listNegotiationAutomationQuestions = dataProduct?.data?.productQuestions
        .filter(question => question.question.questionCategory === 'Negotiation')
        .map(obj => ({
          ...obj,
          isActive: true,
          order: obj.order,
        }))
        .sort((a, b) => (a?.order < b?.order ? -1 : 1));

      const listShippingAutomationQuestions = dataProduct?.data?.productQuestions
        .filter(question => question.question.questionCategory === 'Shipping')
        .map(obj => ({
          ...obj,
          isActive: true,
          order: obj.order,
        }))
        .sort((a, b) => (a?.order < b?.order ? -1 : 1));

      productManagementEvent({
        listIdentificationAutomationQuestions,
        listClientAutomationQuestions,
        listNegotiationAutomationQuestions,
        listShippingAutomationQuestions,
      });
    });
  } catch (err) {
    // console.log(err)
  } finally {
    productManagementEvent({ isLoading: false });
  }
};

export const getAutomationProductManagementUseCase = { execute };

import { useState } from 'react';

import { styled } from '@mui/material/styles';

import Header from 'components/ui/header';
import Sidebar from 'components/ui/sidebar';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 28;

export function Layout({ children }) {
  const [open, setOpen] = useState(false);
  return (
    <StyledRoot
      style={{
        backgroundImage: `url("/assets/background-lines.png")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Header onOpenNav={() => setOpen(true)} />
      <Sidebar onCloseNav={() => setOpen(false)} openNav={open} />
      <Main
        style={{
          height: '100vh',
          background: 'transparent',
          backgroundSize: 'cover',
        }}
      >
        {children}
      </Main>
    </StyledRoot>
  );
}

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  background: `linear-gradient(to bottom, #FAFAFA, #f9f3f3, #f7f8fb, #f6fbf5)`,
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 14,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 14,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

import {
  DeleteOutlineOutlined,
  LocalShippingOutlined,
  StopCircleOutlined,
  ThumbUp,
} from '@mui/icons-material';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAllOutlined';
import SendIcon from '@mui/icons-material/Send';
import { Box, Stack, Typography } from '@mui/material';

import { StatusType } from 'domains/status';

import {
  activeBoxColor,
  approvedBoxColor,
  backMatrixBoxColor,
  boxIcon,
  boxTxt,
  closedBoxColor,
  deletedBoxColor,
  pendingBoxColor,
  recusedBoxColor,
  sendBoxColor,
  txtColor,
} from './styles';

export function StatusCard({ statusCode }: StatusType) {
  const statusInfo = {
    InAnalysis: {
      color: pendingBoxColor,
      text: 'Pendente',
      icon: <AccessAlarmsIcon sx={{ color: '#d3912f', fontSize: 18 }} />,
    },
    Active: {
      color: activeBoxColor,
      text: 'Concluída',
      icon: <DoneAllIcon sx={{ color: '#3b98e5', fontSize: 18 }} />,
    },
    Approved: {
      color: approvedBoxColor,
      text: 'Aprovado',
      icon: <DoneIcon sx={{ color: '#1d8f5e', fontSize: 18 }} />,
    },
    Reproved: {
      color: recusedBoxColor,
      text: 'Recusada',
      icon: <CloseIcon sx={{ color: '#e7002a', fontSize: 18 }} />,
    },
    SentSuplyer: {
      color: sendBoxColor,
      text: 'Enviado',
      icon: <SendIcon sx={{ color: '#005391' }} />,
    },
    SentMatrix: {
      color: sendBoxColor,
      text: 'Enviado',
      icon: <SendIcon sx={{ color: '#005391' }} />,
    },
    BackMatrix: {
      color: backMatrixBoxColor,
      text: 'Voltando',
      icon: <LocalShippingOutlined sx={{ color: '#F49758' }} />,
    },
    Validity: {
      color: approvedBoxColor,
      text: 'Vigente',
      icon: <ThumbUp sx={{ fontSize: 18, color: '#1d8f5e' }} />,
    },
    Done: {
      color: closedBoxColor,
      text: 'Encerrado',
      icon: <StopCircleOutlined sx={{ fontSize: 18, color: '#646464' }} />,
    },
    Deleted: {
      color: deletedBoxColor,
      text: 'Deletado',
      icon: <DeleteOutlineOutlined sx={{ fontSize: 18, color: '#db4d4d' }} />,
    },
  };

  const status = statusInfo[statusCode];

  if (!status) {
    return null;
  }

  return (
    <Stack flexDirection="row" sx={{ ...status.color, width: 128 }} gap={1}>
      <Box sx={boxIcon}>{status.icon}</Box>
      <Box sx={boxTxt}>
        <Typography sx={txtColor}>{status.text}</Typography>
      </Box>
    </Stack>
  );
}

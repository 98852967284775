/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
import { RequestService } from 'services/RequestService/RequestService';

import { Common } from 'utils';

import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

const execute = async () => {
  try {
    partnersManagementEvent({
      isLoadingDetails: true,
      requestDetails: [],
      partnerListMedicines: [],
      partnerListCRM: [],
    });

    const { selectedRow } = partnersManagementStore.getState();
    const listRequests = selectedRow?.requests || [];

    const promises = listRequests.map(async obj => {
      const { data } = await RequestService.getRequestById(obj.id);
      const filteredEquipmentQtyQuestionAnswer = data?.answers?.find(
        item =>
          item?.question?.questionCategory === 'Negotiation' &&
          item?.question?.questionType === 'EquipmentQty',
      );
      const equipmentQty = filteredEquipmentQtyQuestionAnswer?.answer[0] || null;
      return { ...data, equipmentQty };
    });

    const arrDetails = await Promise.all(promises);

    const arrListMedicinesOfAllRequests = arrDetails.reduce((accumulator, obj) => {
      const filteredQuestionsMedicine = obj?.answers?.filter(
        item => item?.question?.questionType === 'Medicine',
      );
      for (const item of filteredQuestionsMedicine) {
        const arr = item?.answer || [];
        accumulator.push(...arr);
      }
      return accumulator;
    }, []);

    const arrListCRMOfAllRequests = arrDetails.reduce((accumulator, obj) => {
      const filteredQuestionsCRM = obj?.answers?.filter(
        item => item?.question?.questionType === 'UF_CRM',
      );
      for (const item of filteredQuestionsCRM) {
        const arr = item?.answer || [];
        accumulator.push(...arr);
      }
      return accumulator;
    }, []);

    const sortDetailsList = arrDetails?.sort((a, b) => {
      if (a.statusId === 'Done' && b.statusId !== 'Done') {
        return 1;
      } else if (a.statusId !== 'Done' && b.statusId === 'Done') {
        return -1;
      } else {
        return 0;
      }
    });

    partnersManagementEvent({
      requestDetails: sortDetailsList,
      partnerInitiative: selectedRow.partner.initiative,
      partnerListMedicines: arrListMedicinesOfAllRequests.filter(Common.onlyUnique),
      partnerListCRM: arrListCRMOfAllRequests.filter(Common.onlyUnique).filter(i => i !== '-'),
    });
  } catch (e) {
    // console.log(e);
  } finally {
    partnersManagementEvent({ isLoadingDetails: false });
  }
};

export const getPartnersDetailsUseCase = { execute };

import { SxProps } from '@mui/material';

export const solicitationsContainerStyle: SxProps = {
  width: '650px',
  backgroundColor: '#fff',
  paddingBottom: 5,
  borderRadius: '15px 0 0 15px',
};

export const solicitationsContentStyle: SxProps = {
  p: 4,
  pb: 4,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
};

export const cardIconAvatarStyles: SxProps = {
  bgcolor: '#005391',
  width: 46,
  height: 46,
  borderRadius: 23,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

import { RequestService } from 'services/RequestService/RequestService';

import { requestManagementDetailEvent } from 'stores/RequestManagementDetail/RequestManagementDetailEvents';

const execute = async (id: string) => {
  try {
    const result = await RequestService.getRequestById(id);
    requestManagementDetailEvent({ dataDetail: result });
  } catch (err) {
    // console.log(err)
  }
};

export const getRequestManagementDetailUseCase = { execute };

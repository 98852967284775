/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';

import { useStore } from 'effector-react';

import { Box, Divider, Drawer, Stack, Typography, useTheme } from '@mui/material';

import AutomationList from 'components/ui/automationList/automationList';
import ConfirmModal from 'components/ui/confirmModal/ConfirmModal';

import { deleteAutomationUseCase } from 'useCases/productManagement/deleteAutomationUseCase';
import { getAutomationListProductManagementUseCase } from 'useCases/productManagement/getAutomationUseCase';

import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';
import productManagementStore from 'stores/ProductManagement/ProductManagementStore';

import SkeletonAutomationList from './SkeletonAutomationList';

AutomationListDrawer.defaultProps = {
  rowId: null,
};

const deleteHandle = (automationId: string, productId: string) => {
  deleteAutomationUseCase.execute(automationId, productId);
};

function AutomationListDrawer({ rowId }: { rowId?: string | null }) {
  const [automationsIds, setAutomationsIds] = useState({ automationId: null, productId: null });

  const theme = useTheme();
  const {
    isOpenAutomationListDrawer,
    dataAutomationList,
    isOpenModalDeleteAutomationSubmit,
    isLoadingForm,
  } = useStore(productManagementStore);

  const handleStateAutomationIds = (automationId: string, productId: string) => {
    setAutomationsIds({ automationId, productId });
  };
  const closeForm = () => {
    productManagementEvent({
      isOpenAutomationListDrawer: false,
      dataAutomationList: null,
    });
  };
  useEffect(() => {
    if (isOpenAutomationListDrawer) getAutomationListProductManagementUseCase.execute(rowId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenAutomationListDrawer]);

  if (!isOpenAutomationListDrawer) return <></>;

  return (
    <Drawer anchor="right" open={isOpenAutomationListDrawer} onClose={closeForm}>
      <Box
        sx={{
          [theme.breakpoints.between('sm', 'xs')]: {
            width: 480,
            maxWidth: 480,
          },
          [theme.breakpoints.up('md')]: {
            width: 580,
            maxWidth: 580,
          },
          minHeight: 'auto',
          display: 'flex',
          flex: 1,
          borderRadius: '10px 0 0 10px !important',
          backgroundColor: '#FFF',
        }}
      >
        <Stack
          px={4}
          py={4}
          direction="column"
          justifyContent="space-between"
          flex={1}
          sx={{
            height: '100%',
            paddingBottom: 5,
          }}
        >
          {dataAutomationList ? (
            <div>
              <Typography fontSize="26px" fontWeight="bold">
                {dataAutomationList?.data?.productName}
              </Typography>
              <Typography fontSize="16px">
                Aqui você pode gerenciar as automações deste diagnóstico, assim como excluí-las.{' '}
              </Typography>
              <div style={{ marginTop: 18 }} />
              <Typography fontSize="12px" fontWeight="bold" mt={4}>
                AUTOMAÇÕES DESSE PRODUTO
              </Typography>
              <Divider />
              <Box mt={2} gap={2}>
                {dataAutomationList?.data?.automation?.map(item => {
                  return (
                    <AutomationList
                      automation={item}
                      productId={rowId}
                      setStateAutomationIds={handleStateAutomationIds}
                    />
                  );
                })}
              </Box>
            </div>
          ) : (
            <SkeletonAutomationList />
          )}
        </Stack>
      </Box>
      <ConfirmModal
        open={isOpenModalDeleteAutomationSubmit}
        setOpen={v => productManagementEvent({ isOpenModalDeleteAutomationSubmit: v })}
        text="Tem certeza que deseja criar um novo"
        onConfirm={() => deleteHandle(automationsIds?.automationId, automationsIds?.productId)}
        isLoading={isLoadingForm}
        title="Atenção"
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
    </Drawer>
  );
}

export default AutomationListDrawer;

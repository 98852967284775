import formDynamicFormStore from 'stores/DynamicForm/FormDynamicFormStore';

interface IParams {
  data: any;
  questionCategoryList: string[];
  cbReset: (v: boolean) => void;
  cbSetValue: (input: string, value: string) => void;
}

const execute = async (params: IParams) => {
  const { cnpjInfo } = formDynamicFormStore.getState();
  if (cnpjInfo) {
    params?.cbReset(true);
    const sectionClient = params?.data?.productQuestions?.filter(
      question => question.question.questionCategory === params?.questionCategoryList[1],
    );

    const doctorNameInput = sectionClient?.find(
      obj =>
        obj.question.questionType === 'PartnerName' &&
        obj.question.description?.toLowerCase().includes('nome do médico'),
    );
    const clinicNameInput = sectionClient?.find(
      obj =>
        obj.question.questionType === 'PartnerName' &&
        obj.question.description?.toLowerCase().includes('clínica ou consultório'),
    );
    const clinicMailInput = sectionClient?.find(
      obj =>
        obj.question.questionType === 'Email' &&
        obj.question.description?.toLowerCase().includes('e-mail do cliente'),
    );
    const clinicPhoneInput = sectionClient?.find(
      obj =>
        obj.question.questionType === 'Phone' &&
        obj.question.description?.toLowerCase().includes('contato da clínica'),
    );

    if (doctorNameInput) {
      const inputName = `${doctorNameInput.question.id}__${doctorNameInput.question.questionType}__${doctorNameInput.question.questionCategory}`;
      params?.cbSetValue(inputName, cnpjInfo?.fantasia);
    }
    if (clinicNameInput) {
      const inputName = `${clinicNameInput.question.id}__${clinicNameInput.question.questionType}__${clinicNameInput.question.questionCategory}`;
      params?.cbSetValue(inputName, cnpjInfo?.nome);
    }
    if (clinicPhoneInput) {
      const inputName = `${clinicPhoneInput.question.id}__${clinicPhoneInput.question.questionType}__${clinicPhoneInput.question.questionCategory}`;
      const fixedPhoneStr = cnpjInfo?.telefone?.includes('/')
        ? cnpjInfo?.telefone?.split('/')[0]
        : cnpjInfo?.telefone;
      params?.cbSetValue(inputName, fixedPhoneStr);
    }
    if (clinicMailInput) {
      const inputName = `${clinicMailInput.question.id}__${clinicMailInput.question.questionType}__${clinicMailInput.question.questionCategory}`;
      params?.cbSetValue(inputName, cnpjInfo?.email);
    }
    params?.cbReset(false);
  }
};

export const loadCNPJInfoInputsUseCase = { execute };

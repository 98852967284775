import { ISolicitationResponse } from 'domains/solicitation/Solicitation.domain';

export interface NotificationState {
  loading: boolean;
  open: boolean;
  openModalApprove: boolean;
  openModalReprove: boolean;
  equipmentReturn: ISolicitationResponse | null;
  managerTransfer: ISolicitationResponse | null;
  requestUpdate: ISolicitationResponse | null;
  pendingNotifications: number;
  pageTabEquipmentReturn: number;
  pageTabManagerTransfer: number;
  pageTabRequestUpdate: number;
}

export const notificationInitialState: NotificationState = {
  loading: false,
  openModalApprove: false,
  openModalReprove: false,
  open: false,
  equipmentReturn: null,
  managerTransfer: null,
  requestUpdate: null,
  pendingNotifications: 0,
  pageTabEquipmentReturn: 1,
  pageTabManagerTransfer: 1,
  pageTabRequestUpdate: 1,
};

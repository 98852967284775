import { getPartnersManagementUseCase } from 'useCases/partnersManagement/getPartnersManagementUseCase';

import { SolicitationService } from 'services/SolicitationService/SolicitationService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';
import { notificationEvent } from 'stores/Notifications/NotificationsEvents';

import { getNotificationsUseCase } from './getNotificationsUseCase';

const execute = async (id: string) => {
  const { sector } = authStore.getState();
  SolicitationService.approve(id, sector)
    .then(() =>
      alertEvent({ open: true, type: 'success', text: 'Solicitação aprovada com sucesso.' }),
    )
    .catch(() =>
      alertEvent({
        open: true,
        type: 'error',
        text: 'Ocorreu um erro, tente novamente mais tarde.',
      }),
    )
    .finally(() => {
      getNotificationsUseCase.execute();
      getPartnersManagementUseCase.execute();
      notificationEvent({ openModalApprove: false });
    });
};

export const approveUseCase = { execute };

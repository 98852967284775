import { IRequestManagementResponse } from 'domains/requestManagement';

export interface RequestManagementState {
  isLoading: boolean;
  isLoadingExport: boolean;
  isOpenEditValidityModal: boolean;
  isOpenAlertModal: boolean;
  isOpenCommentAlertModal: boolean;
  dataSource: IRequestManagementResponse | null;
  isAdmin: boolean;
  pageSize: number;
  pageNow: number;
  filters: any;
}

export const requestManagementInitialState: RequestManagementState = {
  isLoading: false,
  isOpenEditValidityModal: false,
  isOpenAlertModal: false,
  isOpenCommentAlertModal: false,
  isAdmin: false,
  isLoadingExport: false,
  dataSource: null,
  pageSize: 10,
  pageNow: 1,
  filters: null,
};

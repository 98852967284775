/* eslint-disable react/destructuring-assignment */

import { useEffect, useState } from 'react';

import { useStore } from 'effector-react';

import {
  ArrowForwardOutlined,
  CloseOutlined,
  PersonAddAltOutlined,
  PersonAddOutlined,
} from '@mui/icons-material';
import { Box, Button, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material';

import TextInput from 'components/form/textInput';
import { ButtonBack } from 'components/ui/buttonBack/ButtonBack';
import ConfirmModal from 'components/ui/confirmModal/ConfirmModal';

import { changeManagerUseCase } from 'useCases/partnersManagement/changeManagerUseCase';
import { searchUserBySectorUseCase } from 'useCases/partnersManagement/searchUserBySectorUseCase';

import { authStore } from 'stores/AuthStore/AuthStore';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { info1, info2 } from '../PartnersManagementPage.styles';

function DrawerPartnerManager() {
  const { permission } = useStore(authStore);
  const {
    isOpenDrawerPartnerManager,
    isLoadingManager,
    selectedRow,
    isOpenDrawerPartnerManagerModal,
    managerUserInfo,
  } = useStore(partnersManagementStore);

  const [manager, setManager] = useState({
    id: Math.random() * 999,
    sector: '',
    name: '',
    role: '',
  });

  const onClose = () => {
    partnersManagementEvent({ isOpenDrawerPartnerManager: false, isOpenDrawerDetails: true });
  };

  const checkIsInvalid = () => {
    const managerRoleResult = managerUserInfo?.role || manager?.role;
    const validation =
      permission === 0
        ? managerRoleResult === 'REP'
        : !['GD', 'GDGRCE'].includes(managerRoleResult);
    return validation;
  };

  const handleButtonDisabled = () => {
    return checkIsInvalid()
      ? true
      : [manager].every(obj => obj.name.trim() === '' || obj.sector.trim() === '');
  };

  useEffect(() => {
    const filtered = selectedRow?.users
      ?.filter(item => item?.owner)
      ?.map(item => ({
        id: Math.random() * 999,
        sector: item?.sector,
        name: item?.name,
        role: item?.role || '',
      }));
    if (filtered?.length > 0) {
      setManager(filtered[0]);
    }
  }, [isOpenDrawerPartnerManager, selectedRow]);

  useEffect(() => {
    if (managerUserInfo?.users) {
      const result = managerUserInfo?.users[0];
      if (!result) return;
      setManager(oldState => ({ ...oldState, name: result?.nome, role: result?.role || '' }));
    }
  }, [managerUserInfo]);

  useEffect(() => {
    partnersManagementEvent({ managerUserInfo: null });
    setManager({ id: Math.random() * 999, sector: '', name: '', role: '' });
  }, []);

  return (
    <>
      <Drawer anchor="right" open={isOpenDrawerPartnerManager} onClose={onClose}>
        <Stack
          p={4}
          direction="column"
          flex={1}
          sx={{
            width: '640px',
            backgroundColor: 'white',
            paddingBottom: 5,
            borderRadius: '15px 0 0 15px',
          }}
        >
          <Stack mt={2} pb={1} direction="row" justifyContent="space-between" alignItems="center">
            <ButtonBack text="VOLTAR" onBack={onClose} />
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Stack>
          <Typography fontSize={22} fontWeight={700} pb={6}>
            Alterar gestão
          </Typography>

          <Typography fontSize={14} fontWeight={700}>
            GESTÃO ATUAL
          </Typography>
          <Divider />
          <Stack direction="row" gap={2} mt={2}>
            <Box>
              <PersonAddAltOutlined sx={{ fontSize: '46px' }} />
            </Box>
            <Box>
              <Typography sx={info2}>GESTOR</Typography>
              <Stack>
                {selectedRow?.users
                  ?.filter(obj => obj.owner)
                  .map(obj => (
                    <Typography sx={info1}>{obj?.name}</Typography>
                  ))}
              </Stack>
            </Box>
          </Stack>

          <Typography fontSize={14} fontWeight={700} mt={6}>
            ALTERAR GESTÃO
          </Typography>
          <Divider />
          <Box mt={2}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Stack direction="row" alignItems="center" gap={2} style={{ width: '75%' }}>
                <Stack>
                  <Typography fontSize="16px">Código</Typography>
                  <TextInput
                    startAdornment={
                      <>
                        <PersonAddOutlined />
                        <div style={{ width: 8 }} />
                      </>
                    }
                    maxLength={8}
                    placeholder="00000000"
                    value={manager.sector}
                    onChange={e => {
                      const val = e.target.value;
                      setManager(oldState => ({ ...oldState, sector: val }));
                      if (val?.length >= 8) {
                        searchUserBySectorUseCase.execute(val, true);
                      }
                    }}
                    onBlur={e => searchUserBySectorUseCase.execute(e.target.value, true)}
                  />
                </Stack>
                <Stack>
                  <Typography fontSize="16px">Nome</Typography>
                  <TextInput placeholder="Nome" value={manager.name} disabled />
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Button
            variant="contained"
            sx={{ width: 250, mt: 8 }}
            disabled={handleButtonDisabled()}
            endIcon={<ArrowForwardOutlined color="inherit" />}
            onClick={() => partnersManagementEvent({ isOpenDrawerPartnerManagerModal: true })}
          >
            ALTERAR GESTÃO
          </Button>
          {checkIsInvalid() && (
            <Typography sx={{ mt: 2, fontSize: 16, color: 'red' }}>
              * Essa transferência não é permitida.
            </Typography>
          )}
        </Stack>
      </Drawer>
      <ConfirmModal
        open={isOpenDrawerPartnerManagerModal}
        setOpen={v => partnersManagementEvent({ isOpenDrawerPartnerManagerModal: v })}
        title="Atenção"
        text="Tem certeza que deseja alterar a gestão dessa parceria?"
        onConfirm={() =>
          changeManagerUseCase.execute({
            newUserSector: manager.sector,
            reset: () => setManager({ id: Math.random() * 999, sector: '', name: '', role: '' }),
          })
        }
        isLoading={isLoadingManager}
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
    </>
  );
}

export default DrawerPartnerManager;

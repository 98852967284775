import { Skeleton, Stack } from '@mui/material';

import useResponsive from 'hooks/useResponsive';

function DrawerNotificationsLoading(): JSX.Element {
  const isDesktop = useResponsive({ query: 'up', start: 'lg' });
  return (
    <Stack p={isDesktop ? 4 : 2} bgcolor="white" flex={1} direction="column" gap={2}>
      <Skeleton
        animation="wave"
        sx={{ mt: 1, borderRadius: 2 }}
        variant="rectangular"
        width="100%"
        height={120}
      />
      <Skeleton
        animation="wave"
        sx={{ mt: 1, borderRadius: 2 }}
        variant="rectangular"
        width="100%"
        height={120}
      />
      <Skeleton
        animation="wave"
        sx={{ mt: 1, borderRadius: 2 }}
        variant="rectangular"
        width="100%"
        height={120}
      />
      <Skeleton
        animation="wave"
        sx={{ mt: 1, borderRadius: 2 }}
        variant="rectangular"
        width="100%"
        height={120}
      />
      <Skeleton
        animation="wave"
        sx={{ mt: 1, borderRadius: 2 }}
        variant="rectangular"
        width="100%"
        height={120}
      />
      <Skeleton
        animation="wave"
        sx={{ mt: 1, borderRadius: 2 }}
        variant="rectangular"
        width="100%"
        height={120}
      />
      <Skeleton
        animation="wave"
        sx={{ mt: 1, borderRadius: 2 }}
        variant="rectangular"
        width="100%"
        height={120}
      />
    </Stack>
  );
}

export default DrawerNotificationsLoading;

import { RequestService } from 'services/RequestService/RequestService';

import { Formatter } from 'utils';

import { authStore } from 'stores/AuthStore/AuthStore';
import { formDynamicFormEvent } from 'stores/DynamicForm/FormDynamicFormEvents';

const execute = async (form: any) => {
  try {
    formDynamicFormEvent({ isLoading: true });
    const { sector } = authStore.getState();
    const formattedSubmit = Formatter.formatterDynamicFormSubmitFormData(sector, form);
    await RequestService.createRequest(formattedSubmit);
    formDynamicFormEvent({ isLoading: false, openModal: true });
  } catch (error: any) {
    console.log(error);
    const errorMessage = error?.response?.data?.error;
    formDynamicFormEvent({ isLoading: false, openModalError: true, errorMessage });
  }
};

export const createFormDynamicFormUseCase = { execute };

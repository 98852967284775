import { RequestService } from 'services/RequestService/RequestService';

import { Common } from 'utils';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { getPartnersDetailsUseCase } from './getPartnersDetailsUseCase';

const execute = async (newList: string[]) => {
  try {
    partnersManagementEvent({ isLoadingEditMedicine: true });
    const { selectedRow, requestDetails } = partnersManagementStore.getState();

    const arrQuestionsMedicine: any = [];
    for (const obj of requestDetails) {
      const filteredQuestionsMedicine = obj?.answers?.filter(
        item => item?.question?.questionType === 'Medicine',
      );
      for (const item of filteredQuestionsMedicine) {
        arrQuestionsMedicine.push(item);
      }
    }
    const newAnswersList = arrQuestionsMedicine?.map(it => ({
      questionId: it.question.id,
      questionCategory: it.question.questionCategory,
      questionType: it.question.questionType,
      answer: newList,
    }));

    const formattedPayload = {
      partnerCode: selectedRow?.partner?.cnpj_cpf,
      submitData: {
        userId: selectedRow?.users ? selectedRow?.users[0]?.id || null : null,
        answers: [newAnswersList[0]],
      },
    };

    await RequestService.changePartnerAnswers(formattedPayload);
    await Common.sleep(2000);

    alertEvent({
      type: 'success',
      open: true,
      text: 'Lista de produtos negociados atualizada com sucesso.',
    });
  } catch (err) {
    // console.log(err);
  } finally {
    getPartnersDetailsUseCase.execute();
    partnersManagementEvent({ isOpenDrawerEditMedicine: false, isLoadingEditMedicine: false });
  }
};

export const editMedicineUseCase = { execute };

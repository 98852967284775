import { RequestService } from 'services/RequestService/RequestService';

import { Common } from 'utils';

import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';
import { getPartnersManagementUseCase } from './getPartnersManagementUseCase';

const execute = async () => {
  try {
    partnersManagementEvent({ isLoadingInitiative: true });
    const { sector } = authStore.getState();
    const { selectedRow, initiativeInfo } = partnersManagementStore.getState();

    const formattedPayload = {
      id: selectedRow.partner.id,
      initiative: initiativeInfo,
      userSector: sector,
    };

    await RequestService.changePartnerInfo(formattedPayload);

    alertEvent({ open: true, text: 'Parceria atualizada com sucesso.', type: 'success' });

    await Common.sleep(2000);
  } catch (err) {
    alertEvent({ open: true, text: 'Ocorreu um erro, tente novamente mais tarde.', type: 'error' });
  } finally {
    getPartnersManagementUseCase.execute();
    partnersManagementEvent({ isLoadingInitiative: false, isOpenDrawerDetails: false });
  }
};

export const editInitiativeUseCase = { execute };

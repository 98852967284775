import { MedicineService } from 'services/MedicineService/MedicineService';

import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';

const execute = async () => {
  try {
    const result = await MedicineService.getMedicines(1, 999);
    partnersManagementEvent({ listMedicine: result.data.medicines });
  } catch (e) {
    // console.log(e);
  }
};

export const getDrawerMedicineUseCase = { execute };

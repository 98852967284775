import { getNotificationsUseCase } from 'useCases/notifications/getNotificationsUseCase';

import { AuthService } from 'services/Auth/AuthService';

import { Common } from 'utils';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { authEvent } from 'stores/AuthStore/AuthEvents';

import { IAtuhEnum } from 'domains/auth/AuthDomain';

import { keycloak } from '../../keycloak';

const execute = async (params: { token: string }) => {
  try {
    const userDetail = keycloak?.tokenParsed;
    const alreadyLogged = window.location.href.includes('/app/');
    if (alreadyLogged) return;
    const { data: user } = await AuthService.getUserInfo(params.token);
    const permission = userDetail?.groups ? Common.getPermission(userDetail?.groups) : 2;
    const isAdmin = permission === 0;
    const userSector = isAdmin ? IAtuhEnum.ADMIN_CODE : user.sector?.toString();
    const isNotAuthorized = !isAdmin && (!Number(user?.sector) || !userDetail?.groups);
    const searchParams = { name: isAdmin ? IAtuhEnum.ADMIN : user?.name, sector: userSector };
    const { data: userInfo } = await AuthService.getUserAccess(searchParams);
    if (isNotAuthorized) {
      await onError();
      return;
    }
    const result = {
      ...user,
      ...userInfo,
      permission,
      token: params.token,
      groups: userDetail.groups,
      isLogged: true,
      isAdmin,
    };
    authEvent(result);
    Common.setSessionValue('logCookie', result);
    window?.location?.replace('/app/new-request');
    getNotificationsUseCase.execute();
  } catch (err) {
    await onError();
  }
};

const onError = async () => {
  alertEvent({ open: true, type: 'error', text: 'Usuário sem permissão.' });
  await Common.sleep(1500);
  Common.setSessionValue('logCookie', {});
  // Common.clearCacheData();
  keycloak.logout();
};

export const loginSSOUseCase = { execute };

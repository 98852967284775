import { SxProps } from '@mui/material';

export const dropDownContainerStyles: SxProps = {
  minWidth: 200,
  borderRadius: 4,
  position: 'absolute',
  left: 20,
};

export const dropDownItemStyle: SxProps = {
  borderBottom: '1px solid #cecece',
  height: 42,
};

import { Box, Card, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';

import { cardStyles } from 'components/ui/content/styles';

export default function DynamicFormSkeleton(): JSX.Element {
  return (
    <Card sx={cardStyles}>
      <Typography variant="body1" fontWeight="700">
        <Skeleton variant="rectangular" width="30%" height={25} />
      </Typography>
      <Typography variant="caption" component="p" mt={1}>
        <Skeleton variant="rectangular" width="10%" height={15} />
      </Typography>
      <Typography variant="caption" component="p" mt={1} pb={2}>
        <Skeleton variant="rectangular" width="40%" height={15} />
      </Typography>
      <Typography variant="caption" fontWeight="700">
        <Skeleton variant="rectangular" width="15%" height={15} />
      </Typography>

      <Stack mt={4}>
        <Typography variant="caption" fontWeight="700" mb={2}>
          <Skeleton variant="rectangular" width="20%" height={20} />
        </Typography>
        <Divider style={{ marginBottom: '10px' }} />
        <Grid container gap={3}>
          <Grid item xs={12} sm={5} md={5.5} lg={5.5} flexDirection="column" display="flex">
            <Box mt={1}>
              <Skeleton variant="rectangular" width="40%" height={15} />
            </Box>
            <Box mt={1}>
              <Skeleton variant="rectangular" width="100%" height={40} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} md={5.5} lg={5.5} flexDirection="column" display="flex">
            <Box mt={1}>
              <Skeleton variant="rectangular" width="40%" height={15} />
            </Box>
            <Box mt={1}>
              <Skeleton variant="rectangular" width="100%" height={40} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} md={5.5} lg={5.5} flexDirection="column" display="flex">
            <Box mt={1}>
              <Skeleton variant="rectangular" width="40%" height={15} />
            </Box>
            <Box mt={1}>
              <Skeleton variant="rectangular" width="100%" height={40} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} md={5.5} lg={5.5} flexDirection="column" display="flex">
            <Box mt={1}>
              <Skeleton variant="rectangular" width="40%" height={15} />
            </Box>
            <Box mt={1}>
              <Skeleton variant="rectangular" width="100%" height={40} />
            </Box>
          </Grid>
        </Grid>

        <Typography variant="caption" fontWeight="700" mb={2} mt={3}>
          <Skeleton variant="rectangular" width="20%" height={20} />
        </Typography>
        <Divider style={{ marginBottom: '10px' }} />
        <Grid container gap={3}>
          <Grid item xs={12} sm={5} md={5.5} lg={5.5} flexDirection="column" display="flex">
            <Box mt={1}>
              <Skeleton variant="rectangular" width="40%" height={15} />
            </Box>
            <Box mt={1}>
              <Skeleton variant="rectangular" width="100%" height={40} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} md={5.5} lg={5.5} flexDirection="column" display="flex">
            <Box mt={1}>
              <Skeleton variant="rectangular" width="40%" height={15} />
            </Box>
            <Box mt={1}>
              <Skeleton variant="rectangular" width="100%" height={40} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} md={5.5} lg={5.5} flexDirection="column" display="flex">
            <Box mt={1}>
              <Skeleton variant="rectangular" width="40%" height={15} />
            </Box>
            <Box mt={1}>
              <Skeleton variant="rectangular" width="100%" height={40} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} md={5.5} lg={5.5} flexDirection="column" display="flex">
            <Box mt={1}>
              <Skeleton variant="rectangular" width="40%" height={15} />
            </Box>
            <Box mt={1}>
              <Skeleton variant="rectangular" width="100%" height={40} />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
}

/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/require-default-props */
import { Control, Controller, FieldValues } from 'react-hook-form';

import { BaseTextFieldProps, InputAdornment, TextField } from '@mui/material';

import { Formatter } from 'utils';

interface IProps extends BaseTextFieldProps {
  name: string;
  control: Control<FieldValues>;
  mask?: 'CPF' | 'CEP' | 'PHONE' | 'CNPJ';
  maxLength?: number;
  height?: number;
}

export default function TextInput({ control, name, mask, maxLength, ...rest }: IProps) {
  const applyMask = (val: string) => {
    if (!mask) return val;
    switch (mask) {
      case 'CPF':
        return Formatter.maskCpf(val);
      case 'CEP':
        return Formatter.maskCep(val);
      case 'PHONE':
        return Formatter.maskPhone(val);
      case 'CNPJ':
        return Formatter.maskCnpj(val);
      default:
        return val;
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <TextField
            {...rest}
            autoComplete="off"
            fullWidth
            variant="outlined"
            defaultValue=""
            value={value}
            placeholder={rest?.placeholder ?? 'Digite aqui'}
            onChange={e => onChange(applyMask(e.target.value))}
            inputProps={{
              maxLength,
              min: 0,
              style: {
                fontSize: 16,
                height: !rest?.height ? 10 : rest?.height,
              },
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start"> </InputAdornment>,
            }}
            error={!!error}
            helperText={error?.message}
          />
        );
      }}
    />
  );
}

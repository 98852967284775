import { ProductsService } from 'services/ProductService/ProductService';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';
import productManagementStore from 'stores/ProductManagement/ProductManagementStore';

const execute = async (id: string, isActive: boolean) => {
  try {
    productManagementEvent({ isLoadingForm: true });
    const { list } = productManagementStore.getState();

    await ProductsService.changeStatus(id, { isActive }).then(() => {
      const updatedProducts = list.data.products.map(product => {
        if (product.id === id) {
          return { ...product, status: isActive };
        }
        return product;
      });
      const newVal = { ...list, data: { ...list.data, products: updatedProducts } };
      productManagementEvent({ list: newVal });
    });

    alertEvent({
      open: true,
      text: `Produto ${isActive ? 'Ativado' : 'Inativado'} com sucesso.`,
      type: 'success',
    });
  } catch (err) {
    alertEvent({
      open: true,
      text: `Ocorreu um erro ao ${
        isActive ? 'Ativar' : 'Inativar'
      } o produto, tente novamente mais tarde`,
      type: 'error',
    });
  } finally {
    productManagementEvent({
      isOpenModalChangeStatusSubmit: false,
      isLoadingForm: false,
    });
  }
};

export const ChangeStatusProductManagementUseCase = { execute };

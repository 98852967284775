import { ICompaigns } from 'domains/compaigns';
import { IInvestment } from 'domains/investments';
import { IMedicines } from 'domains/medicines';
import { IRelocateTypesEnum } from 'domains/partnersManagement/Partners.domain';
import ICnpjInfo from 'domains/receita';

export interface FormDynamicFormState {
  isLoading: boolean;
  gdSector: string;
  gdName: string;
  isLoadingOptions: boolean;
  listCompaigns: ICompaigns[];
  listInvestment: IInvestment[];
  listMedicine: IMedicines[];
  isError: boolean;
  openModal: boolean;
  openModalError: boolean;
  errorMessage: string;
  cnpjInfo: null | ICnpjInfo;
  editForm: IFormEditDynamicFormState;
  equipmentsIds: string[] | null;
  relocateType: IRelocateTypesEnum | null;
}

export interface IFormEditDynamicFormState {
  id: string;
  userId: string;
  requestOriginId?: string;
  product: {
    id: string;
    productVersionId: string;
    status: boolean;
    name: string;
    description: string | null;
    thumbnail: string | null;
  };
  equipmentsQty: number;
  patientsQty: number;
  reportsQty: number | null;
  statusId: string;
  observation: string | null;
  partnerStatus: string;
  origin: string;
  createdAt: string;
  users: {
    id: string;
    userId: string;
    name: string;
    sector: string;
    role: string;
  }[];
  professionals: {
    ufcrm: string;
  }[];
  medicines: {
    id: string;
    name: string;
  }[];
  status: {
    statusId: string;
    createdBy: string;
    createdAt: string;
  }[];
}
export const formDynamicFormInitialState: FormDynamicFormState = {
  isLoading: false,
  isLoadingOptions: false,
  gdSector: '',
  gdName: '',
  listCompaigns: [],
  listInvestment: [],
  listMedicine: [],
  isError: false,
  openModal: false,
  openModalError: false,
  errorMessage: '',
  cnpjInfo: null,
  editForm: null,
  equipmentsIds: null,
  relocateType: null,
};

import { Box, Grid, Skeleton } from '@mui/material';

export default function DrawerSkeleton(): JSX.Element {
  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Skeleton animation="wave" sx={{ px: 1, mt: 3 }} />
      <Skeleton animation="wave" sx={{ px: 1 }} />
      <Skeleton animation="wave" variant="rounded" width="100%" height={60} sx={{ mt: 4, px: 1 }} />
      <Skeleton
        animation="wave"
        variant="rounded"
        width="100%"
        height={120}
        sx={{ mt: 3, px: 1 }}
      />
      <Grid container mt={3}>
        <Grid item sm={6}>
          <Skeleton animation="wave" variant="rounded" width="99%" height={120} />
        </Grid>
        <Grid item sm={6}>
          <Skeleton animation="wave" variant="rounded" width="99%" height={120} />
        </Grid>
      </Grid>
      <Skeleton animation="wave" sx={{ px: 1, mt: 3 }} />
      <Skeleton
        animation="wave"
        variant="rounded"
        width="100%"
        height={220}
        sx={{ mt: 3, px: 1 }}
      />
      <Skeleton animation="wave" sx={{ px: 1, mt: 3 }} />
      <Skeleton
        animation="wave"
        variant="rounded"
        width="100%"
        height={220}
        sx={{ mt: 3, px: 1 }}
      />
    </Box>
  );
}

/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';

import { useStore } from 'effector-react';

import { ArrowForwardOutlined, CloseOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { ButtonBack } from 'components/ui/buttonBack/ButtonBack';

import { editMedicineUseCase } from 'useCases/partnersManagement/editMedicineUseCase';
import { getDrawerMedicineUseCase } from 'useCases/partnersManagement/getDrawerMedicineUseCase';

import { partnersManagementEvent } from 'stores/PartnersManagement/PartnersManagementEvents';
import partnersManagementStore from 'stores/PartnersManagement/PartnersManagementStore';

function DrawerEditMedicine() {
  const { isOpenDrawerEditMedicine, listMedicine, partnerListMedicines, isLoadingEditMedicine } =
    useStore(partnersManagementStore);
  const theme = useTheme();

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isChanged, setIsChanged] = useState(false);

  const onClose = () =>
    partnersManagementEvent({
      isOpenDrawerEditMedicine: false,
      isOpenDrawerDetails: true,
    });

  useEffect(() => {
    setIsChanged(false);
    getDrawerMedicineUseCase.execute();
  }, []);

  useEffect(() => {
    const partnerActiveMedicines = listMedicine
      ?.filter(obj => partnerListMedicines?.includes(obj?.name))
      ?.map(obj => obj?.id);
    setSelectedOptions(partnerActiveMedicines);
  }, [listMedicine, partnerListMedicines]);

  return (
    <Drawer anchor="right" open={isOpenDrawerEditMedicine} onClose={() => onClose()}>
      <Box
        sx={{
          [theme.breakpoints.between('sm', 'xs')]: {
            width: 480,
            maxWidth: 480,
          },
          [theme.breakpoints.up('md')]: {
            width: 580,
            maxWidth: 580,
          },
          minHeight: '100%',
          borderRadius: '10px 0 0 10px !important',
          backgroundColor: '#FFF',
        }}
      >
        <Stack
          px={4}
          direction="column"
          flex={1}
          sx={{
            height: '100%',
            paddingBottom: 2,
          }}
        >
          <Stack mt={5} pb={1} direction="row" justifyContent="space-between" alignItems="center">
            <ButtonBack
              text="VOLTAR"
              onBack={() => {
                onClose();
              }}
            />
            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <CloseOutlined />
            </IconButton>
          </Stack>
          <Typography fontSize={22} fontWeight={700} pb={6}>
            Alterar medicamentos
          </Typography>
          <Divider />
          <Stack mt={1}>
            {listMedicine
              .filter(item => item.status)
              .map(obj => {
                const isSelected = selectedOptions?.some(it => it === obj.id);
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSelected}
                        onChange={() => {
                          setIsChanged(true);
                          setSelectedOptions(oldState =>
                            isSelected
                              ? oldState.filter(it => it !== obj.id)
                              : [...oldState, obj.id],
                          );
                        }}
                      />
                    }
                    label={
                      <Typography fontSize={14} fontWeight={500}>
                        {obj.name}
                      </Typography>
                    }
                  />
                );
              })}
          </Stack>

          <Button
            variant="contained"
            disabled={!isChanged || isLoadingEditMedicine || selectedOptions.length === 0}
            endIcon={!isLoadingEditMedicine && <ArrowForwardOutlined color="inherit" />}
            onClick={() => {
              editMedicineUseCase.execute(selectedOptions);
            }}
            sx={{ mt: 4, width: 300 }}
          >
            {isLoadingEditMedicine ? <CircularProgress size="25px" /> : 'ALTERAR MEDICAMENTOS'}
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
}

export default DrawerEditMedicine;

import { createStore } from 'effector';

import { authEvent } from './AuthEvents';
import { AuthState, authInitialState } from './AuthState';

const authStore = createStore<AuthState>(authInitialState).on(authEvent, (state, payload) => {
  return {
    ...state,
    ...payload,
  };
});

export { authStore };

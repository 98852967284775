import * as Yup from 'yup';

export const SpreadsheetAutomationProductManagementValidators = Yup.object().shape({
  rowId: Yup.string(),
  type: Yup.string(),
  url: Yup.string()
    .required('* Campo obrigatório')
    .test('is-csv-extension', 'O nome do arquivo deve ter a extensão .csv', value => {
      if (value) {
        return /\.csv$/.test(value);
      }
      return true;
    })
    .test(
      'no-special-characters',
      'O nome do arquivo não pode conter caracteres especiais',
      value => {
        if (value) {
          // Use uma expressão regular que permite apenas alfanuméricos, o sublinhado e o ponto
          return /^[a-zA-Z0-9_.]+$/.test(value);
        }
        return true;
      },
    ),
});

import axios from 'axios';

import { IViaCep } from 'domains/viaCEP';

const apiURL = 'https://viacep.com.br/ws';

const getAddressByCep = async (cep: string) => {
  const { data } = await axios.get<IViaCep>(`${apiURL}/${cep}/json`);
  return data;
};

export const ViaCEPService = {
  getAddressByCep,
};

import * as Yup from 'yup';

export const ProductManagementEditValidators = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string().required('* Campo obrigatório'),
  description: Yup.string().required('* Campo obrigatório'),
  image: Yup.object(),
  negotiationQuestions: Yup.array(),
  clientQuestions: Yup.array(),
  shippingQuestions: Yup.array(),
  compaignQuestions: Yup.array(),
});

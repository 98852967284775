import { IAddress } from 'domains/address';
import IRquestCRM from 'domains/crm';

export interface IRequestBase {
  UserSector: string;
  ProductId: string;
}
export interface IRequestParamExportRequest extends IRequestBase {
  Status?: string;
  StartDate?: string;
  EndDate?: string;
}

export interface IRequestParamExportPartnerHistoryRequest extends IRequestBase {
  Status?: string;
  StartValidity?: string;
  EndValidity?: string;
  SectorFilter?: string;
  MedicineId?: string;
}
export interface IRequestParamExportPartnersRequest extends IRequestBase {
  Status?: string;
  StartValidity?: string;
  EndValidity?: string;
  SectorFilter?: string;
  MedicineId?: string;
}

export interface IRequestParamRequest {
  productVersionId: string;
  userSector: string;
  answers: {
    questionId: string;
    questionCategory: string;
    questionType: string;
    answer: string[];
  }[];
  campaigns: {
    id: string;
    answer: string;
  }[];
  requestOriginId?: string;
  equipmentIds?: string[];
}

export enum PartnerStatus {
  newPartner = 'NewPartner',
  oldPartnet = 'OldPartner',
}
export interface IRequest {
  cnpj: string;
  userId: string;
  address?: IAddress;
  clinicName: string;
  shippingCPF?: string;
  patientsQty?: number;
  equipmentsQty?: number;
  shippingEmail?: string;
  applicantEmail?: string;
  medicines: { id: string }[];
  equipments?: { id: string }[];
  campaigns?: { id: string }[];
  investments?: { id: string }[];
  requestCRMs: IRquestCRM[];
}

import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const TooltipBootstrap = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3B98E5',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3B98E5',
    color: 'white',
  },
}));

export default TooltipBootstrap;

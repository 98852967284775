/* eslint-disable no-promise-executor-return */
/* eslint-disable no-plusplus */

import { addHours } from 'date-fns';

import { IPartnersManagement } from 'domains/partnersManagement/Partners.domain';
import { IRequestDetail } from 'domains/requestManagement';

interface RandomInterface {
  min?: number;
  max: number;
  floating?: boolean;
}

const camelCase = (str: string): string => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

function debounce<T extends unknown[], U>(
  callback: (...props: T) => PromiseLike<U> | U,
  wait: number,
): (...props: T) => Promise<U> {
  let timer: NodeJS.Timeout;

  return (...props: T): Promise<U> => {
    clearTimeout(timer);
    return new Promise(resolve => {
      timer = setTimeout(() => resolve(callback(...props)), wait);
    });
  };
}

const random = ({ min = 0, max, floating = false }: RandomInterface): number => {
  let upper: number;
  let lower: number;

  if (floating) {
    lower = min;
    upper = max;
    return lower + Math.random() * (upper - lower);
  }
  lower = Math.ceil(min);
  upper = Math.floor(max);
  return Math.floor(lower + Math.random() * (upper - lower + 1));
};

const round = (number: number, decimal = 0): number => {
  const roundedNumber = Number(`${number}e+${decimal}`);

  const exponentialNumber = `${Math.round(roundedNumber)}e-${decimal}`;

  return +`${exponentialNumber}`;
};

const cnpjValidator = (cnpj: string): boolean => {
  if (!cnpj) return false;
  const sanitizedCnpj = cnpj.replace(/[^\d]+/g, '');

  if (sanitizedCnpj === '') {
    return false;
  }

  if (sanitizedCnpj.length !== 14) {
    return false;
  }

  const knownInvalidCnpjs = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ];

  if (knownInvalidCnpjs.includes(sanitizedCnpj)) {
    return false;
  }

  const calculateDigit = (nums: string, tm: number): number => {
    let sum = 0;
    let posi = tm - 7;

    for (let i = tm; i >= 1; i--) {
      sum += Number(nums.charAt(tm - i)) * posi--;

      if (posi < 2) {
        posi = 9;
      }
    }

    return sum % 11 < 2 ? 0 : 11 - (sum % 11);
  };

  const digits = sanitizedCnpj.substring(sanitizedCnpj.length - 2);
  const nums = sanitizedCnpj.substring(0, sanitizedCnpj.length - 2);

  const firstDigit = calculateDigit(nums, nums.length);
  if (firstDigit !== Number(digits.charAt(0))) {
    return false;
  }

  const secondDigit = calculateDigit(nums + firstDigit, nums.length + 1);
  if (secondDigit !== Number(digits.charAt(1))) {
    return false;
  }

  return true;
};

const sortAlphabeticMap = (a, b) => {
  const nomeA = a.name.toUpperCase();
  const nomeB = b.name.toUpperCase();

  if (a.name === 'NÃO POSSUI' && b.name !== 'NÃO POSSUI') {
    return 1;
  }
  if (a.name !== 'NÃO POSSUI' && b.name === 'NÃO POSSUI') {
    return -1;
  }

  if (nomeA < nomeB) {
    return -1;
  }
  if (nomeA > nomeB) {
    return 1;
  }
  return 0;
};

const convertNameToInitials = (name: string) => {
  const words = name.split(' ');

  let initials = '';

  words.forEach(word => {
    if (word.length > 0) {
      initials += word[0].toUpperCase();
    }
  });

  return initials;
};

const cpfValidator = (cpf: string) => {
  if (!cpf) {
    return false;
  }

  let cpfSum = 0;
  const strCPF = cpf.replace('.', '').replace('.', '').replace('-', '');

  if (
    strCPF === '00000000000' ||
    strCPF === '11111111111' ||
    strCPF === '22222222222' ||
    strCPF === '33333333333' ||
    strCPF === '44444444444' ||
    strCPF === '55555555555' ||
    strCPF === '66666666666' ||
    strCPF === '77777777777' ||
    strCPF === '88888888888' ||
    strCPF === '99999999999' ||
    strCPF.length !== 11
  )
    return false;

  for (let i = 1; i <= 9; i++) {
    cpfSum += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  }

  let cpfRest = (cpfSum * 10) % 11;
  if (cpfRest === 10 || cpfRest === 11) cpfRest = 0;

  if (cpfRest !== parseInt(strCPF.substring(9, 10), 10)) return false;

  cpfSum = 0;
  for (let k = 1; k <= 10; k++) {
    cpfSum += parseInt(strCPF.substring(k - 1, k), 10) * (12 - k);
  }

  cpfRest = (cpfSum * 10) % 11;
  if (cpfRest === 10 || cpfRest === 11) cpfRest = 0;

  if (cpfRest !== parseInt(strCPF.substring(10, 11), 10)) return false;

  return true;
};

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

const formatTextList = (arrayInfo: string[], questionType = '') => {
  const result = arrayInfo.map((name, index) =>
    index === arrayInfo.length - 1 ? `${name}.` : `${name}, `,
  );
  let joinedString = result.join('');
  if (questionType === 'UF_CRM') {
    joinedString = joinedString.replace(/_undefined/g, '').replace('-', '');
  }
  return joinedString;
};

const sleep = async (milliseconds: number) =>
  new Promise(resolve => setTimeout(resolve, milliseconds));

const reverseCNPJMask = (cnpj: string) => cnpj.normalize('NFD').replace(/[^a-zA-Z0-9]/g, '');

const reverseCPFMask = (cpf: string) => cpf?.replace(/[.-]/g, '');

const formatDate = (inputDate: string): string | null => {
  if (inputDate !== null) {
    const dateObject = new Date(inputDate);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getSeconds().toString().padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
  return null;
};

const orderByDate = (dateStart: string, dateEnd: string) => {
  return new Date(dateStart).getTime() - new Date(dateEnd).getTime();
};

const getEarliestRequestValidityDate = (requests: Partial<IRequestDetail>[]): string => {
  const validStartDates = requests.filter(obj => !!obj.validityStart);
  if (validStartDates.length === 0) {
    return '-';
  }
  const sortedDates = validStartDates.sort((a, b) => orderByDate(a.validityStart, b.validityStart));
  const earliestValidityStartDate = sortedDates[0]?.validityStart;
  const formattedDate = formatDateStr(earliestValidityStartDate);
  return formattedDate;
};

const getMostRecentExpiryDateOfRequests = (requests: Partial<IRequestDetail>[]): string => {
  const validEndDates = requests.filter(obj => !!obj.validityEnd);
  if (validEndDates.length === 0) {
    return '-';
  }
  const sortedDates = validEndDates.sort((a, b) => orderByDate(a.validityEnd, b.validityEnd));
  const lastValidityEndDate = sortedDates[sortedDates.length - 1]?.validityEnd;
  const formattedDate = formatDateStr(lastValidityEndDate);
  return formattedDate;
};

const formatDateStr = (dateString: string): string => {
  const timezone = new Date().getTimezoneOffset() / 60;
  const dateStr = dateString.substring(0, 10);
  const date = addHours(new Date(dateStr), timezone);
  date.setHours(0, 0, 0, 0);
  const formattedDate = date.toISOString().substring(0, 10).split('-').reverse().join('/');
  return formattedDate;
};

function convertDateFormatToEUA(dateString) {
  const parts = dateString.split('/');
  if (parts.length === 3) {
    const day = parts[0].padStart(2, '0');
    const month = parts[1].padStart(2, '0');
    const year = parts[2];
    return `${year}-${month}-${day}`;
  }
  return null;
}

const getStatusPartners = (requests: IPartnersManagement['requests']) => {
  const isDone = requests?.every(obj => obj?.statusId === 'Done');
  const sts = isDone ? 'Done' : 'Validity';
  return sts;
};

const getPermission = (groups: string[]) => {
  const isAdmin = groups.some(item => item?.toLowerCase()?.includes('admin'));
  if (isAdmin) {
    return 0;
  }
  return 2;
};

const getAnswerRequestQuestion = ({
  request,
  questionType = '',
  questionCategory = '',
  description = '',
}) => {
  if (!request?.answers) return null;
  const filterQuestion = request?.answers?.filter(obj => {
    return (
      obj?.question?.questionType === questionType &&
      obj?.question?.questionCategory === questionCategory &&
      obj?.question?.description.toLowerCase().includes(description?.toLowerCase())
    );
  });
  if (filterQuestion) return null;
  const questionObj = filterQuestion[0];
  if (!questionObj) return null;
  const filteredAnswer = questionObj?.answer[0];
  if (!filteredAnswer) return null;
  return filteredAnswer;
};

const getSessionValue = key => {
  try {
    return atob(sessionStorage.getItem(key));
  } catch (err) {
    return null;
  }
};

const setSessionValue = (key, val) => {
  try {
    const stringValue = val && JSON.stringify(val);
    const hash = stringValue ? btoa(stringValue) : '';
    sessionStorage.setItem(key, hash);
    return true;
  } catch (err) {
    return null;
  }
};

const clearCacheData = () => {
  const allCookies = document.cookie.split(';');

  for (let i = 0; i < allCookies.length; i++) {
    let singleCookie = allCookies[i].trim(); // Use trim() para remover espaços em branco
    let cookieIndex = singleCookie.indexOf('=');
    let cookieName = cookieIndex > -1 ? singleCookie.substr(0, cookieIndex) : singleCookie;
    document.cookie = `${cookieName}=;expires=${new Date(0).toUTCString()}`; // Use new Date(0) para expirar o cookie
  }
};

export const Common = {
  setSessionValue,
  getSessionValue,
  debounce,
  random,
  round,
  onlyUnique,
  getPermission,
  camelCase,
  cnpjValidator,
  cpfValidator,
  sleep,
  reverseCNPJMask,
  reverseCPFMask,
  convertNameToInitials,
  sortAlphabeticMap,
  formatTextList,
  formatDate,
  orderByDate,
  getAnswerRequestQuestion,
  getMostRecentExpiryDateOfRequests,
  getEarliestRequestValidityDate,
  getStatusPartners,
  convertDateFormatToEUA,
  clearCacheData,
};

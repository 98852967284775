import { alertEvent } from 'stores/Alert/AlertEvents';
import { authStore } from 'stores/AuthStore/AuthStore';

interface IParams {
  data: any;
  questionCategoryList: string[];
  cbReset: (v: boolean) => void;
  cbSetValue: (input: string, value: string) => void;
}

const execute = async (params: IParams) => {
  const user = authStore.getState();
  if (!params?.data || !user) {
    alertEvent({ open: true, type: 'error', text: 'Usuário não localizado' });
    return;
  }
  const { data, cbReset, cbSetValue } = params;
  cbReset(true);
  const sectionIdentification = data?.productQuestions?.filter(
    question => question?.question?.questionCategory === params?.questionCategoryList[0],
  );
  const nameInput = sectionIdentification?.find(
    obj =>
      obj.question.questionType === 'TextInput' &&
      obj.question.description?.toLowerCase().includes('nome'),
  );
  const sectorInput = sectionIdentification?.find(
    obj =>
      obj.question.questionType === 'Sector' &&
      obj.question.description?.toLowerCase().includes('setor'),
  );
  const emailInput = sectionIdentification?.find(
    obj =>
      obj.question.questionType === 'Email' &&
      obj.question.description?.toLowerCase().includes('e-mail'),
  );

  if (nameInput) {
    const input = `${nameInput.question.id}__${nameInput.question.questionType}__${nameInput.question.questionCategory}`;
    cbSetValue(input, user?.name || '');
  }
  if (emailInput) {
    const input = `${emailInput.question.id}__${emailInput.question.questionType}__${emailInput.question.questionCategory}`;
    cbSetValue(input, user?.email || '');
  }
  if (sectorInput) {
    const input = `${sectorInput.question.id}__${sectorInput.question.questionType}__${sectorInput.question.questionCategory}`;
    cbSetValue(input, user?.sector);
  }

  cbReset(false);
};

export const loadIdentificationInputsUseCase = { execute };

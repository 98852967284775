/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-params */
import { ChangeEvent, useState } from 'react';
import { Controller } from 'react-hook-form';

import { useStore } from 'effector-react';

import { ArrowForwardOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Drawer, Stack, Typography, useTheme } from '@mui/material';

import FileInput from 'components/form/imagePicker';
import SwitchToggle from 'components/form/switchToggle';
import TextInput from 'components/form/textInput';
import CardQuestion from 'components/ui/cardQuestion';

import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';
import productManagementStore from 'stores/ProductManagement/ProductManagementStore';

const nameMaxLength = 15;
const descriptionMaxLength = 130;

function ProductManagementDrawer({ control, errors, isValid }) {
  const [nameCounter, setNameCounter] = useState(0);
  const [descriptionCounter, setDescriptionCounter] = useState(0);
  const [reseted, setReseted] = useState(false);
  const theme = useTheme();
  const {
    isOpenForm,
    listNegotiationQuestions,
    listShippingQuestions,
    listClientQuestions,
    listIdentificationQuestions,
    listCompaign,
  } = useStore(productManagementStore);

  const closeForm = () => productManagementEvent({ isOpenForm: false });

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const currentLength = value.length;
    if (currentLength <= nameMaxLength) {
      setNameCounter(currentLength);
    }
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const currentLength = value.length;
    if (currentLength <= descriptionMaxLength) {
      setDescriptionCounter(currentLength);
    }
  };

  if (!isOpenForm) return <></>;

  return (
    <Drawer anchor="right" open={isOpenForm} onClose={closeForm}>
      <Box
        sx={{
          [theme.breakpoints.between('sm', 'xs')]: {
            width: 480,
            maxWidth: 480,
          },
          [theme.breakpoints.up('md')]: {
            width: 580,
            maxWidth: 580,
          },
          minHeight: 'auto',
          borderRadius: '10px 0 0 10px !important',
          backgroundColor: '#FFF',
        }}
      >
        <Stack
          px={4}
          py={4}
          direction="column"
          justifyContent="space-between"
          flex={1}
          sx={{
            height: '100%',
            paddingBottom: 28,
          }}
        >
          <div>
            <Typography fontSize="26px" fontWeight="bold">
              Criar produto
            </Typography>
            <div style={{ marginTop: 18 }} />
            <Controller
              name="image"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FileInput file={value} setFile={onChange} />
              )}
            />
            <Typography fontSize="12px" fontWeight="bold" mt={4}>
              INFORMAÇÕES DO PRODUTO
            </Typography>
            <Divider />
            <Box mt={2}>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <div>
                      <TextInput
                        label="Nome do produto"
                        name={name}
                        value={value}
                        maxLength={15}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          // Especificando o tipo do evento
                          onChange(e.target.value);
                          handleNameChange(e);
                        }}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        inputProps={{
                          maxLength: nameMaxLength,
                        }}
                      />
                      <Typography
                        fontSize="12px"
                        color={nameCounter <= nameMaxLength ? 'initial' : 'error'}
                      >
                        {`${nameCounter}/${nameMaxLength}`}
                      </Typography>
                    </div>
                  );
                }}
              />
            </Box>
            <Box mt={2}>
              <Controller
                name="description"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <div>
                      <TextInput
                        label="Descrição"
                        name={name}
                        value={value}
                        multiline
                        rows={3}
                        maxLength={130}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          // Especificando o tipo do evento
                          onChange(e.target.value);
                          handleDescriptionChange(e);
                        }}
                        error={!!errors.description}
                        helperText={errors.description?.message}
                        inputProps={{
                          maxLength: descriptionMaxLength,
                        }}
                      />
                      <Typography
                        fontSize="12px"
                        color={nameCounter <= nameMaxLength ? 'initial' : 'error'}
                      >
                        {`${descriptionCounter}/${descriptionMaxLength}`}
                      </Typography>
                    </div>
                  );
                }}
              />
            </Box>

            {!reseted ? (
              <>
                <Typography fontSize="12px" fontWeight="bold" mt={4}>
                  INFORMAÇÕES DO SOLICITANTE
                </Typography>
                <Divider />
                {listIdentificationQuestions
                  ?.sort((a, b) => (a?.order < b?.order ? -1 : 1))
                  .map(obj => {
                    return (
                      <CardQuestion
                        key={obj.id}
                        order={obj?.order}
                        onChange={(draggedOrder, droppedOnOrder) => {
                          setReseted(true);
                          const selectedItemId = listIdentificationQuestions.find(
                            it => it.order === draggedOrder,
                          );
                          const itemToUpdateId = listIdentificationQuestions.find(
                            it => it.order === droppedOnOrder,
                          );
                          const newArr = [...listIdentificationQuestions]?.map(it => ({
                            ...it,
                            order:
                              it.id === selectedItemId?.id
                                ? droppedOnOrder
                                : it.id === itemToUpdateId?.id
                                ? draggedOrder
                                : it.order,
                          }));
                          productManagementEvent({
                            listIdentificationQuestions: newArr,
                          });
                          setReseted(false);
                        }}
                      >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography fontSize={16}>{obj?.description}</Typography>
                          <SwitchToggle
                            disabled={obj.isRequired}
                            value={obj.isActive}
                            onChange={() => {
                              const newArr = [...listIdentificationQuestions];
                              const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                              newArr[replaceIdx].isActive = !obj?.isActive;
                              productManagementEvent({ listIdentificationQuestions: newArr });
                            }}
                          />
                        </Stack>
                      </CardQuestion>
                    );
                  })}

                <Typography fontSize="12px" fontWeight="bold" mt={4}>
                  INFORMAÇÕES DA CLÍNICA/MÉDICO
                </Typography>
                <Divider />

                {listClientQuestions
                  ?.sort((a, b) => (a?.order < b?.order ? -1 : 1))
                  .map(obj => {
                    return (
                      <CardQuestion
                        key={obj.id}
                        order={obj?.order}
                        onChange={(draggedOrder, droppedOnOrder) => {
                          setReseted(true);
                          const selectedItemId = listClientQuestions.find(
                            it => it.order === draggedOrder,
                          );
                          const itemToUpdateId = listClientQuestions.find(
                            it => it.order === droppedOnOrder,
                          );
                          const newArr = [...listClientQuestions]?.map(it => ({
                            ...it,
                            order:
                              it.id === selectedItemId?.id
                                ? droppedOnOrder
                                : it.id === itemToUpdateId?.id
                                ? draggedOrder
                                : it.order,
                          }));
                          productManagementEvent({
                            listClientQuestions: newArr,
                          });
                          setReseted(false);
                        }}
                      >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography fontSize={16}>{obj?.description}</Typography>
                          <SwitchToggle
                            disabled={obj.isRequired}
                            value={obj.isActive}
                            onChange={() => {
                              const newArr = [...listClientQuestions];
                              const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                              newArr[replaceIdx].isActive = !obj?.isActive;
                              productManagementEvent({ listClientQuestions: newArr });
                            }}
                          />
                        </Stack>
                      </CardQuestion>
                    );
                  })}

                <Typography fontSize="12px" fontWeight="bold" mt={4}>
                  INFORMAÇÕES DA PARCERIA
                </Typography>
                <Divider />

                {listNegotiationQuestions
                  ?.sort((a, b) => (a?.order < b?.order ? -1 : 1))
                  .map(obj => {
                    return (
                      <CardQuestion
                        key={obj.id}
                        order={obj?.order}
                        onChange={(draggedOrder, droppedOnOrder) => {
                          setReseted(true);
                          const selectedItemId = listNegotiationQuestions.find(
                            it => it.order === draggedOrder,
                          );
                          const itemToUpdateId = listNegotiationQuestions.find(
                            it => it.order === droppedOnOrder,
                          );
                          const newArr = [...listNegotiationQuestions]?.map(it => ({
                            ...it,
                            order:
                              it.id === selectedItemId?.id
                                ? droppedOnOrder
                                : it.id === itemToUpdateId?.id
                                ? draggedOrder
                                : it.order,
                          }));
                          productManagementEvent({
                            listNegotiationQuestions: newArr,
                          });
                          setReseted(false);
                        }}
                      >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography fontSize={16}>{obj?.description}</Typography>
                          <SwitchToggle
                            disabled={obj.isRequired}
                            value={obj.isActive}
                            onChange={() => {
                              const newArr = [...listNegotiationQuestions];
                              const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                              newArr[replaceIdx].isActive = !obj?.isActive;
                              productManagementEvent({ listNegotiationQuestions: newArr });
                            }}
                          />
                        </Stack>
                      </CardQuestion>
                    );
                  })}

                <Typography fontSize="12px" fontWeight="bold" mt={4}>
                  INFORMAÇÕES DE ENTREGA (SOLICITANTE EMS)
                </Typography>
                <Divider />
                {listShippingQuestions
                  ?.sort((a, b) => (a?.order < b?.order ? -1 : 1))
                  .map(obj => {
                    return (
                      <CardQuestion
                        key={obj.id}
                        order={obj?.order}
                        onChange={(draggedOrder, droppedOnOrder) => {
                          setReseted(true);
                          const selectedItemId = listShippingQuestions.find(
                            it => it.order === draggedOrder,
                          );
                          const itemToUpdateId = listShippingQuestions.find(
                            it => it.order === droppedOnOrder,
                          );
                          const newArr = [...listShippingQuestions]?.map(it => ({
                            ...it,
                            order:
                              it.id === selectedItemId?.id
                                ? droppedOnOrder
                                : it.id === itemToUpdateId?.id
                                ? draggedOrder
                                : it.order,
                          }));
                          productManagementEvent({
                            listShippingQuestions: newArr,
                          });
                          setReseted(false);
                        }}
                      >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography fontSize={16}>{obj?.description}</Typography>
                          <SwitchToggle
                            disabled={obj.isRequired}
                            value={obj.isActive}
                            onChange={() => {
                              const newArr = [...listShippingQuestions];
                              const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                              newArr[replaceIdx].isActive = !obj?.isActive;
                              productManagementEvent({ listShippingQuestions: newArr });
                            }}
                          />
                        </Stack>
                      </CardQuestion>
                    );
                  })}

                <Typography fontSize="12px" fontWeight="bold" mt={4}>
                  INFORMAÇÕES DA CAMPANHA
                </Typography>
                <Divider />
                {listCompaign
                  ?.sort((a, b) => (a?.order < b?.order ? -1 : 1))
                  .map(obj => {
                    return (
                      <CardQuestion
                        key={obj.id}
                        order={obj?.order}
                        onChange={(draggedOrder, droppedOnOrder) => {
                          setReseted(true);
                          const selectedItemId = listCompaign.find(it => it.order === draggedOrder);
                          const itemToUpdateId = listCompaign.find(
                            it => it.order === droppedOnOrder,
                          );
                          const newArr = [...listCompaign]?.map(it => ({
                            ...it,
                            order:
                              it.id === selectedItemId?.id
                                ? droppedOnOrder
                                : it.id === itemToUpdateId?.id
                                ? draggedOrder
                                : it.order,
                          }));
                          productManagementEvent({
                            listCompaign: newArr,
                          });
                          setReseted(false);
                        }}
                      >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography fontSize={16}>{obj?.name}</Typography>
                          <SwitchToggle
                            value={obj.isActive}
                            onChange={() => {
                              const newArr = [...listCompaign];
                              const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                              newArr[replaceIdx].isActive = !obj?.isActive;
                              productManagementEvent({ listCompaign: newArr });
                            }}
                          />
                        </Stack>
                      </CardQuestion>
                    );
                  })}
              </>
            ) : (
              <Box height={2000} />
            )}
          </div>
          <Stack direction="row" justifyContent="flex-end" sx={{ padding: '20px 0' }}>
            <Button
              variant="contained"
              sx={{ width: 150 }}
              disabled={!isValid}
              onClick={() => productManagementEvent({ isOpenModalSubmit: true })}
              endIcon={<ArrowForwardOutlined color="inherit" />}
            >
              CRIAR
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
}

export default ProductManagementDrawer;

/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-params */
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useStore } from 'effector-react';

import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowForwardOutlined, DeleteOutline } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import FileInput from 'components/form/imagePicker';
import SwitchToggle from 'components/form/switchToggle';
import TextInput from 'components/form/textInput';
import CardQuestion from 'components/ui/cardQuestion';
import ConfirmModal from 'components/ui/confirmModal/ConfirmModal';

import { editProductManagementUseCase } from 'useCases/productManagement/editProductManagementUseCase';

import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';
import { productManagementInitialState } from 'stores/ProductManagement/ProductManagementState';
import productManagementStore from 'stores/ProductManagement/ProductManagementStore';

import { ProductManagementEditValidators } from '../ProductManagementEditValidators';

const nameMaxLength = 15;
const descriptionMaxLength = 130;

function ProductManagementEditDrawer() {
  const [nameCounter, setNameCounter] = useState(0);
  const [descriptionCounter, setDescriptionCounter] = useState(0);
  const [reseted, setReseted] = useState(false);
  const theme = useTheme();
  const {
    isLoadingForm,
    isOpenFormEdit,
    isOpenModalEditSubmit,
    listNegotiationEditQuestions,
    listShippingEditQuestions,
    listClientEditQuestions,
    listIdentificationEditQuestions,
    listEditCompaign,
    dataProduct,
  } = useStore(productManagementStore);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(ProductManagementEditValidators),
    mode: 'all',
  });

  const handleSubmitForm = () => {
    productManagementEvent({ isOpenModalEditSubmit: true, isLoadingForm: false });
  };

  const closeForm = () => {
    reset();
    productManagementEvent({
      isOpenFormEdit: false,
      dataProduct: productManagementInitialState.dataProduct,
      listIdentificationEditQuestions:
        productManagementInitialState.listIdentificationEditQuestions,
      listClientEditQuestions: productManagementInitialState.listClientEditQuestions,
      listNegotiationEditQuestions: productManagementInitialState.listNegotiationEditQuestions,
      listShippingEditQuestions: productManagementInitialState.listShippingEditQuestions,
      listEditCompaign: productManagementInitialState.listEditCompaign,
    });
  };
  const handleDeleteImage = () => {
    const res = { ...dataProduct, data: { ...dataProduct.data, thumbnail: '' } };
    productManagementEvent({ imageDeleted: true, dataProduct: res });
  };
  // close using useCase;
  useEffect(() => {
    if (!isOpenFormEdit) closeForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenFormEdit]);
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const currentLength = value.length;
    if (currentLength <= nameMaxLength) {
      setNameCounter(currentLength);
    }
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const currentLength = value.length;
    if (currentLength <= descriptionMaxLength) {
      setDescriptionCounter(currentLength);
    }
  };

  const sectionIdentification =
    dataProduct &&
    listIdentificationEditQuestions &&
    listIdentificationEditQuestions.length > 0 &&
    listIdentificationEditQuestions?.sort((a, b) => (a?.order < b?.order ? -1 : 1));

  const sectionClient = listClientEditQuestions?.sort((a, b) => (a?.order < b?.order ? -1 : 1));

  const sectionNegotiation = listNegotiationEditQuestions?.sort((a, b) =>
    a?.order < b?.order ? -1 : 1,
  );

  const sectionShipping = listShippingEditQuestions?.sort((a, b) => (a?.order < b?.order ? -1 : 1));

  const sectionCompaign = listEditCompaign?.sort((a, b) => (a?.order < b?.order ? -1 : 1));

  if (!isOpenFormEdit) return <></>;

  return (
    <>
      <Drawer anchor="right" open={isOpenFormEdit} onClose={closeForm}>
        <Box
          sx={{
            [theme.breakpoints.between('sm', 'xs')]: {
              width: 480,
              maxWidth: 480,
            },
            [theme.breakpoints.up('md')]: {
              width: 580,
              maxWidth: 580,
            },
            minHeight: 'auto',
            borderRadius: '10px 0 0 10px !important',
            backgroundColor: '#FFF',
          }}
        >
          <Stack
            px={4}
            py={4}
            direction="column"
            justifyContent="space-between"
            flex={1}
            sx={{
              height: '100%',
              paddingBottom: 5,
            }}
          >
            <div>
              <Typography fontSize="26px" fontWeight="bold">
                Editar produto
              </Typography>
              <div style={{ marginTop: 18 }} />
              <Controller
                name="image"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FileInput file={value} setFile={onChange} />
                )}
              />
              <Typography fontSize="12px" fontWeight="bold" mt={4}>
                INFORMAÇÕES DO PRODUTO
              </Typography>
              <Divider />
              {dataProduct?.data ? (
                <>
                  <Stack mt={2} direction="row" alignItems="center" gap={2}>
                    <Box>
                      <Typography fontSize="14px">Imagem atual</Typography>
                      <Avatar
                        sx={{ width: 100, height: 100 }}
                        src={dataProduct?.data?.thumbnail || '/assets/empty.png'}
                        alt={dataProduct?.data?.name}
                      />
                    </Box>
                    <Button sx={{ color: 'red' }} onClick={() => handleDeleteImage()}>
                      <DeleteOutline />
                      Excluir
                    </Button>
                  </Stack>
                  <Box mt={2}>
                    <Controller
                      name="id"
                      control={control}
                      defaultValue={dataProduct.data.id}
                      render={({ field: { name } }) => {
                        return (
                          <TextField
                            name={name}
                            hidden
                            aria-hidden
                            hiddenLabel
                            sx={{ display: 'none' }}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="name"
                      control={control}
                      defaultValue={dataProduct?.data?.name}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <div>
                            <TextInput
                              label="Nome do produto"
                              name={name}
                              value={value}
                              maxLength={15}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                onChange(e.target.value);
                                handleNameChange(e);
                              }}
                              error={!!errors.name}
                              helperText={errors.name?.message}
                              inputProps={{
                                maxLength: nameMaxLength,
                              }}
                            />
                            <Typography
                              fontSize="12px"
                              color={nameCounter <= nameMaxLength ? 'initial' : 'error'}
                            >
                              {`${nameCounter}/${nameMaxLength}`}
                            </Typography>
                          </div>
                        );
                      }}
                    />
                  </Box>
                  <Box mt={2}>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue={dataProduct?.data?.description}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <div>
                            <TextInput
                              label="Descrição"
                              name={name}
                              value={value}
                              multiline
                              rows={3}
                              maxLength={130}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                // Especificando o tipo do evento
                                onChange(e.target.value);
                                handleDescriptionChange(e);
                              }}
                              error={!!errors.description}
                              helperText={errors.description?.message}
                              inputProps={{
                                maxLength: descriptionMaxLength,
                              }}
                            />
                            <Typography
                              fontSize="12px"
                              color={nameCounter <= nameMaxLength ? 'initial' : 'error'}
                            >
                              {`${descriptionCounter}/${descriptionMaxLength}`}
                            </Typography>
                          </div>
                        );
                      }}
                    />
                  </Box>
                </>
              ) : (
                <Box mt={2} width="100%">
                  <Skeleton variant="circular" height={100} width={100} />
                </Box>
              )}

              {!reseted ? (
                <>
                  <Typography fontSize="12px" fontWeight="bold" mt={4}>
                    INFORMAÇÕES DO SOLICITANTE
                  </Typography>
                  <Divider />
                  {sectionIdentification ? (
                    sectionIdentification?.map(obj => {
                      return (
                        <CardQuestion
                          key={obj.id}
                          order={obj?.order}
                          onChange={(draggedOrder, droppedOnOrder) => {
                            setReseted(true);
                            const selectedItemId = listIdentificationEditQuestions.find(
                              it => it.order === draggedOrder,
                            );
                            const itemToUpdateId = listIdentificationEditQuestions.find(
                              it => it.order === droppedOnOrder,
                            );
                            const newArr = [...listIdentificationEditQuestions]?.map(it => ({
                              ...it,
                              order:
                                it.id === selectedItemId?.id
                                  ? droppedOnOrder
                                  : it.id === itemToUpdateId?.id
                                  ? draggedOrder
                                  : it.order,
                            }));
                            productManagementEvent({
                              listIdentificationQuestions: newArr,
                            });
                            setReseted(false);
                          }}
                        >
                          <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography fontSize={16}>{obj?.description}</Typography>
                            <SwitchToggle
                              disabled={obj.isRequired}
                              value={
                                dataProduct?.data?.productQuestions.filter(item => {
                                  return item?.question?.id === obj?.id;
                                }).length > 0
                              }
                              onChange={() => {
                                const newArr = [...listIdentificationEditQuestions];
                                const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                                newArr[replaceIdx].isActive = !obj?.isActive;
                                productManagementEvent({
                                  listIdentificationEditQuestions: newArr,
                                });
                              }}
                            />
                          </Stack>
                        </CardQuestion>
                      );
                    })
                  ) : (
                    <Box>
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                      <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                    </Box>
                  )}

                  {listClientEditQuestions && dataProduct && (
                    <>
                      <Typography fontSize="12px" fontWeight="bold" mt={4}>
                        INFORMAÇÕES DA CLÍNICA/MÉDICO
                      </Typography>
                      <Divider />
                      {sectionClient ? (
                        sectionClient.map(obj => {
                          return (
                            <CardQuestion
                              key={obj.id}
                              order={obj?.order}
                              onChange={(draggedOrder, droppedOnOrder) => {
                                setReseted(true);
                                const selectedItemId = listClientEditQuestions.find(
                                  it => it.order === draggedOrder,
                                );
                                const itemToUpdateId = listClientEditQuestions.find(
                                  it => it.order === droppedOnOrder,
                                );
                                const newArr = [...listClientEditQuestions]?.map(it => ({
                                  ...it,
                                  order:
                                    it.id === selectedItemId?.id
                                      ? droppedOnOrder
                                      : it.id === itemToUpdateId?.id
                                      ? draggedOrder
                                      : it.order,
                                }));
                                productManagementEvent({
                                  listClientEditQuestions: newArr,
                                });
                                setReseted(false);
                              }}
                            >
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography fontSize={16}>{obj?.description}</Typography>
                                <SwitchToggle
                                  disabled={obj.isRequired}
                                  value={obj?.isActive}
                                  onChange={() => {
                                    const newArr = [...listClientEditQuestions];
                                    const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                                    newArr[replaceIdx].isActive = !obj?.isActive;
                                    productManagementEvent({ listClientEditQuestions: newArr });
                                  }}
                                />
                              </Stack>
                            </CardQuestion>
                          );
                        })
                      ) : (
                        <Box>
                          <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                          <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                          <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                        </Box>
                      )}
                    </>
                  )}

                  {listNegotiationEditQuestions && dataProduct && (
                    <>
                      <Typography fontSize="12px" fontWeight="bold" mt={4}>
                        INFORMAÇÕES DA PARCERIA
                      </Typography>
                      <Divider />

                      {sectionNegotiation ? (
                        sectionNegotiation.map(obj => {
                          return (
                            <CardQuestion
                              key={obj.id}
                              order={obj?.order}
                              onChange={(draggedOrder, droppedOnOrder) => {
                                setReseted(true);
                                const selectedItemId = listNegotiationEditQuestions.find(
                                  it => it.order === draggedOrder,
                                );
                                const itemToUpdateId = listNegotiationEditQuestions.find(
                                  it => it.order === droppedOnOrder,
                                );
                                const newArr = [...listNegotiationEditQuestions]?.map(it => ({
                                  ...it,
                                  order:
                                    it.id === selectedItemId?.id
                                      ? droppedOnOrder
                                      : it.id === itemToUpdateId?.id
                                      ? draggedOrder
                                      : it.order,
                                }));
                                productManagementEvent({
                                  listNegotiationEditQuestions: newArr,
                                });
                                setReseted(false);
                              }}
                            >
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography fontSize={16}>{obj?.description}</Typography>
                                <SwitchToggle
                                  disabled={obj.isRequired}
                                  value={obj?.isActive}
                                  onChange={() => {
                                    const newArr = [...listNegotiationEditQuestions];
                                    const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                                    newArr[replaceIdx].isActive = !obj?.isActive;
                                    productManagementEvent({
                                      listNegotiationEditQuestions: newArr,
                                    });
                                  }}
                                />
                              </Stack>
                            </CardQuestion>
                          );
                        })
                      ) : (
                        <Box>
                          <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                          <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                          <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                        </Box>
                      )}
                    </>
                  )}

                  {listShippingEditQuestions && dataProduct && (
                    <>
                      <Typography fontSize="12px" fontWeight="bold" mt={4}>
                        INFORMAÇÕES DE ENTREGA (SOLICITANTE EMS)
                      </Typography>
                      <Divider />
                      {sectionShipping ? (
                        sectionShipping.map(obj => {
                          return (
                            <CardQuestion
                              key={obj.id}
                              order={obj?.order}
                              onChange={(draggedOrder, droppedOnOrder) => {
                                setReseted(true);
                                const selectedItemId = listShippingEditQuestions.find(
                                  it => it.order === draggedOrder,
                                );
                                const itemToUpdateId = listShippingEditQuestions.find(
                                  it => it.order === droppedOnOrder,
                                );
                                const newArr = [...listShippingEditQuestions]?.map(it => ({
                                  ...it,
                                  order:
                                    it.id === selectedItemId?.id
                                      ? droppedOnOrder
                                      : it.id === itemToUpdateId?.id
                                      ? draggedOrder
                                      : it.order,
                                }));
                                productManagementEvent({
                                  listShippingEditQuestions: newArr,
                                });
                                setReseted(false);
                              }}
                            >
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography fontSize={16}>{obj?.description}</Typography>
                                <SwitchToggle
                                  disabled={obj.isRequired}
                                  value={obj?.isActive}
                                  onChange={() => {
                                    const newArr = [...listShippingEditQuestions];
                                    const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                                    newArr[replaceIdx].isActive = !obj?.isActive;
                                    productManagementEvent({ listShippingEditQuestions: newArr });
                                  }}
                                />
                              </Stack>
                            </CardQuestion>
                          );
                        })
                      ) : (
                        <Box>
                          <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                          <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                          <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                        </Box>
                      )}
                    </>
                  )}

                  {listEditCompaign && dataProduct && (
                    <>
                      <Typography fontSize="12px" fontWeight="bold" mt={4}>
                        INFORMAÇÕES DA CAMPANHA
                      </Typography>
                      <Divider />
                      {sectionCompaign ? (
                        sectionCompaign.map(obj => {
                          return (
                            <CardQuestion
                              key={obj.id}
                              order={obj?.order}
                              onChange={(draggedOrder, droppedOnOrder) => {
                                setReseted(true);
                                const selectedItemId = listEditCompaign.find(
                                  it => it.order === draggedOrder,
                                );
                                const itemToUpdateId = listEditCompaign.find(
                                  it => it.order === droppedOnOrder,
                                );
                                const newArr = [...listEditCompaign]?.map(it => ({
                                  ...it,
                                  order:
                                    it.id === selectedItemId?.id
                                      ? droppedOnOrder
                                      : it.id === itemToUpdateId?.id
                                      ? draggedOrder
                                      : it.order,
                                }));
                                productManagementEvent({
                                  listEditCompaign: newArr,
                                });
                                setReseted(false);
                              }}
                            >
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography fontSize={16}>{obj?.name}</Typography>
                                <SwitchToggle
                                  value={obj?.isActive}
                                  onChange={() => {
                                    const newArr = [...listEditCompaign];
                                    const replaceIdx = newArr?.findIndex(it => it?.id === obj?.id);
                                    newArr[replaceIdx].isActive = !obj?.isActive;
                                    productManagementEvent({ listEditCompaign: newArr });
                                  }}
                                />
                              </Stack>
                            </CardQuestion>
                          );
                        })
                      ) : (
                        <Box>
                          <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                          <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                          <Skeleton variant="rectangular" height={38} width="100%" sx={{ mt: 2 }} />
                        </Box>
                      )}
                    </>
                  )}
                </>
              ) : (
                <Box height={2000} />
              )}
            </div>
            <Stack direction="row" justifyContent="flex-end" sx={{ padding: '20px 0' }}>
              <Button
                variant="contained"
                sx={{ width: 150 }}
                disabled={!isValid}
                onClick={() => handleSubmitForm()}
                endIcon={<ArrowForwardOutlined color="inherit" />}
              >
                SALVAR
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
      <ConfirmModal
        open={isOpenModalEditSubmit}
        setOpen={v => productManagementEvent({ isOpenModalEditSubmit: v })}
        text="Tem certeza que deseja editar este produto?"
        onConfirm={handleSubmit(editProductManagementUseCase.execute)}
        isLoading={isLoadingForm}
        title="Atenção"
        declineText="CANCELAR"
        confirmText="CONFIRMAR"
      />
    </>
  );
}

export default ProductManagementEditDrawer;

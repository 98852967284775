import { SxProps } from '@mui/material';

export const containerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
};

export const containerProducts: SxProps = {
  mt: 4,
  display: 'grid',
  gap: 4,
  gridTemplateColumns: 'repeat(1, 1fr)',

  '@media screen and (min-width: 768px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  '@media screen and (min-width: 1000px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
};

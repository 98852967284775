import { ProductsService } from 'services/ProductService/ProductService';

import { Common } from 'utils';

import { alertEvent } from 'stores/Alert/AlertEvents';
import { productManagementEvent } from 'stores/ProductManagement/ProductManagementEvents';
import productManagementStore from 'stores/ProductManagement/ProductManagementStore';

import { IParamsCreateAutomationProduct } from 'domains/product';

import { getProductManagementUseCase } from './getProductManagementUseCase';

const execute = async (formData: any) => {
  try {
    productManagementEvent({ isLoadingForm: true });
    const {
      listIdentificationAutomationQuestions,
      listClientAutomationQuestions,
      listNegotiationAutomationQuestions,
      listShippingAutomationQuestions,
    } = productManagementStore.getState();

    const formattedQuestionList = [
      ...listClientAutomationQuestions,
      ...listIdentificationAutomationQuestions,
      ...listNegotiationAutomationQuestions,
      ...listShippingAutomationQuestions,
    ]
      ?.filter(obj => obj.isActive)
      ?.sort((a, b) => (a.order < b.order ? -1 : 1))
      ?.map(obj => ({
        productVersionQuestionId: obj.id,
        active: true,
      }));

    const submitData: IParamsCreateAutomationProduct = {
      userSector: JSON.parse(Common.getSessionValue('logCookie'))?.sector,
      type: formData?.type,
      emailBody: formData?.emailBody,
      questions: formattedQuestionList,
    };
    if (formData?.type === 'Email') {
      submitData.supplierEmail = formData?.email;
      submitData.emailSubject = formData?.subject;
    } else if (formData?.type === 'Sheet') {
      submitData.sheetPath = formData?.url;
    }
    await ProductsService.saveAutomationProduct(formData?.rowId, submitData);
    alertEvent({
      open: true,
      text: 'Automação criada com sucesso.',
      type: 'success',
    });
    getProductManagementUseCase.execute();
  } catch (err) {
    console.log(err);

    alertEvent({
      open: true,
      text: 'Ocorreu um erro ao criar a automação, tente novamente mais tarde',
      type: 'error',
    });
  } finally {
    productManagementEvent({
      isLoadingForm: false,
      isOpenEmailAutomation: false,
      isOpenSpreadsheetAutomation: false,
    });
  }
};

export const createProductAutomationUseCase = { execute };

export enum QuestionCategoryEnum {
  IDENTIFICATION = 'Identification',
  CLIENT = 'Client',
  NEGOTIATION = 'Negotiation',
  SHIPPING = 'Shipping',
  CAMPAIGN = 'Campaign',
}

export interface IQuestionResponse {
  data: {
    questions: IQuestion[];
  };
  hasError: boolean;
  error: string;
}

export interface IQuestion {
  question: any;
  id: string;
  description: string;
  questionType: string;
  questionSubType: null | string;
  questionCategory: string;
  isRequired: boolean;
  createdAt: string;
  updatedAt: string;
  isActive?: boolean;
  order?: number;
}

import { createStore } from 'effector';

import { partnersManagementEvent } from './PartnersManagementEvents';
import { PartnersManagementState, partnersManagementInitialState } from './PartnersManagementState';

const partnersManagementStore = createStore<PartnersManagementState>(
  partnersManagementInitialState,
).on(partnersManagementEvent, (state, payload) => ({
  ...state,
  ...payload,
}));

export default partnersManagementStore;
